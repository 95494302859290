import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { api, API } from 'modules/api';
import { AccountLoadedType } from '../../../../types';
import { LoginTypeEnum } from 'modules/utils/types';
import { NewsletterGetResponse } from 'modules/api/endpoints/newsletter-preferences';
import {
  NewsletterStatusMessage,
  NewsletterPreferenceResponse,
  UseNewsletterPreferencesProps,
} from '../types';
import logger from 'modules/logger';

export const useNewsletterPreferences: UseNewsletterPreferencesProps = (
  setSkeletonLoaded: React.Dispatch<React.SetStateAction<AccountLoadedType>>,
  loginTypeContext?: LoginTypeEnum,
) => {
  const isActive = useRef(true);
  const successMessage = useMemo(() => {
    return {
      message: 'Newsletter preference updated.',
      isError: false,
    };
  }, []);
  const errorMessage = useMemo(() => {
    return {
      message: 'Unable to update preference.',
      isError: true,
    };
  }, []);
  const noMessage = useMemo(() => {
    return {
      message: '',
      isError: false,
    };
  }, []);

  const [value, setValue] = useState(false);
  const [updateMessage, setUpdateMessage] =
    useState<NewsletterStatusMessage>(noMessage);
  const [isSending, setSending] = useState(false);
  //TODO done useCallback
  const setNewsletterPreference = useCallback(
    async (
      isOptedIn: boolean,
    ): Promise<NewsletterPreferenceResponse | null> => {
      try {
        const res = await api(API.POST_NEWSLETTER_PREFERENCE(isOptedIn));
        return res?.data;
      } catch (error) {
        isActive.current &&
          logger.error('Error setting newsletter preference', error);
        return null;
      }
    },
    [],
  );
  //TODO done useEffect
  useEffect(() => {
    if (loginTypeContext !== LoginTypeEnum.EMPLOYEE) {
      let active = true;
      (async () => {
        try {
          api(API.GET_NEWSLETTER_PREFERENCE()).then(
            (response: NewsletterGetResponse) => {
              active && response.data !== null && setValue(response.data);
            },
          );
        } catch (error) {
          active && logger.error('Error getting newsletter preference', error);
        } finally {
          active &&
            setSkeletonLoaded(prevState => ({
              ...prevState,
              newsletter: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    } else {
      setSkeletonLoaded(prevState => ({
        ...prevState,
        newsletter: true,
      }));
    }
  }, [loginTypeContext, setSkeletonLoaded]);

  const onChange = useCallback(async () => {
    //TODO done useCallback
    try {
      const newValue = !value;
      setSending(true);
      setUpdateMessage(noMessage);
      setValue(newValue);
      await setNewsletterPreference(newValue);
      if (isActive.current) {
        setSending(false);
        setUpdateMessage(successMessage);
      }
    } catch (error) {
      if (isActive.current) {
        setUpdateMessage(errorMessage);
        setValue(value);
        logger.debug('On Change Error: ', error);
      }
    }
  }, [errorMessage, noMessage, setNewsletterPreference, successMessage, value]);

  useEffect(() => {
    return () => {
      isActive.current = false;
    };
  }, []);

  return {
    form: {
      value,
      isSending,
      onChange,
      updateMessage,
    },
  };
};
