import React from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  BadgesTypes,
  BadgesCandidateIdTypes,
  BadgesLoadedType,
  BadgesPageType,
  ProgrammePageType,
} from './types';
import { ProgrammeType } from './components/Programme/constants';
import * as Layout from 'components/Layout';
import AddCandidate from './components/AddCandidate';
import CandidateIds from './components/CandidateIds';
import EarnedBadges from './components/EarnedBadges';
import NewCandidateId from './components/NewCandidateId';
import Overview from './components/Overview';
import Programme from './components/Programme';

type TemplateProps = {
  badges: BadgesPageType;
  candidateId?: BadgesCandidateIdTypes[];
  cancelPolling: () => void;
  itil: ProgrammePageType;
  otherBestPractices: ProgrammePageType;
  loaded: BadgesLoadedType;
  proPath: ProgrammePageType;
  runPolling: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void;
  setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>;
  setCandidateIds: React.Dispatch<
    React.SetStateAction<BadgesCandidateIdTypes[] | undefined>
  >;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  badges,
  candidateId,
  cancelPolling,
  itil,
  otherBestPractices,
  loaded,
  proPath,
  runPolling,
  setBadges,
  setCandidateIds,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  return (
    <Layout.Container>
      <Layout.Content>
        <NewCandidateId
          candidateId={candidateId}
          cancelPolling={cancelPolling}
          loaded={loaded.candidateId}
          setCandidateIds={setCandidateIds}
        />
        {isMobile && (
          <React.Fragment>
            <Overview
              badgesEarned={badges.earned}
              candidateId={candidateId}
              loaded={loaded.candidateId}
            />
            <EarnedBadges
              badges={badges.records?.filter(b => b?.claimed)}
              badgesEarned={badges.earned}
              candidateId={candidateId}
              loaded={loaded.badges}
            />
          </React.Fragment>
        )}
        <Programme
          badgeLinks={badges.links}
          badges={badges.records}
          candidateId={candidateId}
          cancelPolling={cancelPolling}
          loaded={loaded}
          runPolling={runPolling}
          setBadges={setBadges}
          title="ProPath Certification Programme"
          type={ProgrammeType.PROPATH}
          {...proPath}
        />
        <Programme
          badgeLinks={badges.links}
          badges={badges.records}
          candidateId={candidateId}
          cancelPolling={cancelPolling}
          loaded={loaded}
          runPolling={runPolling}
          setBadges={setBadges}
          title="ITIL4 Certification Programme"
          type={ProgrammeType.ITIL}
          {...itil}
        />
        <Programme
          badgeLinks={badges.links}
          badges={badges.records}
          candidateId={candidateId}
          cancelPolling={cancelPolling}
          loaded={loaded}
          runPolling={runPolling}
          setBadges={setBadges}
          title="Other Best Practices"
          type={ProgrammeType.OTHER_BEST_PRACTICES}
          {...otherBestPractices}
        />
      </Layout.Content>
      <Layout.Sidebar>
        {!isMobile && (
          <React.Fragment>
            <Overview
              badgesEarned={badges.earned}
              candidateId={candidateId}
              loaded={loaded.badges}
            />
            <EarnedBadges
              badges={badges.records?.filter(b => b?.claimed)}
              badgesEarned={badges.earned}
              candidateId={candidateId}
              loaded={loaded.badges}
            />
          </React.Fragment>
        )}
        <AddCandidate
          candidateId={candidateId}
          cancelPolling={cancelPolling}
          loaded={loaded.candidateId}
          setCandidateIds={setCandidateIds}
        />
        <CandidateIds ids={candidateId} loaded={loaded.candidateId} />
      </Layout.Sidebar>
    </Layout.Container>
  );
};

export default Template;
