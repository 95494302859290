import React, { useMemo } from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { getDateWithOrdinal } from 'modules/utils/utils';
import { PaymentInfoType } from '../PaymentInformation/types';
import { SubscriptionStatus } from '../constants';
import * as Button from 'components/Button';
import styles from './styles.module.scss';

export type SubscriptionItemType = {
  currentPlan: string | null;
  planAvailable: string;
  planEnd?: string;
  planFee: string;
  planFrequency: string;
  planName: string;
  planSaving: number;
  planStatus: SubscriptionStatus;
  planSubName: string;
  subscriptionPlanId: string;
  willUpgrade: boolean;
};

type SubscriptionPropsType = {
  handleAddPaymentMethod: () => void;
  handleUpgradeClick: (subscriptionPlanId: string) => void;
  isCurrentPlan: boolean;
  isFreeTrial: boolean;
  isMigrationUser: boolean;
  paymentMethods: PaymentInfoType[];
  planAvailable: string;
  planEnd?: string;
  planFee: string;
  planFrequency: string;
  planName: string;
  planSaving: number;
  planStatus: string;
  planSubName: string;
  subscriptionPlanId: string;
};
const Subscription: React.FunctionComponent<SubscriptionPropsType> = ({
  handleAddPaymentMethod,
  handleUpgradeClick,
  isCurrentPlan,
  isFreeTrial,
  isMigrationUser = false,
  paymentMethods,
  planAvailable,
  planEnd,
  planFee,
  planFrequency,
  planName,
  planSaving = 0,
  planStatus,
  planSubName,
  subscriptionPlanId,
}) => {
  const isNonRenewing = planStatus === SubscriptionStatus.NON_RENEWING;

  const getButtonLabel = useMemo(() => {
    if (isCurrentPlan) {
      return isNonRenewing ? 'Re-subscribe' : 'Subscribed';
    }
    if (isMigrationUser) {
      return 'Subscribe';
    }
    return 'Upgrade';
  }, [isCurrentPlan, isMigrationUser, isNonRenewing]);

  return (
    <div className={styles.subscription}>
      <div>
        <h4>
          {isFreeTrial && !isMigrationUser
            ? 'MyAxelos Standard Individual Subscription'
            : planName}
        </h4>
        <p>{planSubName === 'Month' ? 'Monthly' : 'Yearly'}</p>
        <p>
          {`£${planFee}`}
          <span>{`/${planFrequency}`}</span>
        </p>
      </div>
      <div>
        {(!isFreeTrial || isMigrationUser) && planSaving && !isCurrentPlan && (
          <React.Fragment>
            {isMigrationUser ? (
              <p>
                Save <strong>{`£${planSaving}`}</strong> with this plan.
              </p>
            ) : (
              <p>
                Upgrade your subscription to save{' '}
                <strong>{`£${planSaving}`}</strong>
              </p>
            )}
          </React.Fragment>
        )}
        {!isFreeTrial && planEnd && isCurrentPlan && (
          <p>
            {isNonRenewing ? 'Inactive on ' : 'Renews automatically on '}
            <strong>{getDateWithOrdinal(planEnd, false)}</strong>
          </p>
        )}
        {isFreeTrial && !isMigrationUser && paymentMethods.length === 0 ? (
          <Button.Generic
            colour={ButtonColour.BRAND_AQUA}
            label="Upgrade"
            disabled={true} // Delete this after PeopleCert+ Transition
            onClick={handleAddPaymentMethod}
            style={ButtonStyle.SECONDARY}
          />
        ) : (
          <Button.Generic
            colour={ButtonColour.BRAND_AQUA}
            label={getButtonLabel}
            onClick={() => handleUpgradeClick(subscriptionPlanId)}
            style={ButtonStyle.SECONDARY}
            disabled={true} // Delete this after PeopleCert+ Transition
            // disabled={
            //   planAvailable === 'false' || (isCurrentPlan && !isNonRenewing)
            // }
          />
        )}
      </div>
    </div>
  );
};

export default Subscription;
