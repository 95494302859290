import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  userName: yup
    .string()
    .email()
    .required('Please enter email id')
    .max(200, 'Username must be at most 200 characters.'),
  pass: yup
    .string()
    // .password()
    .required('Please enter password')
    .max(200, 'Password must be at most 200 characters.'),
});

export type Model = {
  userName: string;
  pass: string;
};

const userName: QuestionType<Model> = {
  name: 'userName',
};
const pass: QuestionType<Model> = {
  name: 'pass',
};

export const model = {
  userName,
  pass,
};

export const defaultValues: Model = {
  userName: '',
  pass: '',
};
