import ResetPassword from 'components/SSO/ResetPassword';
import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { useLoginToken } from 'modules/hooks';
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoutePropsType } from './types';

export const ProtectedRouteResetPassword: React.FunctionComponent<ProtectedRoutePropsType> =
  ({ path }) => {
    const { loginTypeContext } = useContext(LoginTypeContext);
    const { isLoggedIn } = useLoginToken();
    const { accountDataContext } = useContext(AccountDataContext);

    return <Route path={path}>{!isLoggedIn() && <ResetPassword />}</Route>;
  };
