import React from 'react';

import { BraintreeCardType } from './constants';
import { ReactComponent as AmericanExpressSvg } from './icons/american-express.svg';
import { ReactComponent as DefaultCardSvg } from './icons/default-card.svg';
import { ReactComponent as DinersClubSvg } from './icons/diners-club.svg';
import { ReactComponent as DiscoverSvg } from './icons/discover.svg';
import { ReactComponent as EloSvg } from './icons/elo.svg';
import { ReactComponent as HiperSvg } from './icons/hiper.svg';
import { ReactComponent as HiperCardSvg } from './icons/hipercard.svg';
import { ReactComponent as JcbSvg } from './icons/jcb.svg';
import { ReactComponent as MastercardSvg } from './icons/mastercard.svg';
import { ReactComponent as MirSvg } from './icons/mir.svg';
import { ReactComponent as UnionPaySvg } from './icons/union-pay.svg';
import { ReactComponent as VisaSvg } from './icons/visa.svg';

type BraintreeIconProps = {
  type: BraintreeCardType;
};

const BraintreeIcon: React.FunctionComponent<BraintreeIconProps> = ({
  type,
}) => {
  const normalized =
    typeof type === 'string'
      ? type.toLowerCase().replace(' ', '').replace('-', '').replace('_', '')
      : '';
  switch (normalized) {
    case BraintreeCardType.AMERICAN_EXPRESS:
      return <AmericanExpressSvg />;
    case BraintreeCardType.DINERS_CLUB:
      return <DinersClubSvg />;
    case BraintreeCardType.DISCOVER:
      return <DiscoverSvg />;
    case BraintreeCardType.ELO:
      return <EloSvg />;
    case BraintreeCardType.HIPER:
      return <HiperSvg />;
    case BraintreeCardType.HIPERCARD:
      return <HiperCardSvg />;
    case BraintreeCardType.JCB:
      return <JcbSvg />;
    case BraintreeCardType.MASTERCARD:
      return <MastercardSvg />;
    case BraintreeCardType.MIR:
      return <MirSvg />;
    case BraintreeCardType.UNION_PAY:
      return <UnionPaySvg />;
    case BraintreeCardType.VISA:
      return <VisaSvg />;
    default:
      return <DefaultCardSvg />;
  }
};

export default BraintreeIcon;
