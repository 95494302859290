import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { useState } from 'react';
import { useSignIn } from './hooks/useSignin';
import { useHistory, useLocation } from 'react-router-dom';
import * as Question from 'components/Question';
import IsUserMyAxelos from '../IsUserMyAxelos';
import { defaultValues, model, schema } from './model';
import Questions from 'components/Questions';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import * as Button from 'components/Button';
import * as Loading from 'components/Loading';
import QuestionsWithRecaptcha from 'components/QuestionsWithRecaptcha';

const SignIn: React.FunctionComponent = () => {
  const {
    defaultValues,
    handleSubmit,
    useIsUserMyAxelos,
    isUserMyAxelos,
    error,
    isApiSubmitting,
  } = useSignIn();
  const [clicked, setClicked] = useState(false);
  const [inputType, setInputType] = useState('password');
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const elementsValue = document.querySelectorAll('input');
    const val = elementsValue[1];
    if (val !== null) {
      val.setAttribute('type', inputType);
    }
  });

  const handleVerifyEmail = (e: any) => {
    history.push({
      pathname: '/reset-password',
      state: {
        validateOtp: true,
      },
    });
  };

  const handleClick = (e: any) => {
    setClicked(!clicked);
    const elementsValue = document.querySelectorAll('input');
    const val = elementsValue[1];
    if (val !== null) {
      if (inputType === 'password') {
        setInputType('text');
        val.setAttribute('type', inputType);
      } else {
        setInputType('password');
        val.setAttribute('type', inputType);
      }
    }
  };

  const handleRedirectForgotPassword = () => {
    history.push('/reset-password');
  };
  return (
    <>
      {/* Show Banner On Sign-in Page  */}
      <p className={styles.infoBanner}>
        Exciting news: MyAxelos is transitioning to a brand new membership
        experience. In preparation for our launch, we'll need to implement a few
        adjustments, therefore, for the week of Monday, 26th February 2024,
        certain MyAxelos functionalities will be temporarily unavailable. These
        include: Editing of CPD points, Access to Digital Badges, Subscription
        changes, Payment detail updates. Rest assured, access to all other
        resources and content will remain uninterrupted during this period.
        {/* Please visit{' '}
        <a
          href="https://www.peoplecert.org/membership"
          target="_blank"
          rel="noreferrer"
        >
          www.peoplecert.org/membership
        </a>{' '}
        to learn more. */}
      </p>
      <div id={styles.myAxelosSignIn} className={styles.container}>
        {isApiSubmitting ? <Loading.Modal /> : null}
        <div
          id="background_branding_container"
          className={styles.myAxelosIntro}
          data-tenant-branding-background-color="true"
        >
          <span className={styles.image}></span>
          <div>
            <h1>
              MyAxelos has transitioned to
              <br />
              <span>PeopleCert+</span>
            </h1>
            <p className={styles.pcLoginInfo}>
              PeopleCert+ brings all membership features, your certification and
              exam information, under one roof, your PeopleCert Account. Please
              login in with your current credentials at{' '}
              <a href="https://www.peoplecert.org/login">
                https://www.peoplecert.org/login
              </a>{' '}
              to experience your new platform.
            </p>
          </div>
          <span className={styles.backgroundSlice}></span>
        </div>
        <div className={styles.myAxelosForm} id="panel">
          <div className={styles.formContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={styles.logo}
              role="img"
              viewBox="0 0 125 34"
            >
              <title>MyAxelos logo</title>
              <path
                d="M44.437.205L16.684 0a16.684 16.684 0 000 33.368H32.12a4.583 4.583 0 004.078-2.496l12.283-24A4.583 4.583 0 0044.437.205z"
                fill="#6de2e2"
              />
              <path
                d="M66.513 19.265l4.41 6.22h-2.53l-3.188-4.597-3.204 4.597h-2.48l4.446-6.185-4.298-6.04h2.53l3.06 4.42 3.081-4.42h2.455zM52.072 13.26l6.272 12.224H56.16l-1.514-3.041h-7.223l-1.53 3.041h-2.168l6.29-12.224zm1.685 7.404l-2.714-5.442-2.715 5.442zm20.708-.637h7.532v-1.779h-7.532V15.04h9.012v-1.78H72.525v12.222H83.67v-1.78h-9.205zm13.052-6.767h-1.941v12.225h11.179v-1.78h-9.239zm23.51 6.192c0 4.354-3.545 6.336-6.842 6.336s-6.842-1.982-6.842-6.336c0-3.877 2.75-6.482 6.842-6.482s6.842 2.606 6.842 6.482zm-1.897-.258c0-2.653-2.032-4.506-4.945-4.506s-4.946 1.853-4.946 4.506c0 3.012 1.85 4.812 4.946 4.812 2.391 0 4.945-1.264 4.945-4.812zm9.782-.945c-2.56-.234-4.116-.468-4.116-1.733 0-1.592 2.346-1.83 3.745-1.83 2.905 0 3.995 1.26 4.52 1.864l.245.28 1.417-1.151-.143-.2c-.42-.59-2.095-2.508-6.007-2.508-3.708 0-5.75 1.34-5.75 3.77 0 2.783 3.129 3.086 5.889 3.354 2.218.214 4.314.417 4.314 1.83 0 1.528-2.157 2.07-4.002 2.07-3.394 0-4.463-1.425-5.1-2.277l-.17-.223-1.306 1.114-.193.188.18.2c.994 1.106 2.496 2.778 6.428 2.778 4.524 0 6.137-1.99 6.137-3.85 0-3.121-3.232-3.416-6.088-3.676z"
                fill="#000"
              />
              <path
                d="M24.582 20.52v-6.758a2.28 2.28 0 00-2.399-2.537c-1.707.023-2.745 1.36-2.745 3.16v6.138h-3.53v-6.761a2.275 2.275 0 00-2.375-2.537c-1.73.023-2.768 1.36-2.768 3.16v6.138H7.212V8.11h3.552v2.145a4.584 4.584 0 014.199-2.283 4.123 4.123 0 014.198 2.86 4.536 4.536 0 014.475-2.86c2.745 0 4.476 1.846 4.476 4.751v7.797zM39.378 8.11l-4.314 8.276-3.426-8.276H27.97l5.32 12.157-.26.692a2.083 2.083 0 01-1.898 1.107 2.414 2.414 0 01-1.614-.669l-1.5 2.56a4.992 4.992 0 003.368 1.2c2.146 0 3.42-.898 4.545-3.252l7-13.795z"
                fill="#fff"
              />
            </svg>
            <QuestionsWithRecaptcha
              id="SignIn"
              defaultValues={defaultValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              schema={schema}
            >
              {({ control, isFormSubmitting }) => (
                <React.Fragment>
                  <div className="entry">
                    <div className={styles.entryItem}>
                      <label htmlFor="signInName">Email Address</label>
                      <div className={styles.signinFormTextAlign}>
                        <Question.Text
                          control={control}
                          isSubmitting={isFormSubmitting}
                          question={model.userName}
                          handleCheckIsUserMyAxelos={true}
                          useIsUserMyAxelos={useIsUserMyAxelos}
                          label=""
                        />

                        <label htmlFor="password">Password</label>
                        <div className={styles.wrapper}>
                          <Question.Text
                            control={control}
                            isSubmitting={isFormSubmitting}
                            question={model.pass}
                            label=""
                          />

                          <span
                            className={
                              clicked ? styles.revealActive : styles.reveal
                            }
                            onClick={handleClick}
                          ></span>
                          <a
                            onClick={handleRedirectForgotPassword}
                            className={styles.forgotPassword}
                          >
                            Forgot your password?
                          </a>
                        </div>
                      </div>

                      {/* <div
                        id="reCaptcha"
                        className={styles.reCaptchaStyles}
                      ></div>

                      <div
                        id="reCaptchaResponse"
                        className={styles.displayNone}
                      ></div> */}

                      <p className={styles.error}>{error}</p>

                      <div className={styles.buttons}>
                        <Button.Submit
                          colour={ButtonColour.BRAND_AQUA}
                          disabled={isFormSubmitting}
                          label="Sign In"
                          style={ButtonStyle.SECONDARY}
                          className={styles.next}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </QuestionsWithRecaptcha>
            <div id="api" role="main">
              <div
                className="localAccount"
                aria-label="Sign in with your existing account"
                role="form"
              >
                <div className={styles.create}>
                  <p className={styles.register}>
                    Need to register?&nbsp;
                    <a
                      id="createAccount"
                      tabIndex={1}
                      href={process.env.REACT_APP_NOROLE_URL!}
                    >
                      Sign up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
