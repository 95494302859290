import { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { AccountDataContext } from 'modules/context';

export const useLapsedUserModal = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const history = useHistory();
  // const regExpSignup = new RegExp(/\/signup\?*/);
  const { accountDataContext } = useContext(AccountDataContext);

  useEffect(() => {
    history.listen((_, action) => {
      if (!window.location.href.includes('/signup')) {
        if (action === 'POP' && modal.current) {
          window.location.href = process.env.REACT_APP_NOROLE_URL!;
        }
      }
    });
  }, [history, history.action]);

  const displayLapsedUserModal = useCallback(() => {
    if (accountDataContext?.subscription?.status == null) {
      modal.current = modalShow({
        onClose: () => {
          window.location.href = process.env.REACT_APP_NOROLE_URL!;
        },

        title: 'Subscribe to myAxelos',
        hideX: true,
        text: 'Access your MyAxelos Exclusive Membership to gain access to tools that can help you advance your profession as well as exclusive content, a community, and credibility. MyAxelos costs £99.00 + VAT per year. You must have a credit card. You can cancel at any time to stop new charges.',
        type: ModalType.INFO,
      });
    } else {
      modal.current = modalShow({
        onClose: () => {
          window.location.href = process.env.REACT_APP_NOROLE_URL!;
        },
        type: ModalType.LAPSED_USER,
      });
    }
  }, [accountDataContext?.subscription?.status, modalShow]);

  return { displayLapsedUserModal } as const;
};
