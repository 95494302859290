import React from 'react';
import clsx from 'clsx';
import { ReactComponent as CpdTitleIcon } from 'modules/theme/icons/misc/cpd-title-icon.svg';
import styles from './styles.module.scss';

type FieldsetType = {
  className?: string;
  title: string;
  cpdForm?: boolean;
  disabled?: boolean;
};

const Fieldset: React.FC<FieldsetType> = ({
  children,
  className,
  title,
  disabled,
  cpdForm,
}) => {
  const titleClass = clsx(cpdForm && styles.cpdTitle);
  return (
    <fieldset className={clsx(styles.fieldset, className)} disabled={disabled}>
      <legend>
        <h2 className={titleClass}>
          {title}
          {cpdForm && <CpdTitleIcon />}
        </h2>
      </legend>
      {children}
    </fieldset>
  );
};

export default Fieldset;
