import { useCallback, useEffect, useRef, useState } from 'react';
// import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import { API, api } from 'modules/api';
import { programmeTypes, programmeOptionsTypes } from '../types';
import logger from 'modules/logger';

export const useProgrammeTypes = (userId: number) => {
  const [loadData, setLoadData] = useState(true);
  const [programmeSelectionType, setProgrammeSelectionType] =
    useState<programmeOptionsTypes[]>();
  const active = useRef(true);

  const fetchProgrammeTypes = useCallback(async () => {
    try {
      const res = await api(API.GET_PROGRAMME_TYPES(userId));

      if (active.current) {
        setProgrammeSelectionType(res.data);
      }
    } catch (error) {
      active.current && logger.debug('Fetch Programme Types Error:', error);
    } finally {
      active.current && setLoadData(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return [fetchProgrammeTypes, programmeSelectionType, loadData] as const;
};
