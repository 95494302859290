import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { AccountDataContext } from 'modules/context';
import { ButtonColour } from 'components/Button/constants';
import { contentIsNew } from 'modules/utils/newContentUtils';
import { ResourceHubItemType } from '../types';
import {
  getDateFormatted,
  getLink,
  linkText,
  readTimeText,
} from 'modules/utils/utils';
import { ReactComponent as Chevron } from 'modules/theme/icons/chevrons/chevron-right.svg';
import * as Hyperlink from 'components/Hyperlink';
import CtaButton from 'components/Cta/Button';
import Social from 'components/Social';

import styles from './styles.module.scss';

export type FeaturedResourcesItemProps = {
  resource: ResourceHubItemType;
  primary?: boolean;
};

const FeaturedResourcesItem: React.FunctionComponent<FeaturedResourcesItemProps> =
  ({ primary, resource }) => {
    const isMobile = useMediaQuery({
      query: '(max-width: 896px) and (min-width: 768px), (max-width: 576px)',
    });

    const { accountDataContext } = useContext(AccountDataContext);

    const contentNew = useMemo(() => {
      if (accountDataContext) {
        return contentIsNew(
          accountDataContext?.lastLogin,
          resource.meta.publication_date,
          resource.uid,
          accountDataContext.userId,
        );
      }
    }, [accountDataContext, resource]);

    const classNames = clsx(
      styles.featuredResourcesItem,
      primary && styles.primary,
      contentNew && styles.isNewArticle,
    );
    return (
      <article className={classNames}>
        {primary &&
          (resource.images?.featured_image?.url ? (
            <span
              style={{
                backgroundImage: `url(${resource.images.featured_image.url}?format=jpg&auto=webp?quality=60)`,
              }}
            >
              <Hyperlink.PageLink
                slug={getLink(
                  resource.categorisation.content_type,
                  resource.slug,
                )}
              >
                <span></span>
              </Hyperlink.PageLink>
            </span>
          ) : (
            <Hyperlink.PageLink
              slug={getLink(
                resource.categorisation.content_type,
                resource.slug,
              )}
            >
              <span className={styles.placeholderImage}></span>
            </Hyperlink.PageLink>
          ))}

        <div>
          <header>
            {accountDataContext && contentNew && (
              <span className={styles.newIcon}>NEW</span>
            )}
            <p className={styles.publication}>
              <span>{getDateFormatted(resource.meta.publication_date)}</span>
              <span>
                {resource.meta.time_to_read} min{' '}
                {readTimeText(resource.categorisation?.content_type[0]?.slug)}
              </span>
            </p>
            {!isMobile && (
              <Social
                bookmarked={resource.bookmarked}
                className={styles.social}
                contentTypeUid={resource.uid}
                liked={resource.liked}
                likes={resource.likes}
                pageSlug={resource.slug}
              />
            )}
          </header>
          {(resource.short_title || resource.short_description) && (
            <div className={styles.copy}>
              {!!resource.short_title && (
                <h3>
                  <Hyperlink.PageLink
                    slug={getLink(
                      resource.categorisation.content_type,
                      resource.slug,
                    )}
                  >
                    {resource.short_title}
                  </Hyperlink.PageLink>
                </h3>
              )}
              {!!resource.short_description && !isMobile && (
                <p>
                  <Hyperlink.PageLink
                    slug={getLink(
                      resource.categorisation.content_type,
                      resource.slug,
                    )}
                  >
                    {resource.short_description}
                  </Hyperlink.PageLink>
                </p>
              )}
            </div>
          )}
          <footer>
            {isMobile && (
              <Social
                bookmarked={resource.bookmarked}
                className={styles.social}
                contentTypeUid={resource.uid}
                liked={resource.liked}
                likes={resource.likes}
                pageSlug={resource.slug}
              />
            )}
            {(!primary || (primary && isMobile)) && (
              <Hyperlink.PageLink
                className={styles.hyperlink}
                label={linkText(resource.categorisation?.content_type[0]?.slug)}
                slug={getLink(
                  resource.categorisation.content_type,
                  resource.slug,
                )}
                style={ButtonColour.BRAND_BERRY}
              >
                <Chevron />
              </Hyperlink.PageLink>
            )}
            {primary && !isMobile && (
              <CtaButton
                defaultColour={ButtonColour.BRAND_BERRY}
                label={linkText(resource.categorisation?.content_type[0]?.slug)}
                page_link={getLink(
                  resource.categorisation.content_type,
                  resource.slug,
                )}
                small
              />
            )}
          </footer>
        </div>
      </article>
    );
  };

export default FeaturedResourcesItem;
