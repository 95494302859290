import React, { useEffect } from 'react';

// original: https://usehooks.com/useScript/
function LoadScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = React.useState(src ? 'loading' : 'idle');
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      } // Fetch existing script element by src
      // It may have been added by another intance of this hook

      let script = document.querySelector('script[src="'.concat(src, '"]'));

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.defer = true;
        script.setAttribute('data-status', 'loading'); // Add script to document body

        document.body.appendChild(script); // Store status in attribute on script
        // This can be read by other instances of this hook

        const setAttributeFromEvent = event => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error',
          );
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      } // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.

      const setStateFromEvent = event => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      }; // Add event listeners

      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent); // Remove event listeners on cleanup

      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src], // Only re-run effect if script src changes
  );
  return status;
}

const RECAPTCHA_SCRIPT_SRC_URL =
  'https://www.google.com/recaptcha/api.js?render=6Le-Ow8pAAAAAFw5JGIjl0chHBwdEKLxo4Iw59yY';
let timer;
/**
 * a hook integrates google-recaptcha v2 into React function components
 * @param {Object} config configuration of recaptcha
 * @param {string} config.containerId  ID of the container to render the recaptcha widget
 * @param {string} config.sitekey client-side sitekey
 * @param {string} config.size the size of the widget. Value can be either of "normal", "compact", "invisible"
 * @param {string} config.theme The color theme of the widget. Value can be either of "dark" or "light". This is only applicable to checkbox recaptcha
 * @param {string} config.badge Reposition the recaptcha badge. Value can be either of "bottomright", "bottomleft", 'inline'. 'inline' lets you position it with CSS. This is only applicable to invisible recaptcha
 * @param {Function} config.successCallback executed when the user submits a successful response and passes the challenge if it prompts up. The g-recaptcha-response token is passed to the callback.
 * @param {Function} config.expiredCallback executed when the recaptcha response expires and the user needs to re-verify.
 * @param {Function} config.errorCallback executed when recaptcha encounters an error (usually network connectivity) and cannot continue until connectivity is restored. If you specify a function here, you are responsible for informing the user that they should retry.
 * @return {Object}
 */

export function useRecaptcha(_ref) {
  let _window, _window$grecaptcha;

  const {
    containerId,
    sitekey,
    size,
    theme,
    badge,
    successCallback,
    expiredCallback,
    errorCallback,
  } = _ref;
  // https://stackoverflow.com/questions/45240833/what-are-the-benefits-of-explicitly-rendering-recaptcha-widget-as-opposed-to-aut
  // explicit rendering is needed because the view template of applicatio may not be loaded yet when recaptcha is loaded

  LoadScript(RECAPTCHA_SCRIPT_SRC_URL);
  const [recaptchaLoaded, setRecaptchaLoaded] = React.useState(
    (_window = window) !== null &&
      _window !== void 0 &&
      (_window$grecaptcha = _window.grecaptcha) !== null &&
      _window$grecaptcha !== void 0 &&
      _window$grecaptcha.render
      ? true
      : false,
  );
  const [recaptchaWidget, setRecaptchaWidget] = React.useState(null);

  useEffect(() => {
    timer = window.setInterval(() => {
      let _window2, _window2$grecaptcha;

      if (
        (_window2 = window) !== null &&
        _window2 !== void 0 &&
        (_window2$grecaptcha = _window2.grecaptcha) !== null &&
        _window2$grecaptcha !== void 0 &&
        _window2$grecaptcha.render
      ) {
        setRecaptchaLoaded(true);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    try {
      if (recaptchaLoaded && recaptchaWidget === null) {
        clearInterval(timer);
        const widget = window.grecaptcha.render(containerId, {
          sitekey,
          size,
          theme,
          badge,
          callback: successCallback,
          'expired-callback': expiredCallback,
          'error-callback': errorCallback,
        });
        setRecaptchaWidget(widget);
      }
    } catch (e) {
      console.log('grecaptcha Error', e);
    }

    // eslint-disable-next-line
  }, [recaptchaLoaded, successCallback, recaptchaWidget, containerId]);
  return {
    recaptchaLoaded,
    recaptchaWidget,
  };
}
