import React from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import {
  CongratulationsPagePlanText,
  CongratulationsPageType,
} from 'modules/api/endpoints/congrats-page';
import { useHistory } from 'react-router-dom';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { htmlSafe, stripParagraphTags } from 'modules/utils/utils';
import { ReactComponent as LogoSVG } from 'modules/theme/ims/logos/MyAxelosLogo.svg';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { TriggerGTMEvent } from 'modules/gtm';
import * as Button from 'components/Button/';
import * as Layout from 'components/Layout';
import * as Loading from 'components/Loading';

import styles from './styles.module.scss';

type TemplateProps = {
  content?: CongratulationsPageType;
  plan?: string;
  planText?: CongratulationsPagePlanText;
  subscriptionState: SubscriptionStateEnum;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  content,
  plan,
  planText,
  subscriptionState,
}) => {
  const history = useHistory();

  const handleVerifyEmail = () => {
    history.push({
      pathname: '/reset-password',
      state: {
        validateOtp: true,
      },
    });
  };

  return content ? (
    <Layout.Container className={styles.congratulations}>
      <div></div>
      <div className={styles.greeting}>
        <h1>
          <span>{content?.title}</span>
          {content?.display_myaxelos_logo_after_title && (
            <LogoSVG className={styles.logo} />
          )}
        </h1>
        <p className={styles.message}>
          {planText?.above && <span>{planText?.above}</span>}
          {subscriptionState !== SubscriptionStateEnum.PeopleCert_User &&
            subscriptionState !== SubscriptionStateEnum.Consultant_User && (
              <React.Fragment>
                {planText?.on_the_left &&
                  htmlSafe(stripParagraphTags(planText?.on_the_left))}
                <span className={styles.plan}>{plan}</span>
                {planText?.on_the_right &&
                  htmlSafe(stripParagraphTags(planText?.on_the_right))}
              </React.Fragment>
            )}
          {planText?.below && <span>{planText?.below}</span>}
        </p>
        {(subscriptionState === SubscriptionStateEnum.Subscription_Lapsed ||
          subscriptionState === SubscriptionStateEnum.Migrated_User ||
          subscriptionState === SubscriptionStateEnum.Subscribed ||
          subscriptionState === SubscriptionStateEnum.Consultant_User) && (
          <Button.PageLink
            className={styles.link}
            colour={ButtonColour.BRAND_AQUA}
            label="Take me to MyAxelos"
            onClick={() =>
              TriggerGTMEvent({
                action: EventsActions.CLICK_TAKE_ME_TO_MYAXELOS,
                category: EventsCategories.MIGRATION,
                label: '',
              })
            }
            slug="/"
            style={ButtonStyle.SECONDARY}
          />
        )}
        {subscriptionState === SubscriptionStateEnum.New_User && (
          <Button.PageLink
            className={styles.link}
            colour={ButtonColour.BRAND_AQUA}
            label="Verify email address using passcode"
            onClick={handleVerifyEmail}
            slug="/reset-password"
            style={ButtonStyle.SECONDARY}
          />
        )}
      </div>
      <div className={styles.noEmail}>
        <p>
          <span>No email?</span> Check your spam. Check your other email
          accounts. Consider it may have been blocked by a firewall.
        </p>
      </div>
    </Layout.Container>
  ) : (
    <Loading.Modal />
  );
};

export default Template;
