import {
  USER_ROLE_CONSULTANT,
  USER_ROLE_INDIVIDUAL,
} from 'modules/utils/constants';
import { userRoleDescription } from './types';

export const getUserRolesEntries = (
  userGroups: string[] | undefined,
): userRoleDescription[] => {
  const entries: any = [];

  if (userGroups !== undefined) {
    userGroups.forEach(role => {
      if (role === USER_ROLE_INDIVIDUAL) {
        entries.push({
          descriptive_text: 'Individual Role',
          user_roles: [{ role_name: 'Candidate' }],
        });
      } else if (role === USER_ROLE_CONSULTANT) {
        entries.push({
          descriptive_text: 'Consultant Role',
          user_roles: [{ role_name: 'AXELOS Consultant' }],
        });
      }
    });
  }
  return entries;
};
