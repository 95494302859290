export type BadgeQualifications = {
  id: string;
  category: string;
  level: string;
  version: string;
  achieved: boolean;
  class?: string;
};

export type BadgesCertificatesPathsTypes = {
  designation: string;
  family: string;
  isCertificate: boolean;
  qualifications: BadgeQualifications[];
  version: number;
};

export type BadgesCandidateIdTypes = {
  candidateId?: string;
};

export type BadgesTypes = {
  assertionId: string;
  name: string;
  claimed: boolean;
  acceptUrl: string;
  viewUrl: string;
  family: string;
  status: number;
  systemName: string;
  displayPriority: number;
  certificate: {
    id: string;
    candidateId: string;
    qualification?: BadgeQualifications;
    language: string;
    dateTaken: string;
    dateExpires: string;
    canRecordCpd: boolean;
  };
};

export type BadgeLinksTypes = {
  certificate_link: {
    certificate_title: string;
  }[];
  find_out_more_link: string;
}[];

export type BadgesPageType = {
  earned: number | boolean;
  links?: BadgeLinksTypes;
  records?: BadgesTypes[];
};

export type CertificateTitleTypes = {
  title: string;
  uniqueTitle?: string;
};

export type CertificateTypes = {
  category: string;
  certificates: CertificateTitleTypes[];
};

export type DesignationsType = {
  title: string;
};
export type ProgrammePageType = {
  certificates: CertificateTypes[];
  designations: DesignationsType[];
  handleNext: () => void;
  handlePrev: () => void;
  paths?: BadgesCertificatesPathsTypes[];
  showPaths: number | boolean;
  slidePosition: number;
};

export type BadgesLoadedType = {
  badges: boolean;
  candidateId: boolean;
  certificates: boolean;
  paths: boolean;
};

export enum ExamMessages {
  RETRIEVE_BADGES = 'We will retrieve your badges to claim shortly',
  ALL_CERTIFICATES_EXPIRED = 'All your certifications are Inactive',
}
