import React from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { ButtonColour } from 'components/Button/constants';
import { EventType } from '../types';
import { getLink } from 'modules/utils/utils';
import { ReactComponent as Chevron } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { RESOURCE_HUB_URL } from 'modules/utils/constants';
import * as Hyperlink from 'components/Hyperlink';
import CtaButton from 'components/Cta/Button';
import EventDateTime from 'components/EventDateTime';

import styles from './styles.module.scss';

export type EventsItemProps = {
  event: EventType;
  primary?: boolean;
};

const EventsItem: React.FunctionComponent<EventsItemProps> = ({
  event,
  primary,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 896px) and (min-width: 768px), (max-width: 576px)',
  });
  const buttonLabel = event.cta?.label[0]?.title
    ? event.cta.label[0].title
    : 'Learn more';

  const classNames = clsx(styles.eventsItem, primary && styles.primary);
  const isPageLink = event.cta.page_link.length > 0;
  const isCustomLink = !!event.cta.custom_url;

  return (
    <article className={classNames}>
      {event.event_image.url && (
        <span
          style={{
            backgroundImage: `url(${event.event_image.url}?format=jpg&auto=webp?quality=60)`,
          }}
        >
          <span></span>
        </span>
      )}
      <div>
        <div>
          <h3>{event.title}</h3>
          {!isMobile && <p>{event.description}</p>}
        </div>
        {event.event_date.map((date: string, indexEvents: number) => {
          if (indexEvents % 2 === 0) {
            return (
              <EventDateTime
                className={styles.eventDateTime}
                key={`event-date-${event.uid}-${indexEvents}`}
                startDate={date}
                endDate={event.event_date[indexEvents + 1]}
              />
            );
          } else {
            return null;
          }
        })}
        {(isCustomLink || isPageLink) && (
          <div className={styles.cta}>
            {primary && isCustomLink && !isPageLink && (
              <React.Fragment>
                {isMobile ? (
                  <Hyperlink.CustomLink
                    className={styles.hyperlink}
                    href={event.cta.custom_url}
                    label={buttonLabel}
                    style={ButtonColour.BRAND_BERRY}
                  >
                    <Chevron />
                  </Hyperlink.CustomLink>
                ) : (
                  <CtaButton
                    custom_url={event.cta.custom_url}
                    defaultColour={ButtonColour.BRAND_BERRY}
                    label={buttonLabel}
                    small
                  />
                )}
              </React.Fragment>
            )}
            {primary && isPageLink && (
              <React.Fragment>
                {isMobile ? (
                  <Hyperlink.PageLink
                    className={styles.hyperlink}
                    label={buttonLabel}
                    slug={`${RESOURCE_HUB_URL}${event.cta.page_link[0]?.categorisation?.content_type[0]?.slug}/${event.cta.page_link[0]?.slug}`}
                    style={ButtonColour.BRAND_BERRY}
                  >
                    <Chevron />
                  </Hyperlink.PageLink>
                ) : (
                  <CtaButton
                    defaultColour={ButtonColour.BRAND_BERRY}
                    label={buttonLabel}
                    page_link={getLink(
                      event?.cta?.page_link[0]?.categorisation?.content_type,
                      event?.cta?.page_link[0]?.slug,
                    )}
                    small
                  />
                )}
              </React.Fragment>
            )}
            {!primary && isCustomLink && !isPageLink && (
              <Hyperlink.CustomLink
                className={styles.hyperlink}
                href={event.cta.custom_url}
                label={buttonLabel}
                style={ButtonColour.BRAND_BERRY}
              >
                <Chevron />
              </Hyperlink.CustomLink>
            )}
            {!primary && isPageLink && (
              <Hyperlink.PageLink
                className={styles.hyperlink}
                label={buttonLabel}
                slug={`${RESOURCE_HUB_URL}${event.cta.page_link[0]?.categorisation?.content_type[0]?.slug}/${event.cta.page_link[0]?.slug}`}
                style={ButtonColour.BRAND_BERRY}
              >
                <Chevron />
              </Hyperlink.PageLink>
            )}
          </div>
        )}
      </div>
    </article>
  );
};

export default EventsItem;
