import React, { useContext, useState } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useController } from 'react-hook-form';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';

import { QuestionProps } from './types';
import { ReactComponent as HelpSVG } from 'modules/theme/icons/form/help.svg';
import { InputSize } from 'components/Inputs/Input/constants';
import * as Input from 'components/Inputs/Input';
import Label from 'components/Label';
import PlatformContext from 'modules/platform/context';

import styles from './styles.module.scss';

function Component<T>({
  control,
  disabled,
  help,
  showHelpOnClick,
  isSubmitting,
  label,
  min,
  question: { name },
  size = InputSize.REGULAR,
}: QuestionProps<T>) {
  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: name,
  });

  const { isMobile } = useContext(PlatformContext);
  const [showHelp, setShowHelp] = useState(false);

  const classNames = clsx(
    styles.question,
    styles.questionDatepicker,
    isSubmitting && styles.readOnly,
    !disabled && invalid && styles.invalid,
    isMobile && showHelp && styles.showHelp,
    showHelpOnClick && showHelp && styles.showHelp,
  );

  const validationMsgClass = clsx(
    size && InputSize.EXTRA_SMALL && styles['input-extra-small'],
  );

  return (
    <div className={classNames}>
      <Label
        id={!isMobile ? 'date-picker-inline' : name}
        label={label}
        size={size}
      />
      {!isMobile ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            disabled={disabled || isSubmitting}
            disableFuture
            disableToolbar
            error={false}
            format="DD/MM/YYYY"
            helperText={null}
            id="date-picker-inline"
            invalidDateMessage=""
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            minDate={min}
            placeholder="DD/MM/YYYY"
            PopoverProps={{
              anchorOrigin: { horizontal: 'left', vertical: 'top' },
              transformOrigin: { horizontal: 'left', vertical: 'top' },
            }}
            readOnly={isSubmitting}
            value={String(value) || null}
            variant="inline"
          />
          {help && (
            <React.Fragment>
              <HelpSVG onClick={() => setShowHelp(!showHelp)} />
              <div role="tooltip" aria-hidden="true">
                {help}
              </div>
            </React.Fragment>
          )}
        </MuiPickersUtilsProvider>
      ) : (
        <React.Fragment>
          <div className={styles.mobileDate}>
            <Input.Date
              className="mobileDatepicker"
              forwardedRef={ref}
              id={name}
              invalid={invalid}
              label={label}
              min={min}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder="DD/MM/YYYY"
              readOnly={isSubmitting}
              size={size}
              tabIndex={disabled ? -1 : 0}
              value={String(value)}
            />
          </div>
        </React.Fragment>
      )}
      {invalid && (
        <p aria-live="polite" className={validationMsgClass}>
          {(error as any)?.message}{' '}
          {(error as any)?.type === 'minDate'
            ? dayjs(min).format('DD/MM/YYYY')
            : ''}
        </p>
      )}
    </div>
  );
}

export default React.memo(Component) as typeof Component;
