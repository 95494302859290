import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Autocomplete } from 'components/Inputs/Autocomplete';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { MuiAutocompleteProps } from 'pages/resource-hub/types';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type SearchBarProps = {
  handleEnterKeyDown: (event: React.KeyboardEvent) => void;
  handleSearchButtonClick: () => void;
  muiAutocompleteProps: MuiAutocompleteProps;
  isEvent: boolean;
};

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  handleEnterKeyDown,
  handleSearchButtonClick,
  muiAutocompleteProps,
  isEvent,
}) => {
  const placeholderBreakpoint = useMediaQuery({
    query: '(max-width: 460px)',
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 1152px) and (min-width: 768px), (max-width: 640px)',
  });
  return (
    <div className={styles.searchBar}>
      <Autocomplete
        onKeyDown={handleEnterKeyDown}
        placeholder={
          isEvent
            ? 'Search MyAxelos'
            : placeholderBreakpoint
            ? 'Search resource hub'
            : 'Search MyAxelos resource hub'
        }
        {...muiAutocompleteProps}
      />
      <Button.Generic
        ariaLabel="Search"
        colour={ButtonColour.BRAND_BLACK_GRAPE}
        label={isMobile ? '' : 'Search'}
        onClick={handleSearchButtonClick}
        style={ButtonStyle.PRIMARY}
        small={isMobile ? true : false}
      />
    </div>
  );
};

export default SearchBar;
