import React from 'react';
import clsx from 'clsx';

import { CpdTrackItem, dummyCertificates } from 'pages/cpd/types';
import {
  ButtonColour,
  ButtonStyle,
  ButtonTarget,
} from 'components/Button/constants';
import * as Button from 'components/Button';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';
import CPDTrackRecord from '../CPDTrackRecord';
import { getDateFormattedForCPD } from 'modules/utils/utils';

type CPDTrackProps = {
  cpdTrackItem: CpdTrackItem;
  name: string;
  loaded: boolean;
  setIsFetchDataCallback: (val: boolean) => void;
};

const CPDTrack: React.FunctionComponent<CPDTrackProps> = ({
  cpdTrackItem,
  name,
  loaded,
  setIsFetchDataCallback,
}) => {
  const { expiryDate, message, cpdYears, certifications } = cpdTrackItem;
  const certificationsAcquired = 'PRINCE2 Foundation, 6th edition';
  const peopleCertPathMessage = `Boost your path's renewal date by acquiring a new certification in
  the family`;

  const classNames = clsx(
    styles.CPDTrack,
    (cpdYears === undefined || cpdYears?.length === 0) && styles.noCertificate,
  );

  const expirationDateClasses = clsx(
    styles.expirationDate,
    true && styles.notEligible,
  );

  const certificationsAcquiredClasses = clsx(
    styles.certificationsAcquired,
    name === 'Project, Programme & Portfolio Management' && styles.prince2,
    name === 'RESILIA' && styles.resilia,
    name === 'AgileShift' && styles.agileShift,
    name === 'ITIL 4' && styles.itil,
  );
  const itilSvg = '/images/icons/myaxelos-itil-short.svg';
  const handleClick = () => {
    window.open(
      'https://www.peoplecert.org/ways-to-get-certified/keep-your-certification-current',
      '_blank',
    );
  };

  const handleClickAxelos = () => {
    window.open(
      'https://my.axelos.com/resource-hub/article/continuous-professional-development',
      '_blank',
    );
  };

  return loaded && cpdTrackItem !== undefined ? (
    <div className={classNames}>
      {cpdYears !== undefined && cpdYears?.length > 0 && (
        <>
          <div className={expirationDateClasses}>
            <h3>Renew by: {getDateFormattedForCPD(expiryDate)}</h3>

            {message !== null && <p>{message}</p>}
            {cpdTrackItem.status === 'Manage CPD' && (
              <p>
                Select a title to view your CPD track for that certification. If
                you have multiple certifications, when adding CPD points, you
                can choose to add them to multiple certifications at once.
              </p>
            )}
            {cpdTrackItem.status !== 'Manage CPD' && (
              <>
                <p>
                  You are not Eligible to add any CPDs. To keep your Certificate
                  up to date please sit an exam
                </p>
                <Button.Generic
                  colour={ButtonColour.BRAND_BERRY}
                  label="SIT AN EXAM"
                  style={ButtonStyle.PRIMARY}
                  small
                  // eslint-disable-next-line prettier/prettier
                  className={styles.peopleCertButton}
                  onClick={handleClick}
                ></Button.Generic>
              </>
            )}
          </div>

          <div className={styles.certifications}>
            <h3>Certifications acquired</h3>
            <div className={styles.certification}>
              {certifications.length > 0 &&
                certifications?.map((certificate, index) => {
                  return (
                    <div key={index} className={certificationsAcquiredClasses}>
                      <div className={styles.itemContent}>
                        <div className={styles.title}>
                          <img
                            className={styles.imgSize}
                            src={itilSvg}
                            alt="certificationImage"
                          />
                          <h5>{certificate.qualification.category}</h5>
                        </div>
                        <p className={styles.detailText}>
                          {certificate.qualification.peopleCertName}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className={styles.CPDTrackRecord}>
            <h3>My CPD track record</h3>
            <Button.Generic
              label="CPDs explained"
              colour={ButtonColour.BRAND_BERRY}
              small
              style={ButtonStyle.PRIMARY}
              target={ButtonTarget.BLANK}
              onClick={handleClickAxelos}
            ></Button.Generic>
            <br />
            <br />
            <div className={styles.record}>
              {cpdYears?.map((cpdYear: any, index) => {
                return (
                  <CPDTrackRecord
                    key={`record-${index}`}
                    lastYearPoints={
                      index > 0 ? cpdYears[index - 1]?.totalCpdPoints : 20
                    }
                    CPDTrackRecordItem={cpdYear}
                    year={index + 1}
                    productSuiteId={cpdTrackItem?.id}
                    productSuiteName={cpdTrackItem?.name}
                    setIsFetchDataCallback={setIsFetchDataCallback}
                  />
                );
              })}
            </div>
          </div>

          <div className={styles.peopleCertPath}>
            <h3>{peopleCertPathMessage}</h3>

            <Button.Generic
              colour={ButtonColour.BRAND_BERRY}
              label="Visit Peoplecert"
              style={ButtonStyle.PRIMARY}
              small
              // eslint-disable-next-line prettier/prettier
              className={styles.peopleCertButton}
              onClick={handleClick}
            ></Button.Generic>
          </div>
        </>
      )}

      {(cpdYears === null || cpdYears?.length === 0) && (
        <div className={styles.noCpdYears}>
          <h3>{`YOU DO NOT HAVE ANY ${name} CERTIFICATE`}</h3>

          <p>
            Browse PeopleCert Certificate Families to get the one that help to
            lift your career
          </p>

          <Button.Generic
            colour={ButtonColour.BRAND_BERRY}
            label="Visit Peoplecert"
            style={ButtonStyle.PRIMARY}
            small
            // eslint-disable-next-line prettier/prettier
            className={styles.peopleCertButton}
            onClick={handleClick}
          ></Button.Generic>
        </div>
      )}
    </div>
  ) : (
    <Skeleton />
  );
};

export default CPDTrack;
