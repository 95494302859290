import yup, { ValdationMessages } from 'modules/validation';
import dayjs from 'dayjs';
import { getDateFormatted } from 'modules/utils/utils';

const validateCpdCompletionValid = (value: string): boolean => {
  return dayjs(value).isValid() ? true : false;
};

const validateCpdCompletionMax = (value: string): boolean => {
  return !dayjs(value).isAfter(dayjs()) ? true : false;
};

const minMessage = 'Completion date cannot be before';

function validator(this: yup.StringSchema) {
  return this.test(
    'cpdCompletion',
    ValdationMessages.CPD_COMPLETION_MAX,
    function (value) {
      return value ? validateCpdCompletionMax(value) : true;
    },
  )
    .test('cpdCompletion', ValdationMessages.DATE, function (value) {
      return value ? validateCpdCompletionValid(value) : true;
    })
    .test('minDate', minMessage, function (value) {
      const ref = this.resolve(yup.ref('minDate')) || '';
      // const minDate = dayjs(ref) < dayjs(value) ? true : false;

      //const refDate = getDateFormatted(ref);
      //const valueDate = typeof value == 'string' ? getDateFormatted(value) : '';
      let value2: Date;
      if (value) {
        value2 = new Date(value);
        // console.log('Ref date', refDate);
        // console.log('Value date', valueDate);
        // console.log('ref', ref);
        // console.log('value', value);
        const diff = value2.getTime() - new Date(ref).getTime();
        const minDate = diff > 0 ? true : false;
        //console.log('mindate', minDate);
        return minDate;
      }
      //const minDate = refDate <= valueDate ? true : false;
      return true;
    });
}

export default validator;
