import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Control,
  DeepPartial,
  useForm,
  UnpackNestedValue,
  UseFormReset,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import logger from 'modules/logger';
import Recaptcha from './Recaptcha';
import yup from 'modules/validation';

import styles from './styles.module.scss';

const onError = (errors: any) => logger.debug('Form Validation Errors', errors);

type QuestionsWithRecaptchaProps<T> = {
  children?: (props: {
    control: Control<T>;
    isValid: boolean;
    reset: UseFormReset<T>;
    values: UnpackNestedValue<T>;
    isFormSubmitting: boolean;
  }) => React.ReactNode;
  className?: string;
  defaultValues: UnpackNestedValue<DeepPartial<T>>;
  enableReinitialize?: boolean;
  id: string;
  onSubmit: (
    data: UnpackNestedValue<T>,
    reset: UseFormReset<T>,
    event?: React.BaseSyntheticEvent,
  ) => any | Promise<any>;
  schema?: yup.ObjectSchema;
};

function QuestionsWithRecaptcha<T>({
  children,
  className,
  defaultValues,
  enableReinitialize = false,
  id,
  onSubmit,
  schema,
}: QuestionsWithRecaptchaProps<T>) {
  const formMethods = useForm<T>({
    criteriaMode: 'firstError',
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: schema ? yupResolver(schema) : undefined,
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const {
    control,
    formState: { isSubmitting, isValid },
    reset,
    watch,
  } = formMethods;

  const [grecaptchaLoaded, setGrecaptchaLoaded] = useState(false);
  const [grecaptchaWidget, setGrecaptchaWidget] = useState<any>();
  const onSubmitRef = useRef(onSubmit);
  const formMethodsRef = useRef(formMethods);

  useEffect(() => {
    onSubmitRef.current = onSubmit;
    formMethodsRef.current = formMethods;
  });

  const successCallback = (response: any) => {
    return formMethodsRef.current.handleSubmit(data => {
      logger.debug('Recaptcha - Success Callback');
      return onSubmitRef.current(
        { ...data, 'g-recaptcha-response': response },
        reset,
      );
    }, onError)();
  };

  const executeRecaptcha = (e: any) => {
    logger.debug('Recaptcha - Execute');
    e.preventDefault();
    if (grecaptchaWidget !== null) {
      window.grecaptcha?.reset(grecaptchaWidget);
      window.grecaptcha?.execute(grecaptchaWidget);
    }
  };

  useEffect(() => {
    grecaptchaLoaded && logger.debug(`Recaptcha ${id} - Loaded`);
  }, [grecaptchaLoaded, id]);

  useEffect(() => {
    !!enableReinitialize && reset(defaultValues);
  }, [defaultValues, enableReinitialize, reset]);

  const values = watch();
  const classNames = clsx(styles.questions, className);

  return (
    <form className={classNames} onSubmit={executeRecaptcha}>
      <span id={id} />
      {children &&
        children({
          control,
          isValid,
          reset,
          values,
          isFormSubmitting: isSubmitting,
        })}
      <Recaptcha
        id={id}
        setGrecaptchaLoaded={setGrecaptchaLoaded}
        setGrecaptchaWidget={setGrecaptchaWidget}
        successCallback={successCallback}
      />
    </form>
  );
}

export default React.memo(
  QuestionsWithRecaptcha,
) as typeof QuestionsWithRecaptcha;
