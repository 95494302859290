import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  cpdType: yup.string().required('This field is required'),
  certificateClaimingFor: yup.string(),
  description: yup
    .string()
    .required('This field is required')
    .max(500, 'Description must be at most 500 characters.'),
  claimedPoints: yup
    .string()
    .claimedPoints()
    .required('This field is required'),
  completionDate: yup
    .string()
    .cpdCompletion('minDate')
    .required('This field is required')
    .nullable(),
  whatDidYouLearn: yup
    .string()
    .required('This field is required')
    .max(500, 'What did you learn? must be at most 500 characters.'),
});

export type Model = {
  cpdType: string;
  certificateClaimingFor: string;
  description: string;
  claimedPoints: string;
  completionDate: string;
  whatDidYouLearn: string;
  minDate: string;
  certificateId: string;
};

const cpdType: QuestionType<Model> = {
  name: 'cpdType',
};

const certificateClaimingFor: QuestionType<Model> = {
  name: 'certificateClaimingFor',
};

const description: QuestionType<Model> = {
  name: 'description',
};
const claimedPoints: QuestionType<Model> = {
  name: 'claimedPoints',
};
const completionDate: QuestionType<Model> = {
  name: 'completionDate',
};
const whatDidYouLearn: QuestionType<Model> = {
  name: 'whatDidYouLearn',
};
const minDate: QuestionType<Model> = {
  name: 'minDate',
};
const certificateId: QuestionType<Model> = {
  name: 'certificateId',
};

export const model = {
  cpdType,
  certificateClaimingFor,
  description,
  claimedPoints,
  completionDate,
  whatDidYouLearn,
  minDate,
  certificateId,
};
