import { useCallback, useMemo } from 'react';

import { defaultValues, Model } from '../QuestionSet/model';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';
import useSubscribeUser from 'pages/sign-up/hooks/useSubscribeUser';

type UseSignUpProps = {
  candidateToken: string;
  peopleCertDefaultValues: Model;
  subscriptionPlan: UserSubscriptionPlanType | null;
};

function useSignUp({
  candidateToken,
  peopleCertDefaultValues,
  subscriptionPlan,
}: UseSignUpProps) {
  const { subscribeNewUser, isSubmitting } = useSubscribeUser();
  const pcDefaultValues = {
    candidateNumber: subscriptionPlan?.candidateNumber,
    candidateToken: subscriptionPlan?.candidateToken,
    countryCode: subscriptionPlan?.countryCode,
    email: subscriptionPlan?.email,
    givenName: subscriptionPlan?.givenName,
    requirePayment: 'N',
    subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
    surname: subscriptionPlan?.surname,
  };

  const formData = useMemo(() => {
    return { ...defaultValues, ...peopleCertDefaultValues };
  }, [peopleCertDefaultValues]);
  const pcFormData = useMemo(() => {
    return { ...defaultValues, ...pcDefaultValues, ...peopleCertDefaultValues };
  }, [pcDefaultValues]);
  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: any) => {
      await subscribeNewUser(
        {
          candidateNumber: data.candidateNumber,
          candidateToken: candidateToken,
          countryCode: data.countryCode,
          email: data.email,
          givenName: data.givenName,
          requirePayment: 'N',
          subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
          surname: data.surname,
        },
        SubscriptionStateEnum.PeopleCert_User,
        subscriptionPlan?.subscriptionPlanId,
      );
    },
    [candidateToken, subscriptionPlan, subscribeNewUser],
  );

  return {
    defaultValues: subscriptionPlan?.isExistInPeoplecert
      ? pcFormData
      : formData,
    handleSubmit,
    isSubmitting,
  };
}

export default useSignUp;
