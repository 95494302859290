import React, {
  useContext,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import { AccountDataContext } from 'modules/context';
import { isFreeTrialUser, getDateWithOrdinal } from 'modules/utils/utils';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { ModalType } from 'modules/modals/constants';
import * as Button from 'components/Button';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { SubscriptionsTypes } from 'pages/account-management/types';
import PaymentInformation from './PaymentInformation';
import Subscription, { SubscriptionItemType } from './Subscription';
import styles from './styles.module.scss';
import { useCancelSubscription } from './hooks';

const Subscriptions: React.FunctionComponent<SubscriptionsTypes> = ({
  handleAddPaymentMethod,
  handleEditPaymentMethod,
  handleRemovePaymentMethod,
  paymentMethods,
  subscriptions,
}) => {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const { accountDataContext } = useContext(AccountDataContext);

  const currentPlanEndDate = useMemo(
    () =>
      new Date(
        subscriptions.currentSubscription?.planEnd || '',
      ).toLocaleDateString('en-GB'),
    [subscriptions.currentSubscription?.planEnd],
  );

  const isMigrationUser = useMemo(
    () => subscriptions.isMigratedUser(subscriptions?.currentSubscription),
    [subscriptions],
  );

  const [userId, setUserId] = useState('');
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const { cancelSubscription } = useCancelSubscription();
  const isSubscriptionCancelled =
    accountDataContext?.subscription?.cancelledAt ?? '';
  const formattedDate = `${getDateWithOrdinal(isSubscriptionCancelled, false)}`;

  const showCancelSubscriptionModel = useCallback(() => {
    if (isSubscriptionCancelled !== null && isSubscriptionCancelled !== '') {
      if (!modal.current) {
        modal.current = modalShow({
          onClose: () => {
            !!modal.current && modalHide(modal.current);
            modal.current = null;
          },
          title: 'Cancel Subscription',
          text: `You have already cancelled your subscription. Your access to MyAxelos will end on ${formattedDate}.`,
          type: ModalType.INFO,
        });
      }
    } else if (!modal.current) {
      modal.current = modalShow({
        onCancel: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        onConfirm: async () => {
          try {
            setIsApiSubmitting(true);
            !!modal.current && modalHide(modal.current);
            modal.current = null;
            await cancelSubscription(userId);
          } catch (error) {
            if (!modal.current) {
              modal.current = modalShow({
                onClose: () => {
                  !!modal.current && modalHide(modal.current);
                  modal.current = null;
                },
                title: 'Error',
                text: 'There was an error trying to send your data. Please try again later.',
                type: ModalType.INFO,
              });
            }
          } finally {
            setIsApiSubmitting(false);
          }
        },
        title: 'Cancel Subscription',
        text: `Are you sure? If you choose to cancel your subscription, 
        all of your personal data will be erased once your subscription ends,
        which means you will no longer hold any of your digital badges.`,
        type: ModalType.CONFIRM,
      });
    }
  }, [
    modal,
    modalHide,
    modalShow,
    cancelSubscription,
    userId,
    isSubscriptionCancelled,
    formattedDate,
  ]);

  useEffect(() => {
    setUserId(accountDataContext?.guid ?? '');
  }, [accountDataContext]);

  return accountDataContext ? (
    <React.Fragment>
      <div className={styles.subscriptions}>
        {isFreeTrialUser(accountDataContext) &&
          !isMigrationUser &&
          paymentMethods.length === 0 && (
            <p>
              You are currently on a 1 month free trial expiring on{' '}
              <strong>{` ${currentPlanEndDate}`}</strong>. To continue beyond
              your Renewal date, please click the upgrade button below, where
              you will be upgraded to our annual plan.
            </p>
          )}
        {isFreeTrialUser(accountDataContext) &&
          !isMigrationUser &&
          paymentMethods.length > 0 && (
            <p>
              You are currently on a 1 month free trial, which will
              automatically upgrade to our annual plan on{' '}
              <strong>{` ${
                currentPlanEndDate ? currentPlanEndDate : ''
              }`}</strong>
              . You will not be charged until then.
            </p>
          )}
        {isMigrationUser && (
          <p>
            You're currently using an extended subscription expiring{' '}
            <strong>{` ${currentPlanEndDate}`}</strong>. Choose one of the plans
            below to continue using MyAxelos after this date.
          </p>
        )}
        {!isFreeTrialUser(accountDataContext) &&
          !isMigrationUser &&
          subscriptions.currentSubscription?.planSubName === 'Month' && (
            <p>
              You are currently on a monthly subscription. To upgrade to an
              annual plan and save, click the button below.
            </p>
          )}
        {subscriptions.subscriptionsList?.map(
          (subscription: SubscriptionItemType, index: number) => (
            <Subscription
              handleAddPaymentMethod={handleAddPaymentMethod}
              handleUpgradeClick={subscriptions.handleUpgradeClick}
              isCurrentPlan={subscriptions.isCurrentPlan(
                subscription.currentPlan,
              )}
              isMigrationUser={isMigrationUser}
              isFreeTrial={isFreeTrialUser(accountDataContext)}
              key={`subscription-${index}`}
              paymentMethods={paymentMethods}
              planAvailable={subscription.planAvailable}
              planEnd={subscriptions.currentSubscription?.planEnd}
              planFee={subscription.planFee}
              planFrequency={subscription.planFrequency}
              planName={subscription.planName}
              planSaving={subscription.planSaving}
              planStatus={subscription.planStatus}
              planSubName={subscription.planSubName}
              subscriptionPlanId={subscription.subscriptionPlanId}
            />
          ),
        )}
        <PaymentInformation
          handleAddPaymentMethod={handleAddPaymentMethod}
          handleEditPaymentMethod={handleEditPaymentMethod}
          handleRemovePaymentMethod={handleRemovePaymentMethod}
          paymentMethods={paymentMethods}
        />

        {subscriptions.currentSubscription && (
          <div className={styles.cancelSubscription}>
            <Button.Generic
              colour={ButtonColour.BRAND_BERRY}
              label="Cancel Subscription"
              onClick={() => {
                showCancelSubscriptionModel();
              }}
              small
              style={ButtonStyle.PRIMARY}
              disabled={true} // Delete this after PeopleCert+ Transition
              // disabled={isApiSubmitting}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  ) : null;
};

export default Subscriptions;
