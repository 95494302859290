import React from 'react';
import clsx from 'clsx';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import styles from './styles.module.scss';

export default function FileUploadPage(props: any) {
  const {
    setSelectedFiles,
    setIsFilePicked,
    selectedFiles,
    isFilePicked,
    isSubmitting,
    readOnly,
  } = props;

  const hiddenInput = React.useRef<HTMLInputElement>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles((existingFiles: any) => {
      const files = [...existingFiles, ...(event.target.files as any)];
      event.target.value = '';
      return files;
    });
    setIsFilePicked(true);
    // eslint-disable-next-line no-console
    console.log(selectedFiles);
  };

  const triggerInput = () => {
    if (hiddenInput.current !== null) {
      hiddenInput.current.click();
    }
  };

  const removeFile = (index: number) => {
    const fileToBeRemoved = selectedFiles.splice(index, 1);
    const newArray = selectedFiles.filter((f: any) => f !== fileToBeRemoved);
    setSelectedFiles(newArray);
  };

  const classNames = clsx(styles.addFile, readOnly && styles.noEvents);

  return (
    <div className={classNames}>
      <Carousel
        additionalTransfrom={0}
        slidesToSlide={1}
        arrows
        centerMode={false}
        className={styles.carouselArea}
        containerClass="container-with-dots"
        dotListClass=""
        autoPlay={false}
        draggable
        focusOnSelect={false}
        // infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        swipeable
      >
        {isFilePicked ? (
          selectedFiles.map(
            (
              file: {
                name:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined;
              },
              index: number,
            ) => {
              return (
                <span key={index} className={styles.fileItem}>
                  <div>
                    {!readOnly && (
                      <img
                        key={index}
                        onClick={event => removeFile(index)}
                        className={styles.closeIcon}
                        alt="Close icon"
                        src="/images/icons/x-icon.jpg"
                      />
                    )}
                    <img
                      className={styles.checkIcon}
                      alt="Check icon"
                      src="/images/icons/checkmark-xxl.png"
                    />
                  </div>
                  <p className={styles.fileNameText}>{file.name}</p>
                </span>
              );
            },
          )
        ) : (
          <span></span>
        )}
      </Carousel>
      <div
        onClick={triggerInput}
        onChange={changeHandler}
        className={styles.buttonArea}
      >
        <input
          readOnly={isSubmitting || readOnly}
          type="file"
          multiple
          ref={hiddenInput}
          accept=".doc,.docx,application/pdf, image/*"
          className={styles.displayOpt}
        ></input>

        <img
          className={styles.uploadIcon}
          alt="upload icon"
          src="/images/icons/upload-icon.png"
        />
        <p className={styles.buttonText}>Include an attachment</p>
      </div>
    </div>
  );
}
