import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { filtersHookType, filterObjectType } from 'pages/resource-hub/types';
import { EVENTS_URL, RESOURCE_HUB_URL } from 'modules/utils/constants';
import { useSelectedFiltersURL } from './useSelectedFiltersURL';

export const useSelectedFiltersItems = (
  filters: filtersHookType,
  slug: string,
) => {
  const [topicsSlug, setTopicsSlug] = useState('');
  const [bestPracticesSlug, setBestPracticesSlug] = useState('');
  const [contentTypesSlug, setContentTypesSlug] = useState('');
  const history = useHistory();
  const {
    addDeleteFilterItem,
    refreshResults,
    setRefreshResults,
    selectItemsFromSlug,
  } = useSelectedFiltersURL(slug);

  const path = window.location.href.includes('/resource-hub')
    ? RESOURCE_HUB_URL
    : EVENTS_URL;

  const updateTopicsURL = useCallback(
    (newTopicsURL: string) => {
      let newURL = '';

      if (newTopicsURL !== '') {
        newURL = `${newTopicsURL}/`;
      }

      if (bestPracticesSlug !== '') {
        newURL = `${newURL}${bestPracticesSlug}/`;
      }

      if (contentTypesSlug !== '') {
        newURL = `${newURL}${contentTypesSlug}/`;
      }
      setTopicsSlug(newTopicsURL);
      history.push(`${path}${newURL}`);
    },
    [bestPracticesSlug, contentTypesSlug, history],
  );

  const updateBestPracticesURL = useCallback(
    (newBestPracticesURL: string) => {
      let newURL = '';
      if (topicsSlug !== '') {
        newURL = `${topicsSlug}/`;
      }

      if (newBestPracticesURL !== '') {
        newURL = `${newURL}${newBestPracticesURL}/`;
      }

      if (contentTypesSlug !== '') {
        newURL = `${newURL}${contentTypesSlug}/`;
      }
      setBestPracticesSlug(newBestPracticesURL);
      history.push(`${path}${newURL}`);
    },
    [contentTypesSlug, history, topicsSlug],
  );

  const updateContentTypesURL = useCallback(
    (newContentTypesURL: string) => {
      let newURL = '';

      if (topicsSlug !== '') {
        newURL = `${topicsSlug}/`;
      }
      if (bestPracticesSlug !== '') {
        newURL = `${newURL}${bestPracticesSlug}/`;
      }
      if (newContentTypesURL !== '') {
        newURL = `${newURL}${newContentTypesURL}/`;
      }

      setContentTypesSlug(newContentTypesURL);
      history.push(`${path}${newURL}`);
    },
    [bestPracticesSlug, history, topicsSlug],
  );

  const addDeleteCategory = useCallback(
    (item: filterObjectType) => {
      addDeleteFilterItem(
        item,
        filters.setContentTypesSelected,
        filters.contentTypesSelected,
        updateContentTypesURL,
      );
    },
    [
      addDeleteFilterItem,
      filters.setContentTypesSelected,
      filters.contentTypesSelected,
      updateContentTypesURL,
    ],
  );

  const addDeleteResourceType = useCallback(
    (item: filterObjectType) => {
      addDeleteFilterItem(
        item,
        filters.setTopicsSelected,
        filters.topicsSelected,
        updateTopicsURL,
      );
    },
    [
      addDeleteFilterItem,
      filters.setTopicsSelected,
      filters.topicsSelected,
      updateTopicsURL,
    ],
  );

  const addDeleteCertificate = useCallback(
    (item: filterObjectType) => {
      addDeleteFilterItem(
        item,
        filters.setBestPracticesSelected,
        filters.bestPracticesSelected,
        updateBestPracticesURL,
      );
    },
    [
      addDeleteFilterItem,
      filters.bestPracticesSelected,
      filters.setBestPracticesSelected,
      updateBestPracticesURL,
    ],
  );

  return {
    addDeleteResourceType,
    addDeleteCertificate,
    addDeleteCategory,
    refreshResults,
    selectItemsFromSlug,
    setBestPracticesSlug,
    setContentTypesSlug,
    setRefreshResults,
    setTopicsSlug,
  };
};
