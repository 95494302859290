import * as yup from 'yup';

import { ValdationMessages } from './messages';
import * as validators from './validators';

yup.setLocale({
  mixed: {
    notType: ValdationMessages.NOT_TYPE,
    oneOf: ValdationMessages.ONE_OF,
    required: ValdationMessages.REQUIRED,
  },
  string: {
    email: ValdationMessages.EMAIL,
  },
});

yup.addMethod<yup.StringSchema>(yup.string, 'name', validators.name);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'maxLength',
  validators.maxLengthString,
);
yup.addMethod<yup.StringSchema>(yup.string, 'telephone', validators.telephone);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'userExists',
  validators.userExists,
);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'cpdCompletion',
  validators.cpdCompletion,
);
yup.addMethod<yup.StringSchema>(yup.string, 'password', validators.password);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'claimedPoints',
  validators.claimedPoints,
);

export { default as ValdationMessages } from './messages';

export default yup;
