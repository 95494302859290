/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
import Checkbox from 'components/Inputs/Checkbox';

import styles from './styles.module.scss';
import { Dialog, Fade } from '@material-ui/core';
import * as Button from 'components/Button';
import { useUpdateUserConsentStatus } from './hooks/updateUserConsent';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CLIENT_EMAIL, IS_USER_CONSENT_CHECKED } from 'modules/utils/constants';

type UserConsentProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleUserModal: Function;
};

const UserConsent: React.FunctionComponent<UserConsentProps> = ({
  handleUserModal,
}) => {
  const [termsCheckbox, setTermsCheckbox] = React.useState(false);
  const [privacyCheckbox, setPrivacyCheckbox] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(true);
  const userEmail = sessionStorage.getItem(CLIENT_EMAIL);
  const { updateUserConsentStatus } = useUpdateUserConsentStatus();

  const handleTermsCheckbox = (event?: any) => {
    // eslint-disable-next-line eqeqeq
    if (event != null)
      setTermsCheckbox(event.currentTarget.checked ? true : false);
  };
  const handlePrivacyCheckbox = (event?: any) => {
    if (event != null)
      setPrivacyCheckbox(event.currentTarget.checked ? true : false);
  };

  const handleCloseButton = () => {
    setShowDialog(false);
  };

  const useStyles = makeStyles({
    dialog: {
      maxWidth: '1144px',
      borderRadius: '14px',
    },
  });

  const handleSubmit = () => {
    // eslint-disable-next-line no-console, @typescript-eslint/no-unused-expressions
    if (privacyCheckbox && termsCheckbox === true) {
      updateUserConsentStatus(userEmail);
      setShowDialog(false);
      handleUserModal(false);
    }
  };

  const classes = useStyles();
  return (
    <>
      <div id={styles.loadoverlay}></div>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={showDialog}
        maxWidth="lg"
        title="Agree page to Terms and Policy"
        TransitionComponent={Fade}
      >
        <div className={styles.consent}>
          <div className={styles.popup}>
            <h1>
              <span className={styles.desktopTitle}>
                Update to Terms &amp; Conditions and Privacy Policy
              </span>
              <span className={styles.mobileTitle}>Notification</span>
              <button onClick={handleCloseButton} id={styles.close}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                >
                  <path
                    id={styles.Path_364}
                    data-name="Path 364"
                    d="M26,7.115,23.885,5,15.5,13.385,7.115,5,5,7.115,13.385,15.5,5,23.885,7.115,26,15.5,17.615,23.885,26,26,23.885,17.615,15.5Z"
                    transform="translate(-5 -5)"
                    fill="#282d3d"
                  />
                </svg>
              </button>
            </h1>
            <div className={styles.popupBody}>
              <div className={styles.termsText}>
                <p>
                  Our Terms & Conditions and Privacy Policy have been updated.
                  As with previous updates we have made changes to keep the
                  terms and policy up to date with the services we provide - we
                  also need to reflect some internal reorganisations across our
                  business.
                </p>
                <p>
                  Agreement to continue the use of the service constitutes
                  acceptance of the updated terms and policies. Please consult
                  these at the links below before agreeing.
                </p>
              </div>
              <div className={styles.form}>
                <Checkbox
                  checked={termsCheckbox}
                  label={' '}
                  onChange={handleTermsCheckbox}
                >
                  <a
                    href="https://my.axelos.com/policies/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>
                </Checkbox>
                <Checkbox
                  checked={privacyCheckbox}
                  label={' '}
                  onChange={handlePrivacyCheckbox}
                >
                  <a
                    href="https://my.axelos.com/policies/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Checkbox>
                <div className={styles.buttons}>
                  <Button.Submit
                    onClick={handleSubmit}
                    style={ButtonStyle.SECONDARY}
                    id={styles.agree}
                    colour={ButtonColour.BRAND_BLACK_GRAPE}
                    disabled={!termsCheckbox || !privacyCheckbox}
                    label="Agree"
                  ></Button.Submit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <h3 className={styles.fakeComponentDiv}>Hello</h3> */}

      {/* <div id={styles.loadoverlay}></div>
      <div className={styles.consent}>
        <div className={styles.popup}>
          <h1>
            <span className={styles.desktopTitle}>
              Update to Terms &amp; Conditions and Privacy Policy
            </span>
            <span className={styles.mobileTitle}>Notification</span>
            <button id={styles.close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
              >
                <path
                  id={styles.Path_364}
                  data-name="Path 364"
                  d="M26,7.115,23.885,5,15.5,13.385,7.115,5,5,7.115,13.385,15.5,5,23.885,7.115,26,15.5,17.615,23.885,26,26,23.885,17.615,15.5Z"
                  transform="translate(-5 -5)"
                  fill="#282d3d"
                />
              </svg>
            </button>
          </h1>
          <div className={styles.popupBody}>
            <div className={styles.termsText}>
              <h2>Update to Terms &amp; Conditions and Privacy Policy</h2>
              <p>
                Our Terms & Conditions and Privacy Policy have been updated. As
                with previous updates we have made changes to keep the terms and
                policy up to date with the services we provide - we also need to
                reflect some internal reorganisations across our business.
              </p>
              <p>
                Agreement to continue the use of the service constitutes
                acceptance of the updated terms and policies. Please consult
                these at the links below before agreeing.
              </p>
            </div>
            <div className={styles.form}>
              <div>
                <ul>
                  <li>
                    <input
                      id={styles.termsCheckbox}
                      name="termsCheckbox"
                      type="checkbox"
                      onClick={handleTermsCheckbox}
                      defaultChecked={termsCheckbox}
                    />
                    <label>
                      <a href="<%= termsLink %>" target="_blank">
                        Terms &amp; Conditions
                      </a>
                    </label>
                  </li>
                  <li>
                    <input
                      id={styles.privacyCheckbox}
                      name="privacyCheckbox"
                      type="checkbox"
                      onClick={handlePrivacyCheckbox}
                      defaultChecked={privacyCheckbox}
                    />
                    <label htmlFor="privacyCheckbox">
                      <a href="<%= privacyLink %>" target="_blank">
                        Privacy Policy
                      </a>
                    </label>
                  </li>
                </ul>
                <div className={styles.buttons}>
                  <button
                    id={styles.agree}
                    disabled={!termsCheckbox || !privacyCheckbox}
                  >
                    Agree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id={styles.api} data-name="SelfAsserted" role="main"></div>
      </div> */}
    </>
  );
};

export default UserConsent;
