import React from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import * as Button from 'components/Button';
import styles from './styles.module.scss';

const NoCertification = () => {
  const handleClick = () => {
    window.open(
      'https://www.peoplecert.org/ways-to-get-certified/keep-your-certification-current',
      '_blank',
    );
  };
  return (
    <div className={styles.noCertification}>
      <p className={styles.title}>Start on your path!</p>
      <p className={styles.info}>
        Boost your path's renewal date by acquiring a new certification in the
        family
      </p>

      <Button.Generic
        colour={ButtonColour.BRAND_BERRY}
        label="Visit Peoplecert"
        style={ButtonStyle.PRIMARY}
        small
        className={styles.peopleCertButton}
        onClick={handleClick}
      ></Button.Generic>
    </div>
  );
};

export default NoCertification;
