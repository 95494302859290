import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import * as Question from 'components/Question';
import styles from '../../styles.module.scss';
import { useHistory } from 'react-router-dom';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import * as Button from 'components/Button';
import { model, schema } from '../modal/verifyChangePassword';
import Questions from 'components/Questions';
import { useChangePassword } from '../hooks/useChangePassword';

// eslint-disable-next-line @typescript-eslint/ban-types
type SetPasswordProps = {
  token: string;
  email: string;
};

// eslint-disable-next-line no-empty-pattern
const SetPassword: React.FunctionComponent<SetPasswordProps> = ({
  token,
  email,
}) => {
  const classNames = clsx(styles.verifyEmail);
  const history = useHistory();
  const [inputTypePassword, setInputTypePassword] = useState('password');
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] =
    useState('password');
  const { handleChangePassword, success, isApiSubmitting } =
    useChangePassword();
  const defaultValues = {
    password: '',
    confirmPassword: '',
    token: token,
    email: email,
  };
  const [clickedPassword, setClickedPassword] = useState(false);
  const [clickedConfirmPassword, setClickedConfirmPassword] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const handleClickPassword = (e: any) => {
    setClickedPassword(!clickedPassword);
    const elementsInput = document.querySelectorAll('input');
    const usedElement = elementsInput[0];
    if (usedElement !== null) {
      if (inputTypePassword === 'password') {
        setInputTypePassword('text');
        usedElement.setAttribute('type', inputTypePassword);
      } else {
        setInputTypePassword('password');
        usedElement.setAttribute('type', inputTypePassword);
      }
    }
  };

  const handleClickConfirmPassword = (e: any) => {
    setClickedConfirmPassword(!clickedConfirmPassword);
    const elementsInput = document.querySelectorAll('input');
    const usedElement2 = elementsInput[1];
    if (usedElement2 !== null) {
      if (inputTypeConfirmPassword === 'password') {
        setInputTypeConfirmPassword('text');
        usedElement2.setAttribute('type', inputTypeConfirmPassword);
      } else {
        setInputTypeConfirmPassword('password');
        usedElement2.setAttribute('type', inputTypeConfirmPassword);
      }
    }
  };

  useEffect(() => {
    const button = document.querySelector('button');
    if (button !== null) {
      console.log(button.getAttribute('disabled'));
      if (button.getAttribute('disabled')) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    }
  });

  useEffect(() => {
    const elementsInput = document.querySelectorAll('input');
    const usedElement = elementsInput[0];
    const usedElement2 = elementsInput[1];
    if (usedElement !== null || usedElement2 !== null) {
      usedElement.setAttribute('type', inputTypePassword);
      usedElement2.setAttribute('type', inputTypeConfirmPassword);
    }
  });

  useEffect(() => {
    if (success) {
      history.push('/sign-in');
      window.location.reload();
    }
  });

  return (
    <div>
      <h2 className="email-title">Set new password</h2>
      <div className={styles.passwordFormat}>
        <p>The password must be between 8 and 64 characters long.</p>
        <p>The password must have at least one of each of the following:</p>
        <ul>
          <li>A lowercase letter</li>
          <li>An uppercase letter</li>
          <li>A digit</li>
          <li>A symbol</li>
        </ul>
      </div>
      <div id="api" role="main">
        <div className="localAccount" role="form">
          <Questions
            defaultValues={defaultValues}
            enableReinitialize={false}
            onSubmit={handleChangePassword}
            schema={schema}
          >
            {({ control, isFormSubmitting }) => (
              <React.Fragment>
                <div className={styles.entry}>
                  <div className={styles.entryItem}>
                    <div className={styles.textLeft}>
                      <label htmlFor="password">New Password</label>
                      <div className={styles.wrapper}>
                        <Question.Text
                          control={control}
                          isSubmitting={isFormSubmitting}
                          question={model.password}
                          label=""
                        />
                        <span
                          className={
                            clickedPassword
                              ? styles.revealActive
                              : styles.reveal
                          }
                          onClick={handleClickPassword}
                        ></span>
                      </div>
                      <label htmlFor="confirmPassword">
                        Confirm new Password
                      </label>
                      <div className={styles.wrapper}>
                        <Question.Text
                          control={control}
                          isSubmitting={isFormSubmitting}
                          question={model.confirmPassword}
                          label=""
                        />
                        <span
                          className={
                            clickedConfirmPassword
                              ? styles.revealActive
                              : styles.reveal
                          }
                          onClick={handleClickConfirmPassword}
                        ></span>
                      </div>
                    </div>
                    {/* <Hyperlink.PageLink rel="noreferrer" slug="/auth"> */}
                    <button
                      type="submit"
                      className={
                        isApiSubmitting
                          ? styles.buttonLoading
                          : styles.buttonNew
                      }
                      disabled={isApiSubmitting ? true : false}
                    >
                      <span className={styles.button__text}>
                        Continue&nbsp;
                      </span>
                    </button>
                    {/* </Hyperlink.PageLink> */}
                  </div>
                </div>
              </React.Fragment>
            )}
          </Questions>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SetPassword);
