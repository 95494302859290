import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'debounce-promise';

import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';
import {
  CountryCodeProps,
  PageInfoProps,
  ProductInfoProps,
  VoucherCodeProps,
} from './components/types';
import { Model } from './components/QuestionSet/model';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { SubscriptionStatus } from 'pages/account-management/components/Subscriptions/constants';
import { useLoginToken } from 'modules/hooks';
import { useMarketing } from './hooks/useMarketing';
import { useSignUpState } from './hooks/useSignUpState';
import { useSplitTest } from './hooks/useSplitTest';
import { useSubscriptionPlan } from 'modules/chargebee/hooks/useSubscriptionPlan';
import Template from './Template';
import { logInRedirection } from 'modules/utils/utils';
import { useCookies } from 'react-cookie';
import {
  CLIENTID_COOKIE_NAME,
  CLIENT_ID_ITEM_NAME,
} from 'modules/utils/constants';
import { useHistory } from 'react-router-dom';

export const SignUp: React.FunctionComponent = () => {
  const [cookie] = useCookies<string>([CLIENTID_COOKIE_NAME]);
  const history = useHistory();

  useEffect(() => {
    // console.log('SignUp triggered > cookie > clientid', cookie);
    if (
      cookie.clientid !== undefined &&
      cookie.clientid !== 'undefined' &&
      cookie.clientid !== '' &&
      cookie.clientid !== null
    )
      sessionStorage.setItem(CLIENT_ID_ITEM_NAME, cookie.clientid);
  }, []);

  const [countryCode, setCountryCode] = useState('');
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [value, setValue] = useState('');
  const [couponCodePreviouslyApplied, setCouponCodePreviouslyApplied] =
    useState(false);
  const { testOption } = useSplitTest();
  const { isLoggedIn } = useLoginToken();
  const { plan, candidateToken } = useMarketing(isLoggedIn());
  const { isLoading: signupStateIsLoading, subscriptionState } = useSignUpState(
    isLoggedIn(),
    candidateToken,
  );
  const peopleCertPlan = subscriptionState?.peopleCertPlan;

  const {
    couponCodeMessage,
    couponCodeSubmitting,
    handleClick,
    subscriptionPlan,
    isLoading: subscriptionPlanIsLoading,
  } = useSubscriptionPlan(
    countryCode,
    couponCodeApplied,
    plan ?? peopleCertPlan?.subscriptionPlanId,
    setCouponCodeApplied,
    setCouponCodePreviouslyApplied,
    setValue,
    value,
  );

  const isMyAxelosExistingUser: boolean =
    subscriptionState?.isMyAxelosExistingUser ?? false;

  const loading = useMemo(
    () =>
      signupStateIsLoading ||
      (subscriptionPlan === null && subscriptionPlanIsLoading) ||
      testOption === null ||
      isMyAxelosExistingUser === true,
    [
      signupStateIsLoading,
      subscriptionPlan,
      subscriptionPlanIsLoading,
      testOption,
      isMyAxelosExistingUser,
    ],
  );

  const userState = useMemo(() => {
    if (
      subscriptionPlan?.currentSubscription?.status ===
      SubscriptionStatus.NON_RENEWING
    ) {
      return SubscriptionStateEnum.Subscription_Non_Renewing;
    }
    return !loading
      ? subscriptionState?.subscriptionState ??
          SubscriptionStateEnum.Subscription_Lapsed
      : SubscriptionStateEnum.Subscription_Lapsed;
  }, [
    loading,
    subscriptionState,
    subscriptionPlan?.currentSubscription?.status,
  ]);

  const pageInfo: PageInfoProps = useMemo(() => {
    if (
      userState === SubscriptionStateEnum.Subscribed &&
      subscriptionPlan?.savingAmount &&
      (subscriptionPlan.subscriptionPlanId ===
        ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY ||
        subscriptionPlan.subscriptionPlanId ===
          ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY_MIGRATION)
    ) {
      return {
        pageDescription: `You are currently on a monthly plan. Clicking the Submit button below will upgrade you to an annual plan, saving you £${subscriptionPlan?.savingAmount} per year.`,
        pageTitle: 'Upgrade your subscription',
      };
    }
    return {
      pageDescription: peopleCertPlan?.pageDescription || null,
      pageTitle: peopleCertPlan?.pageTitle || null,
    };
  }, [peopleCertPlan, subscriptionPlan, userState]);

  const peopleCertDefaultValues: Model = useMemo(() => {
    return {
      candidateNumber: peopleCertPlan?.candidateNumber ?? '',
      confirmEmail: peopleCertPlan?.email ?? '',
      consent: false,
      countryCode: peopleCertPlan?.countryCode ?? '',
      email: peopleCertPlan?.email ?? '',
      givenName: peopleCertPlan?.givenName ?? '',
      paymentMethodId: '',
      surname: peopleCertPlan?.surname ?? '',
    };
  }, [peopleCertPlan]);

  const productInfo: ProductInfoProps = useMemo(() => {
    return {
      discountAmount: subscriptionPlan?.discountAmount || '0.00',
      creditAmount: subscriptionPlan?.creditAmount || '0.00',
      paymentAmount: subscriptionPlan?.paymentAmount || '0.00',
      planAmount: subscriptionPlan?.planAmount || '0.00',
      planDescription: subscriptionPlan?.planDescription || null,
      planName: subscriptionPlan?.planName || null,
      salesTaxAmount: subscriptionPlan?.salesTaxAmount || '0.00',
      salesTaxPercentage: subscriptionPlan?.salesTaxPercentage || '0',
    };
  }, [subscriptionPlan]);

  const voucherCode: VoucherCodeProps = useMemo(() => {
    return {
      couponCodeApplied,
      couponCodeMessage,
      couponCodePreviouslyApplied,
      couponCodeSubmitting,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      },
      onClick: handleClick,
      setValue,
      value,
    };
  }, [
    couponCodeApplied,
    couponCodeMessage,
    couponCodePreviouslyApplied,
    couponCodeSubmitting,
    handleClick,
    value,
  ]);

  const country: CountryCodeProps = useMemo(() => {
    return {
      setValue: debounce(setCountryCode, 400, {
        leading: true,
      }),
      value: countryCode,
    };
  }, [countryCode]);

  useEffect(() => {
    if (isMyAxelosExistingUser === true) {
      // logInRedirection();
      // window.location.href = '/';
      history.push('/');
    }
  }, [isMyAxelosExistingUser]);

  return (
    <Template
      candidateToken={peopleCertPlan?.candidateToken ?? ''}
      countryCode={country}
      loading={loading}
      pageInfo={pageInfo}
      peopleCertDefaultValues={peopleCertDefaultValues}
      peopleCertSplit={testOption}
      productInfo={productInfo}
      subscriptionPlan={subscriptionPlan}
      paymentMethods={subscriptionState?.paymentMethods ?? []}
      userState={userState}
      voucherCode={voucherCode}
    />
  );
};
