import React from 'react';
import clsx from 'clsx';
import { API, api } from 'modules/api';
import { useId } from 'react-id-generator';

import { ReactComponent as DownloadSVG } from './download.svg';
import { ReactComponent as ArrowSVG } from '../arrow.svg';
import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';
import logger from 'modules/logger';

import styles from './styles.module.scss';
import { getDateWithOrdinal } from 'modules/utils/utils';

export type PaymentHistoryYearItemProps = {
  paymentDate: string;
  paymentDescription: string;
  paymentAmountNet: string;
  paymentAmount: string;
  salesTaxPerc: string;
  invoiceLink: string;
  salesTaxAmount: string;
};

const PaymentHistoryYearItem: React.FunctionComponent<PaymentHistoryYearItemProps> =
  ({
    paymentDate,
    paymentDescription,
    paymentAmountNet,
    paymentAmount,
    salesTaxPerc,
    invoiceLink,
    salesTaxAmount,
  }) => {
    const { animating, handleClick, open, ref } = useAccordion(false);
    const [id] = useId(1, 'payment-history-year-item');
    const className = clsx(
      styles.paymentHistoryYearItem,
      animating && styles.animating,
      !open && styles.closed,
    );

    const handlePdfDownload = async () => {
      const active = true;
      try {
        await api(API.GET_DOWNLOAD_INVOICE_PDF(invoiceLink)).then(res => {
          // window.location.href = `${process.env.REACT_APP_BACKEND_URL}${invoiceLink}`;
          // window.open(res.data, '_blank');
          const link = document.createElement('a');
          link.href = `${res.data}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } catch (error) {
        active && logger.error('Get Dashboard Page Error', error);
      }
    };

    return (
      <div className={className}>
        <div>
          <h4>{getDateWithOrdinal(paymentDate.split('T')[0])}</h4>
          <Button.Generic
            ariaControls={id}
            ariaExpanded={open}
            ariaLabel={`${
              open ? 'Open' : 'Closed'
            } ${paymentDate} Payment History Item`}
            onClick={handleClick}
          >
            <ArrowSVG />
          </Button.Generic>
        </div>
        <div aria-hidden={!open} id={id} ref={ref}>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>{paymentDescription}</td>
                  <td>{`£${paymentAmountNet}`}</td>
                </tr>
                <tr>
                  <td>{`VAT (${salesTaxPerc}%)`}</td>
                  <td>{`£${salesTaxAmount}`}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>{`£${paymentAmount}`}</th>
                </tr>
              </tfoot>
            </table>
            <div className={styles.downloadPdfDiv}>
              <Button.Generic
                ariaLabel="Download as PDF"
                onClick={handlePdfDownload}
                small
              >
                <DownloadSVG className={styles.pdfdownloadIcon} />
                <span>Download as PDF</span>
              </Button.Generic>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default React.memo(PaymentHistoryYearItem);
