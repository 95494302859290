import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import VerifyEmail from './Components/VerifyEmail';

import styles from './styles.module.scss';
import VerifyOtp from './Components/VerifyOtp';
import SetPassword from './Components/SetPassword';
import { useLocation } from 'react-router-dom';
type ResetPasswordProps = {
  className?: string;
};

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({
  className,
}) => {
  const classNames = clsx(styles.reset, className);
  const [activeTab, setActiveTab] = useState('');
  const [reSubmitEmail, setReSubmitEmail] = useState('');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location?.state !== undefined) {
      setActiveTab('VerifiedOtp');
    }
  }, [location?.state]);

  return (
    <div id={styles.myAxelosResetPassword} className={styles.container}>
      <div
        id="background_branding_container"
        className={styles.myAxelosIntro}
        data-tenant-branding-background-color="true"
      >
        <span className={styles.image}></span>
        <div>
          <h1>
            MyAxelos has transitioned to
            <br />
            <span>PeopleCert+</span>
          </h1>
          <p className={styles.pcLoginInfo}>
            PeopleCert+ brings all membership features, your certification and
            exam information, under one roof, your PeopleCert Account. Please
            login in with your current credentials at{' '}
            <a href="https://www.peoplecert.org/login">
              https://www.peoplecert.org/login
            </a>{' '}
            to experience your new platform.
          </p>
        </div>
        <span className={styles.backgroundSlice}></span>
      </div>
      <div className={styles.myAxelosForm} id="panel">
        <div className={styles.formContainer}>
          <div id="resetPasswordContent"></div>
          {activeTab === '' && (
            <VerifyEmail
              setActiveTab={setActiveTab}
              setReSubmitEmail={setReSubmitEmail}
              setToken={setToken}
              setEmail={setEmail}
            />
          )}
          {activeTab === 'VerifiedOtp' && (
            <VerifyOtp
              setActiveTab={setActiveTab}
              setEmail={setEmail}
              setToken={setToken}
              reSubmitEmail={reSubmitEmail}
            />
          )}
          {activeTab === 'setPassword' && (
            <SetPassword token={token} email={email} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
