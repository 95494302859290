import React, { useState, useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { AutocompleteItemType } from 'modules/api/endpoints/autocomplete';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { LoginTypeEnum } from 'modules/utils/types';
import { TriggerGTMEvent } from 'modules/gtm';
import * as Loading from 'components/Loading';
import { ContentTypePage } from './components/content-type-page';
import { MuiAutocompleteProps, ResourceHubLoadedType } from './types';
import {
  useCuratedPosts,
  useGetSearchData,
  useFilters,
  useMuiAutocomplete,
  useResourceHub,
  useSearchButtonClick,
} from './hooks';
import Template from './Template';

export const ResourceHub: React.FunctionComponent = () => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const { pathname = '' } = useLocation();
  const { slug = '' } = useParams<any>();
  const [autocompleteEntries, setAutocompleteEntries] = useState<
    AutocompleteItemType[] | null
  >([]);
  const [loaded, setLoaded] = useState<ResourceHubLoadedType>({
    contentType: false,
    contentTypePage: false,
    curated: false,
    filters: false,
    resourceHub: false,
  });
  const [searchClick, setSearchClick] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { resources } = useResourceHub(
    setLoaded,
    pathname,
    loaded.filters,
    loginTypeContext,
  );

  const { filters } = useFilters(slug, loginTypeContext);

  const { curatedPages } = useCuratedPosts(setLoaded, loginTypeContext);

  const {
    getClearProps,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useMuiAutocomplete(autocompleteEntries, setAutocompleteEntries);

  const muiAutocompleteProps: MuiAutocompleteProps = {
    getClearProps: getClearProps,
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    getListboxProps: getListboxProps,
    getOptionProps: getOptionProps,
    groupedOptions: groupedOptions,
  };

  const { getData } = useGetSearchData(
    setLoaded,
    setSearchTerm,
    getInputProps,
    filters.fetchSearchData,
    loginTypeContext,
  );

  const { searchEvents } = useSearchButtonClick(
    setLoaded,
    setSearchTerm,
    getInputProps,
    filters.fetchSearchData,
    searchClick,
    setSearchClick,
    loginTypeContext,
  );

  const showMore = () => {
    getData(false);
  };

  useEffect(() => {
    let label = '';
    let action = '';

    if (loginTypeContext === LoginTypeEnum.CONSULTANT) {
      label = 'Resource hub visits (Consultants)';
      action = EventsActions.RESOURCE_HUB_VISITS_CONSULTANTS;
    } else if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
      label = 'Resource hub visits (Individuals)';
      action = EventsActions.RESOURCE_HUB_VISITS_INDIVIDUALS;
    }

    TriggerGTMEvent({
      action: action,
      category: EventsCategories.PAGE_VIEWS,
      label: label,
    });
  }, [loginTypeContext]);

  return loaded.filters ? (
    <React.Fragment>
      {loaded.contentType && slug ? (
        <ContentTypePage
          contentTypes={resources.contentTypes}
          loaded={loaded.contentTypePage}
          setLoaded={setLoaded}
        />
      ) : (
        accountDataContext &&
        loginTypeContext && (
          <Template
            curatedPages={curatedPages}
            filters={filters}
            getData={getData}
            loaded={loaded}
            loginTypeContext={loginTypeContext}
            muiAutocompleteProps={muiAutocompleteProps}
            pathname={pathname}
            resources={resources}
            searchTerm={searchTerm}
            showMore={showMore}
            {...searchEvents}
          />
        )
      )}
    </React.Fragment>
  ) : null;
};

export default ResourceHub;
