import {
  BadgeQualifications,
  BadgesCertificatesPathsTypes,
} from 'pages/badges/types';

export const useCurrentPath = (
  paths?: BadgesCertificatesPathsTypes[],
  slidePosition?: number,
) => {
  const currentPath = paths?.filter(
    (p, index: number) => slidePosition === index,
  );

  const isItilMaster =
    currentPath && currentPath[0]?.designation === 'ITIL 4 Master';

  const count =
    currentPath &&
    currentPath[0]?.qualifications != null &&
    currentPath[0]?.qualifications.filter(
      (obj: BadgeQualifications) => obj?.achieved === false,
    ).length;

  const progress =
    count === 0
      ? 'You have achieved '
      : isItilMaster
      ? count && count > 1
        ? `You are ${count} designations away from `
        : `You are ${count} designation away from `
      : count && count > 1
      ? `You are ${count} exams away from `
      : `You are ${count} exam away from `;

  const designation = currentPath && currentPath[0]?.designation;

  return { designation, progress } as const;
};
