import yup, { ValdationMessages } from 'modules/validation';

const regex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/,
);

export const validatePassword = (value: string): boolean => {
  return regex.test(value) ? true : false;
};

function validator(this: yup.StringSchema) {
  return this.test('password', ValdationMessages.PASSWORD, function (value) {
    return value ? validatePassword(value) : true;
  });
}

export default validator;
