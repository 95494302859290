import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { DashboardLoadedType } from './types';
import {
  useBadgesForHomePage,
  useCandidateDetails,
  useContentTypes,
  useDashboard,
  useEvents,
  useFeaturedResources,
  useKeyTemplates,
  useMaturityModelsHomePage,
  useRefreshNotifications,
  useUpdateBadges,
} from './hooks';
import { useBadgesPolling } from 'pages/badges/hooks/useBadgesPolling';
import Template from './Template';
import { useNotifyOfFreeMonth } from './hooks/useNotifyOfFreeMonth';
import { useGrantAdditionalSubscription } from './hooks/useGrantAdditionalSubscription';
import { useLogUserLoginEvent } from './hooks/useLogUserLoginEvent';
import { useBadges, useBadgesLinks } from 'pages/badges/hooks';
import * as Loading from 'components/Loading';
import { BadgesPageType } from 'pages/badges/types';
import {
  CLIENT_ID_ITEM_NAME,
  IS_USER_CONSENT_CHECKED,
} from 'modules/utils/constants';
import UserConsent from 'components/SSO/UserConsent';

export const Home: React.FunctionComponent = () => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);

  const [loaded, setLoaded] = useState<DashboardLoadedType>({
    achievements: false,
    homeBadges: false,
    badges: false,
    candidateId: false,
    contentTypes: false,
    claim: false,
    events: false,
    featuredResources: false,
    keyTemplates: false,
    maturityModels: false,
  });

  const { candidateIds, setCandidateIds, achievements, setAchievements } =
    useCandidateDetails(setLoaded);

  const { dashboard, isEmployee } = useDashboard();

  const { featuredResources } = useFeaturedResources(setLoaded);
  const { contentTypes } = useContentTypes(setLoaded, dashboard);
  const { events } = useEvents(setLoaded, dashboard);
  const { keyTemplates } = useKeyTemplates(setLoaded, dashboard);

  const { cancelPolling, runPolling } = useBadgesPolling();

  const { claimStates, homeBadges, setHomeBadges } = useBadgesForHomePage(
    runPolling,
    setLoaded,
    candidateIds,
    dashboard,
  );

  const [showUserModal, setShowUserModal] = useState(false);

  const { badgesLinks } = useBadgesLinks(setLoaded);
  const { badges, setBadges } = useBadges(setLoaded, runPolling, candidateIds);
  const badgesData: BadgesPageType = {
    links: badgesLinks,
    earned:
      badges?.filter(b => b?.claimed) &&
      badges?.filter(b => b?.claimed).length > 0
        ? badges?.filter(b => b?.claimed).length
        : false,
    records: badges,
  };

  const { maturityModelsUrl } = useMaturityModelsHomePage(setLoaded, dashboard);
  const { updateBadges } = useUpdateBadges(
    setAchievements,
    setHomeBadges,
    cancelPolling,
    runPolling,
  );
  const { notifications } = useRefreshNotifications();

  const { notifyOfFreeMonth } = useNotifyOfFreeMonth();
  const { grantAdditionalSubscription } = useGrantAdditionalSubscription();
  const { logUserLoginEvent } = useLogUserLoginEvent();

  useEffect(() => {
    // Performance Improvement - New API Call
    const userEmail = accountDataContext?.email;
    const appUserId = accountDataContext?.userId;
    const adUserObjectId = accountDataContext?.guid;

    grantAdditionalSubscription(appUserId, userEmail);
    logUserLoginEvent(adUserObjectId);
    notifyOfFreeMonth(appUserId, userEmail);
  }, [accountDataContext]);

  useEffect(() => {
    if (
      accountDataContext?.policyAccepted !== true &&
      (accountDataContext?.status === 'Active' ||
        accountDataContext?.status === 'InTrail')
    ) {
      setShowUserModal(true);
    } else {
      setShowUserModal(false);
    }
  }, [accountDataContext]);

  return loginTypeContext && accountDataContext ? (
    showUserModal ? (
      <UserConsent handleUserModal={setShowUserModal} />
    ) : (
      <Template
        achievements={achievements}
        homeBadges={homeBadges}
        badges={badgesData}
        candidateIds={candidateIds}
        claimStates={claimStates}
        contentTypes={contentTypes}
        dashboard={dashboard}
        featuredResources={featuredResources}
        events={events}
        isEmployee={isEmployee}
        notifications={notifications}
        loaded={loaded}
        keyTemplates={keyTemplates}
        maturityModelsUrl={maturityModelsUrl}
        updateBadges={updateBadges}
        setCandidateIds={setCandidateIds}
        cancelPolling={cancelPolling}
      />
    )
  ) : null;
};
