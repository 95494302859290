import {
  USER_ROLE_CONSULTANT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_INDIVIDUAL,
} from './constants';

export type NotificationItem = {
  buttonLabel: string;
  date: string | null;
  dateCreated: string;
  dateDismissed: string | null;
  id: number;
  isDismissed: boolean;
  message: string;
  type: string;
  url: string;
};

export enum SubscriptionStateEnum {
  Consultant_User = 'CONSULTANT_USER',
  Migrated_User = 'MIGRATED_USER',
  New_User = 'NEW_USER',
  Organisation_User = 'ORGANISATION_USER',
  PeopleCert_User = 'PEOPLECERT_USER',
  Subscribed = 'SUBSCRIBED',
  Subscription_Non_Renewing = 'SUBSCRIPTION_NON_RENEWING',
  Subscription_Lapsed = 'SUBSCRIPTION_LAPSED',
}

type SubscriptionUser = {
  cancelScheduleCreatedAt: string | null;
  cancelledAt: string | null;
  couponCode: string;
  currentTermEnd: string;
  customerId: string;
  hasScheduledChanges: boolean;
  planId: string;
  status: string;
  subscriptionId: string;
};

export type AccountDataType = {
  name: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  jobTitle: string;
  userRoles: string[];
  candidateNumbers: string[];
  profilePhoto: string;
  notifications: NotificationItem[];
  subscription: SubscriptionUser;
  lastLogin: string;
  userId: number;
  policyAccepted: boolean;
  status: string;
  redirectToSubscribePage: boolean;
  guid: string;
};

export const UserRolesKeys: any = {
  Individual: 1,
  Consultant: 2,
  Employee: 3,
};

export const UserRolesNames: any = {
  1: USER_ROLE_INDIVIDUAL,
  2: USER_ROLE_CONSULTANT,
  3: USER_ROLE_EMPLOYEE,
};

export enum LoginTypeEnum {
  INDIVIDUAL = 1,
  CONSULTANT = 2,
  EMPLOYEE = 3,
}
