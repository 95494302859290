import yup, { ValdationMessages } from 'modules/validation';

const regex = new RegExp('^[ 0-9+()-]+$');
const validateTelephone = (value: string): any => {
  if (value && value.length > 0) {
    return regex.test(value) ? true : false;
  }
};

function validator(this: yup.StringSchema) {
  return this.test('telephone', ValdationMessages.TELEPHONE, function (value) {
    return value ? validateTelephone(value) : true;
  });
}

export default validator;
