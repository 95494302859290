import { QuestionType } from 'components/Question/Template/types';
import { BraintreeDefaultValues } from 'modules/braintree/types';
import yup from 'modules/validation';

export const schema = [
  /* Sign Up With Payment Schema - Step 1 */
  yup.object().shape({
    givenName: yup
      .string()
      .required()
      .name()
      .max(255, 'First name must be at most 255 characters.'),
    surname: yup
      .string()
      .required()
      .name()
      .max(255, 'Surname must be at most 255 characters.'),
    email: yup
      .string()
      .required()
      .email()
      .userExists()
      .max(255, 'Email must be at most 255 characters.'),
    confirmEmail: yup
      .string()
      .required('Please confirm your email')
      .email()
      .oneOf([yup.ref('email')], "Your email address doesn't match"),
    candidateNumber: yup
      .string()
      .max(25, 'Candidate number must be at most 25 characters.'),
    countryCode: yup.string().required(),
  }),

  /* Sign Up With Payment Schema - Step 2 */
  yup.object().shape({
    consent: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions.')
      .required(),
  }),

  /* Sign Up Schema */
  yup.object().shape({
    givenName: yup.string().required().name(),
    surname: yup.string().required().name(),
    email: yup.string().required().email().userExists(),
    confirmEmail: yup
      .string()
      .required('Please confirm your email')
      .email()
      .oneOf([yup.ref('email')], "Your email address doesn't match"),
    countryCode: yup.string().required(),
    consent: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions.')
      .required(),
  }),
  /* Upgrade Subscription Schema */
  yup.object().shape({
    paymentMethodId: yup.string().required(),
    consent: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions.')
      .required(),
  }),
];

export const SignupWithPaymentPCertSchema =
  /* Sign Up With Payment PeopleCert user Schema - Step 1 */
  yup.object().shape({
    givenName: yup
      .string()
      .required()
      .name()
      .max(255, 'First name must be at most 255 characters.'),
    surname: yup
      .string()
      .required()
      .name()
      .max(255, 'Surname must be at most 255 characters.'),
    email: yup
      .string()
      .required()
      .email()
      // .userExists()
      .max(255, 'Email must be at most 255 characters.'),
    confirmEmail: yup
      .string()
      .required('Please confirm your email')
      .email()
      .oneOf([yup.ref('email')], "Your email address doesn't match"),
    countryCode: yup.string().required(),
  });

export const CountryOfResidenceSchema = yup.object().shape({
  givenName: yup.string().required().name(),
  surname: yup.string().required().name(),
  countryCode: yup.string().required(),
});

export type Model = BraintreeDefaultValues & {
  givenName: string;
  surname: string;
  email: string;
  confirmEmail: string;
  candidateNumber: string;
  countryCode: string;
  consent: boolean;
  paymentMethodId: string;
};

export const defaultValues: Model = {
  cardholderName: '',
  cvv: '',
  expirationMonth: '',
  expirationYear: '',
  number: '',
  //
  billingFirstName: '',
  billingLastName: '',
  billingStreetAddress: '',
  billingExtendedAddress: '',
  billingLocality: '',
  billingPostalCode: '',
  billingCountryCodeAlpha2: '',
  //
  givenName: '',
  surname: '',
  email: '',
  confirmEmail: '',
  candidateNumber: '',
  countryCode: '',
  consent: false,
  paymentMethodId: '',
};

const givenName: QuestionType<Model> = {
  name: 'givenName',
};

const surname: QuestionType<Model> = {
  name: 'surname',
};

const email: QuestionType<Model> = {
  name: 'email',
};

const confirmEmail: QuestionType<Model> = {
  name: 'confirmEmail',
};

const candidateNumber: QuestionType<Model> = {
  name: 'candidateNumber',
};

const countryCode: QuestionType<Model> = {
  name: 'countryCode',
};

const consent: QuestionType<Model> = {
  name: 'consent',
};

const paymentMethodId: QuestionType<Model> = {
  name: 'paymentMethodId',
};

export const model = {
  givenName,
  surname,
  email,
  confirmEmail,
  candidateNumber,
  countryCode,
  consent,
  paymentMethodId,
};
