import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import {
  BOOKMARKS_URL,
  CONGRATULATIONS_PAGE_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  USER_ROLE_CONSULTANT,
} from 'modules/utils/constants';
import {
  ButtonColour,
  ButtonStyle,
  IconStyle,
} from 'components/Button/constants';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { ReactComponent as PrimaryBookmarkSVG } from 'modules/theme/icons/general/bookmark-primary.svg';
import { useLoginToken } from 'modules/hooks';
import { useNavigation } from './hooks/useNavigation';
import * as Button from 'components/Button';
import Account from './Account';
import BurgerMenuButton from './BurgerMenuButton';
import Logo from 'components/Logo';
import Menu, { NavMenuItem } from './Menu';

import styles from './styles.module.scss';

type HeaderProps = {
  navMenu?: NavMenuItem[];
};

const Header: React.FunctionComponent<HeaderProps> = ({ navMenu }) => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const { pathname } = useLocation();
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: 767px)`,
  });

  const { isLoggedIn } = useLoginToken();

  const { handleCloseMenu, handleMenuToggle, menuAnimating, navRef } =
    useNavigation({
      isMobile,
      setToggleMobileMenu,
      toggleMobileMenu,
    });

  const classNames = clsx(
    styles.header,
    toggleMobileMenu && styles.menuAnimating,
  );

  if (pathname === '/sign-in') return null;
  if (pathname === '/reset-password') return null;
  if (pathname === '/congratulations') return null;

  return (
    <React.Fragment>
      {/* Show Header Banner On All Page  */}
      <p className={styles.infoBanner}>
        Exciting news: MyAxelos is transitioning to a brand new membership
        experience. In preparation for our launch, we'll need to implement a few
        adjustments, therefore, for the week of Monday, 26th February 2024,
        certain MyAxelos functionalities will be temporarily unavailable. These
        include: Editing of CPD points, Access to Digital Badges, Subscription
        changes, Payment detail updates. Rest assured, access to all other
        resources and content will remain uninterrupted during this period.
        {/* Please visit{' '}
        <a
          href="https://www.peoplecert.org/membership"
          target="_blank"
          rel="noreferrer"
        >
          www.peoplecert.org/membership
        </a>{' '}
        to learn more. */}
      </p>

      <header className={classNames}>
        <div className="container">
          <Logo
            isLoggedIn={isLoggedIn}
            pathname={pathname}
            role={loginTypeContext}
          />
          {!isMobile &&
            isLoggedIn() &&
            (accountDataContext?.subscription !== null ||
              accountDataContext?.userRoles.includes(USER_ROLE_CONSULTANT)) && (
              <Menu
                accountDataContext={accountDataContext}
                forwardedRef={navRef}
                handleCloseMenu={handleCloseMenu}
                isLoggedIn={isLoggedIn}
                isMobile={isMobile}
                navLinks={navMenu}
                toggleMobileMenu={toggleMobileMenu}
              />
            )}
          {!isMobile &&
            isLoggedIn() &&
            accountDataContext !== undefined &&
            (accountDataContext?.subscription !== null ||
              accountDataContext?.userRoles.includes(USER_ROLE_CONSULTANT)) && (
              <Button.Icon
                ariaLabel="Bookmarks"
                className={styles.bookmark}
                iconStyle={IconStyle.PRIMARY}
                slug={BOOKMARKS_URL}
                title="Bookmarks"
              >
                <PrimaryBookmarkSVG />
              </Button.Icon>
            )}
          {isMobile &&
            isLoggedIn() &&
            (accountDataContext?.subscription !== null ||
              accountDataContext?.userRoles.includes(USER_ROLE_CONSULTANT)) && (
              <BurgerMenuButton
                handleMenuToggle={handleMenuToggle}
                toggleMobileMenu={toggleMobileMenu}
              />
            )}
          {!isMobile &&
            isLoggedIn() &&
            accountDataContext !== undefined &&
            (accountDataContext?.subscription !== null ||
              accountDataContext?.userRoles.includes(USER_ROLE_CONSULTANT)) && (
              <Account
                accountDataContext={accountDataContext}
                handleCloseMenu={handleCloseMenu}
              />
            )}
          {!isLoggedIn() &&
            pathname !== SIGN_UP_URL &&
            pathname !== CONGRATULATIONS_PAGE_URL && (
              <Button.CustomLink
                colour={ButtonColour.BRAND_AQUA}
                className={styles.signIn}
                href={LOGIN_URL}
                label="Sign in"
                style={ButtonStyle.SECONDARY}
              >
                <ChevronSVG />
              </Button.CustomLink>
            )}
        </div>
      </header>
      {isMobile &&
        isLoggedIn() &&
        (accountDataContext?.subscription !== null ||
          accountDataContext?.userRoles.includes(USER_ROLE_CONSULTANT)) && (
          <Menu
            accountDataContext={accountDataContext}
            forwardedRef={navRef}
            handleCloseMenu={handleCloseMenu}
            isLoggedIn={isLoggedIn}
            isMobile={isMobile}
            navLinks={navMenu}
            menuAnimating={menuAnimating}
            toggleMobileMenu={toggleMobileMenu}
          />
        )}
    </React.Fragment>
  );
};

export default Header;
