/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from 'react';
import clsx from 'clsx';

import * as Question from 'components/Question';
import styles from '../../styles.module.scss';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import * as Button from 'components/Button';
import { model, schema } from '../modal/verifyOtpModal';
import Questions from 'components/Questions';
import { useVerifyOtp } from '../hooks/useVerifyOtp';
import { useVerifyEmail } from '../hooks/useVerifyEmail';
import { CLIENT_EMAIL } from 'modules/utils/constants';

type VerifyOtpProps = {
  setActiveTab: Function;
  reSubmitEmail: string;
  setEmail: Function;
  setToken: Function;
};

// eslint-disable-next-line no-empty-pattern
const VerifyOtp: React.FunctionComponent<VerifyOtpProps> = ({
  setActiveTab,
  reSubmitEmail,
  setEmail,
  setToken,
}) => {
  const classNames = clsx(styles.verifyEmail);
  const {
    checkVerificationCode,
    successfullyVerified,
    isApiSubmitting,
    verifyToken,
  } = useVerifyOtp();
  const { handleResetWithoutModelEmail } = useVerifyEmail();

  useEffect(() => {
    if (successfullyVerified === true) {
      setActiveTab('setPassword');
      setEmail(
        sessionStorage.getItem(CLIENT_EMAIL) !== null
          ? sessionStorage.getItem(CLIENT_EMAIL)
          : reSubmitEmail,
      );
      setToken(verifyToken);
    }
  }, [setActiveTab, successfullyVerified]);

  const defaultValues = {
    otp: '',
  };

  return (
    <div>
      <h2 className="email-title">Verify your email</h2>
      <div id="api" role="main">
        <div className="localAccount" role="form">
          <div className="entry">
            <div className={styles.entryItem}>
              <div className={styles.verifyText}>
                <p>Verification code has been send to your inbox</p>
              </div>
              <Questions
                defaultValues={defaultValues}
                enableReinitialize={false}
                onSubmit={checkVerificationCode}
                schema={schema}
              >
                {({ control, isFormSubmitting }) => (
                  <React.Fragment>
                    <div className={styles.entry}>
                      <div className={styles.entryItem}>
                        <label htmlFor="verifyOtp">Verification Code</label>
                        <div className={styles.textLeft}>
                          <Question.Text
                            control={control}
                            isSubmitting={isFormSubmitting}
                            question={model.otp}
                            label=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.submitOtpButtons}>
                      <div>
                        <button
                          type="submit"
                          className={
                            isApiSubmitting
                              ? styles.buttonLoading
                              : styles.buttonNew
                          }
                          disabled={isApiSubmitting ? true : false}
                        >
                          <span className={styles.button__text}>
                            Verify Code&nbsp;
                          </span>
                        </button>
                      </div>
                      <div
                        className={styles.sendNewCodeDiv}
                        onClick={() =>
                          handleResetWithoutModelEmail(reSubmitEmail)
                        }
                      >
                        <span className={styles.sendNewCode}>
                          send new code
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Questions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VerifyOtp);
