import React, { useCallback, useContext } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import Zoom from '@material-ui/core/Zoom';
import clsx from 'clsx';

import { ReactComponent as CrossSVG } from 'modules/theme/icons/general/cross.svg';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import PlatformContext from 'modules/platform/context';
import { ModalSize } from 'modules/modals/constants';
import { htmlSafe } from 'modules/utils/utils';
import * as Button from 'components/Button';
import ModalOverlay from '../Overlay';

import modalStyles from 'modules/modals/styles.module.scss';
import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalInfoTemplateProps = {
  onClose: () => void;
  size?: ModalSize;
  text?: string | JSX.Element;
  title: string;
  hideX?: boolean;
};

export const ModalInfoTemplate = React.memo<ModalInfoTemplateProps>(
  ({ onClose, size = ModalSize.NARROW, text, title, hideX }) => {
    const classNames = clsx(modalStyles[`${size}`], styles.modalInfo);
    const buttonClassNames = clsx(modalStyles.buttons, styles.buttons);
    return (
      <section className={classNames} role="dialog">
        <header>
          <h2>{title}</h2>
          {!hideX && (
            <Button.Generic
              onClick={onClose}
              ariaLabel="Close popup"
              title="Close popup"
            >
              <CrossSVG />
            </Button.Generic>
          )}
        </header>
        <div>
          {typeof text === 'string' ? <p>{htmlSafe(text)}</p> : text}
          <div className={buttonClassNames}>
            <Button.Generic
              ariaLabel="Close popup"
              colour={ButtonColour.BRAND_BLACK_GRAPE}
              label="Close"
              onClick={onClose}
              style={ButtonStyle.SECONDARY}
            />
          </div>
        </div>
      </section>
    );
  },
);

export type ModalInfoProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onClose?: () => void;
  open: boolean;
  size?: ModalSize;
  text?: string | JSX.Element;
  title: string;
  hideX?: boolean;
};

const Info: React.FunctionComponent<ModalInfoProps> = ({
  destroyModal,
  disableRestoreFocus,
  hideModal,
  onClose,
  open,
  text,
  title,
  hideX,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose],
  );
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalInfoTemplate
          onClose={handleClose}
          text={text}
          title={title}
          hideX={hideX}
        />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Info;
