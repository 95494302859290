import { useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesLinksGetResponse } from 'modules/api/endpoints/badgesLinks';
import { BadgeLinksTypes, BadgesLoadedType } from '../types';
import logger from 'modules/logger';

export const useBadgesLinks = (
  setLoaded: React.Dispatch<React.SetStateAction<any>>,
) => {
  const [badgesLinks, setBadgesLinks] = useState<BadgeLinksTypes>();
  //TODO done useEffect
  useEffect(() => {
    let active = true;
    (async () => {
      try {
        const response: BadgesLinksGetResponse = await api(
          API.GET_BADGE_LINKS(),
        );
        active && setBadgesLinks(response.data.entries);
      } catch (error) {
        active && logger.error('Get Badges Error', error);
      } finally {
        active &&
          setLoaded((prevState: any) => ({
            ...prevState,
            certificates: true,
          }));
      }
    })();
    return () => {
      active = false;
    };
  }, [setLoaded]);

  return { badgesLinks };
};
