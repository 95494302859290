import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { JSEncrypt } from 'jsencrypt';
import { useCookies } from 'react-cookie';
import $ from 'jquery';

import { AccountDataContext } from 'modules/context';
import { api, API } from 'modules/api';
import logger from 'modules/logger';
import { Model } from '../../../../components/SSO/Signin/model';
import {
  CLIENTID_COOKIE_NAME,
  CLIENT_ID_ITEM_NAME,
  CLIENT_EMAIL,
  TOKEN_PARAM_NAME,
  USER_IS_MY_AXELOS_ERROR,
  LOG_OUT_TRIGGERED,
} from 'modules/utils/constants';
import { useLoginToken } from 'modules/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { CookieSetOptions } from 'pages/sign-up/hooks/types';
import { useJSPeoplecertApplication } from 'modules/utils/PeoplecertExternalApplication';
import { any } from 'prop-types';
import { domainCreation } from '../utils';
// import useModalAction from 'modules/modals/hooks/useModalAction';
// import { ModalType } from 'modules/modals/constants';

export const useSignIn = () => {
  const application = useJSPeoplecertApplication();

  const history = useHistory();
  const { isLoggedIn, fetchAccountData } = useLoginToken();
  const { search: params, pathname } = useLocation();

  const { accountDataContext } = useContext(AccountDataContext);
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  // const { modalShow, modalHide } = useModalAction();
  // const errorModal = useRef<string | null>(null);
  // const [userConsentModal, setUserConsentModal] = useState(false);
  const [isError, setIsError] = useState('');
  const [isUserMyAxelos, setIsUserMyAxelos] = useState(false);
  const [userRole, setUserRole] = useState('');

  const active = useRef(true);
  const defaultValues: Model = useMemo(() => {
    return {
      userName: '',
      pass: '',
    };
  }, [accountDataContext]);

  const [clientId, setClientId] = useState(
    sessionStorage.getItem(TOKEN_PARAM_NAME),
  );

  const [cookie, setCookie, removeCookie] = useCookies<string>([
    CLIENTID_COOKIE_NAME,
  ]);

  const today = new Date();
  today.setMinutes(today.getMinutes() + 30);
  const cookieOptions: CookieSetOptions = {
    path: '/',
    maxAge: 3600,
    expires: today,
    domain: '.axelos.com',
  };

  const useIsUserMyAxelos = async (email: any) => {
    await api(API.GET_IS_USER_ONLY_AXELOS(email))
      .then(res => {
        setIsUserMyAxelos(res.data);
        if (res.data === true) {
          setIsError(USER_IS_MY_AXELOS_ERROR);
          sessionStorage.setItem(CLIENT_EMAIL, email);
        }
      })
      .catch(err => logger.error(err));
  };
  const handleSubmit = async (data: Model) => {
    sessionStorage.setItem(LOG_OUT_TRIGGERED, '');

    // const recapchaElement = document?.getElementById('reCaptcha');
    // recapchaElement?.setAttribute('style', 'display: none');
    // const captchaValue = recapchaElement ? $('#reCaptchaResponse').text() : '';
    const captchaValue = window.grecaptcha.getResponse();

    //TODO -- show recaptcha errors
    // if (isCaptchaVisible && captchaValue == '') {
    //   application.showErrorNotification(
    //     'Security verification failed',
    //     'errorDIV',
    //   );
    //   return;
    // }
    if (!isApiSubmitting && captchaValue !== '') {
      try {
        // setIsApiSubmitting(true);
        setIsError('');
        const cb = new Date().getTime();
        //Value from env from .envv
        // const publicKey = process.env.REACT_APP_PUBLIC_KEY;
        const publicKey =
          'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDa5kl2GxrW8QuZppNt961nYO0WvXhgXaylXw4LXLKxutphlaCuXvGPu/ONhTvPhMPlGp+Q3XAJxCsIT3HQtSw6pM4gd6bxqI5FLMtIqFeX0eWCPM4gPCJJkvAjma5qFD+AU3XO18I/YQCI+V3q7iJtPvYNsx03dNCpesryZDEefQIDAQAB';
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        const bodyObj = {
          username: data.userName,
          password: data.pass,
        };
        sessionStorage.setItem(CLIENT_EMAIL, data.userName);
        const encrypted = encryptor.encrypt(JSON.stringify(bodyObj));
        await api(API.POST_LOGIN_FORM(cb, captchaValue, encrypted)).then(
          res => {
            const id = res.data?.headers[0]?.value[0];

            if (
              id !== '' &&
              id !== undefined &&
              id !== 'undefined' && // TODO - Refactor
              id !== null
            ) {
              if (res.data?.statusCode === 200) {
                console.log('p', res.data);
                setClientId(id);
                sessionStorage.setItem(CLIENT_ID_ITEM_NAME, id);
                setCookie(CLIENTID_COOKIE_NAME, id, cookieOptions);
                // application.manageDomainCookies('add', [], id);
                localStorage.setItem('user_access', res.data?.reasonPhrase);
                domainCreation(id);

                setTimeout(() => {
                  history.push('/');
                }, 3000);
              } else {
                const message = res.data?.reasonPhrase?.split('~');
                setIsError(message[0]);
              }
            } else {
              const message = res.data?.reasonPhrase?.split('~');
              setIsError(message[0]);
            }
          },
        );
      } catch (error) {
        if (active.current) {
          logger.error('handleSubmit Error', error);
        }
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    } else {
      setIsError('Empty Captcha Value');
      window.grecaptcha.reset(0);
    }
  };

  // Make API call after getting clientId
  useEffect(() => {
    // SSO API CALL
    const ssoAPI = async (id: string | null, param: any) => {
      try {
        await api(API.GET_AXELOS_SSO(id)).then(res => {
          if (res === null) {
            sessionStorage.removeItem(TOKEN_PARAM_NAME);
          }
        });

        await api(API.GET_SET_PC_SSO_COOKIES(param)).then(res => {
          if (res === null) {
            sessionStorage.removeItem(TOKEN_PARAM_NAME);
          }
        });

        history.push('/');
      } catch (e) {
        console.log('ssoAPI call > Error', e);
      }
    };

    if (
      clientId !== '' &&
      clientId !== undefined &&
      clientId !== 'undefined' && // TODO - refactor
      clientId !== null
    ) {
      removeCookie(CLIENTID_COOKIE_NAME);
      setCookie(CLIENTID_COOKIE_NAME, clientId, cookieOptions);

      const requesterBodyObj = {
        clientid: clientId,
        cb: new Date().getTime(),
      };
      ssoAPI(clientId, requesterBodyObj);
      // application.manageDomainCookies('add', [], clientId);

      // Check if cookie already exist, then redirect to home
      history.push('/');
      // if (isLoggedIn()) {
      //   fetchAccountData();
      // }
    } else if (
      cookie.clientid !== '' &&
      cookie.clientid !== undefined &&
      cookie.clientid !== 'undefined' && // TODO - Refactor
      cookie.clientid !== null
    ) {
      // console.log('TESTING CLIENT ID VALUE COOKIE', cookie.clientid);
      sessionStorage.setItem(CLIENT_ID_ITEM_NAME, cookie.clientid);
      history.push('/');
    }
  });

  return {
    defaultValues,
    handleSubmit,
    isApiSubmitting,
    isUserMyAxelos,
    useIsUserMyAxelos,
    loading: !accountDataContext,
    error: isError,
  };
};
