import React, { useCallback, useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { AccountDataContext } from 'modules/context';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { getDateFormattedNotif } from 'modules/utils/utils';
import { isFreeTrialUser } from 'modules/utils/utils';
import { NotificationAction } from './constants';
import { ReactComponent as BellSvg } from 'modules/theme/icons/general/bell.svg';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { ReactComponent as CrossSvg } from 'modules/theme/icons/general/cross.svg';
import { TriggerGTMEvent } from 'modules/gtm';
import { useNotifications } from './hooks/useNotifications';
import { useOpenSubscriptions } from 'modules/hooks/useOpenSubscriptions';
import * as Button from 'components/Button';
import styles from './styles.module.scss';

type NotificationsProps = {
  buttonLabel: string;
  date: string | null;
  id: number;
  message: string;
  type: string;
};

const Notifications: React.FunctionComponent<NotificationsProps> = ({
  buttonLabel,
  date,
  id,
  message,
  type,
}) => {
  const { handleClose, isDismissed, ref } = useNotifications(id);
  const isMobileBreakpoint = useMediaQuery({
    query: '(max-width: 1153px) and (min-width: 768px), (max-width: 724px)',
  });
  const { openSubscriptions } = useOpenSubscriptions();
  const { accountDataContext } = useContext(AccountDataContext);

  const isMigratedUser = useMemo(
    () =>
      accountDataContext?.subscription?.planId ===
        ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_MONTHLY_MIGRATION ||
      accountDataContext?.subscription?.planId ===
        ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY_MIGRATION,
    [accountDataContext?.subscription?.planId],
  );

  const buttonText = useMemo(
    () =>
      (accountDataContext && isFreeTrialUser(accountDataContext)) ||
      type === NotificationAction.MIGRATION_TYPE
        ? 'Choose Plan'
        : buttonLabel,
    [accountDataContext, buttonLabel, type],
  );

  const handleClick = useCallback(() => {
    TriggerGTMEvent({
      action: EventsActions.CLICK_UPDATE_CARD_DETAILS_BUTTON,
      category: EventsCategories.DASHBOARD,
      label: '',
    });
    openSubscriptions();
  }, [openSubscriptions]);

  return !isDismissed ? (
    <div className={styles.notifications} ref={ref}>
      <div>
        <BellSvg />
      </div>
      <div>
        {accountDataContext &&
        isFreeTrialUser(accountDataContext) &&
        !isMigratedUser
          ? 'You are currently on a 1 month free trial. To continue beyond your free trial period, please choose a plan'
          : isMigratedUser
          ? "We've added 30 days free to your existing subscription. To continue beyond then, please choose a plan"
          : message}
      </div>
      {date && <time dateTime={date}>{getDateFormattedNotif(date)}</time>}
      {!isMobileBreakpoint && (
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          label={buttonText}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
          small={true}
        />
      )}
      {isMobileBreakpoint && (
        <Button.Generic
          className={styles.button}
          label={buttonText}
          onClick={handleClick}
        >
          <ChevronSVG />
        </Button.Generic>
      )}
      <Button.Generic ariaLabel="Hide Notification" onClick={handleClose}>
        <CrossSvg />
      </Button.Generic>
    </div>
  ) : null;
};

export default React.memo(Notifications);
