import React from 'react';

import { BadgesCandidateIdTypes } from 'pages/badges/types';
import { htmlSafe } from 'modules/utils/utils';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type OverviewProps = {
  badgesEarned: boolean | number;
  candidateId?: BadgesCandidateIdTypes[];
  loaded: boolean;
};

const Overview: React.FunctionComponent<OverviewProps> = ({
  badgesEarned,
  candidateId,
  loaded,
}) => {
  const numberOfBadges =
    typeof badgesEarned === 'number'
      ? `You earned <span>${badgesEarned} badges<span>`
      : undefined;

  const text =
    numberOfBadges && candidateId && candidateId.length > 1
      ? htmlSafe(numberOfBadges)
      : 'You will view all your earned badges here';

  return loaded ? (
    <React.Fragment>
      {badgesEarned > 0 && (
        <div className={styles.overview}>
          <h3>Your Digital Badges</h3>
          <p>{text}</p>
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Overview;
