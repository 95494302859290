import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { Model } from './EmailForm/model';
import { ModalType } from 'modules/modals/constants';
import { useChangeEmail } from './EmailForm/useChangeEmail';
import { useChangePass } from './EmailForm/useChangePass';
import AccountSettingsItem from './AccountSettingsItem';
import EmailForm from './EmailForm';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';
import PasswordForm from './PasswordForm';

import styles from './styles.module.scss';

const AccountSettings: React.FunctionComponent = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [changeEmailTextLoading, setChangeEmailTextLoading] = useState(true);
  const [changeEmailText, setChangeEmailText] = useState('');
  const { accountDataContext } = useContext(AccountDataContext);
  const [getChangeEmailText, postChangeEmail] = useChangeEmail();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoutAndRedirect] = useChangePass();
  const active = useRef(true);

  useEffect(() => {
    //TODO done useEffect
    if (changeEmailTextLoading && loginTypeContext) {
      let active = true;
      (async () => {
        try {
          setChangeEmailTextLoading(false);
          const response = await getChangeEmailText(loginTypeContext!);
          active && setChangeEmailText(response);
        } catch (error) {
          active && logger.debug('Get Change Email Text Error: ', error);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [
    changeEmailText,
    changeEmailTextLoading,
    getChangeEmailText,
    loginTypeContext,
  ]);

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
      active.current = false;
    };
  }, [modalHide]);

  //TODO done useCallback
  const handlePostChangeEmail = useCallback(
    async (data: Model) => {
      try {
        setIsSubmitting(true);
        await postChangeEmail(data.confirmEmail);
        if (active.current) {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
          logger.debug('Handle Post Change Email - Successful');
        }
      } catch (error) {
        active.current && logger.error('Handle Post Change Email - Error');
      } finally {
        active.current && setIsSubmitting(false);
      }
    },
    [modalHide, postChangeEmail],
  );

  const handleChangePass = useCallback(async () => {
    setIsSubmitting(true);
    try {
      if (accountDataContext) {
        logoutAndRedirect();
      }
      logger.debug('Handle Change Password - Successful');
    } catch (error) {
      logger.error('Handle Change Password - Error');
    } finally {
      setIsSubmitting(false);
    }
  }, [accountDataContext, logoutAndRedirect]);

  const handlChangeEmail = () => {
    // if (!modal.current) {
    //   modal.current = modalShow({
    //     onCancel: () => {
    //       !!modal.current && modalHide(modal.current);
    //       modal.current = null;
    //     },
    //     renderContent: onCancel => (
    //       <EmailForm
    //         onCancel={onCancel}
    //         onSubmit={handlePostChangeEmail}
    //         changeEmailText={changeEmailText}
    //         isSubmitting={isSubmitting}
    //       />
    //     ),
    //     title: 'Change Email Address',
    //     type: ModalType.FORM,
    //   });
    // }
  };

  const handlChangePassword = () => {
    if (!modal.current) {
      modal.current = modalShow({
        onCancel: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderContent: onCancel => (
          <PasswordForm
            onCancel={onCancel}
            onSubmit={handleChangePass}
            isSubmitting={isSubmitting}
          />
        ),
        title: 'Change Password',
        type: ModalType.FORM,
      });
    }
  };

  return (
    <div className={styles.accountSettings}>
      {/* {!changeEmailTextLoading && accountDataContext && (
        <AccountSettingsItem
          label="Change email address"
          onClick={handlChangeEmail}
          text={accountDataContext?.email}
          title="Email"
        />
      )} */}
      <AccountSettingsItem
        label="Change password"
        onClick={handlChangePassword}
        text="••••••••"
        title="Password"
      />
    </div>
  );
};

export default AccountSettings;
