import { QuestionType } from 'components/QuestionDate/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  email: yup.string().email().required('This field is required'),
  password: yup.string().password().required('This field is required'),
  confirmPassword: yup
    .string()
    .password()
    .required('This field is required')
    .oneOf([yup.ref('password'), ''], 'Password Does not match'),
});

export type Model = {
  password: string;
  confirmPassword: string;
  token: string;
  email: string;
};

const password: QuestionType<Model> = {
  name: 'password',
};

const confirmPassword: QuestionType<Model> = {
  name: 'confirmPassword',
};

const token: QuestionType<Model> = {
  name: 'token',
};

const email: QuestionType<Model> = {
  name: 'email',
};

export const model = {
  password,
  confirmPassword,
  token,
  email,
};
