import { useCallback } from 'react';

import { API, api } from 'modules/api';

export const useLogUserLoginEvent = () => {
  const logUserLoginEvent = useCallback(async (adUserObjectId?: string) => {
    if (adUserObjectId === null || adUserObjectId === '') {
      throw new Error();
    }

    api(API.POST_LOG_USER_LOGIN_EVENT(adUserObjectId));
  }, []);
  return { logUserLoginEvent } as const;
};
