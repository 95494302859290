import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { API, api } from 'modules/api';
import { cpdSubmissionResponse } from '../types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { ModalType } from 'modules/modals/constants';
import { Model } from '../model';
import { TriggerGTMEvent } from 'modules/gtm';
import dayjs from 'dayjs';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { AccountDataContext } from 'modules/context';

export const useSubmitCpd = (
  modalRef: MutableRefObject<string | null>,
  selectedFiles?: any,
  selectedRecords?: any,
  editRecordId?: number,
) => {
  const { accountDataContext } = useContext(AccountDataContext);
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);
  const active = useRef(true);
  const modal = useRef<string | null>(null);

  const showCpdClaimModal = useCallback(
    (res: cpdSubmissionResponse) => {
      modal.current = modalShow({
        onClose: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        res: res,
        type: ModalType.CPD_CLAIM,
      });
    },
    [modalHide, modalShow],
  );

  const showErrorModal = useCallback(() => {
    errorModal.current = modalShow({
      onClose: () => {
        !!errorModal.current && modalHide(errorModal.current);
        errorModal.current = null;
      },
      title: 'Error',
      text: 'There was an error trying to send your data. Please try again later',
      type: ModalType.INFO,
    });
  }, [modalHide, modalShow]);

  const handleSuccess = useCallback(
    data => {
      if (modalRef) {
        modalRef.current && modalHide(modalRef.current);
        modalRef.current = null;
      }
      TriggerGTMEvent({
        action: EventsActions.SUBMIT_BUTTON_ON_CPD_FORM,
        category: EventsCategories.CPD,
        label: '',
      });
      showCpdClaimModal(data);
    },
    [modalHide, modalRef, showCpdClaimModal],
  );

  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: Model) => {
      if (!isApiSubmitting) {
        try {
          setIsApiSubmitting(true);
          const completion = dayjs(data.completionDate).toISOString();

          let filesResponse;
          if (accountDataContext?.userId !== undefined) {
            const files = new FormData();
            selectedFiles.forEach((element: File) => {
              files.append('file', element);
            });

            filesResponse = await api(
              API.POST_ADD_FILES(files, accountDataContext?.userId),
            );
          }

          if (editRecordId !== undefined && editRecordId !== 0) {
            const cpdEditClaim = {
              cpdClaimProductSuiteModel: selectedRecords?.[0],
              certificate: data.certificateId,
              points: Number(data.claimedPoints),
              completionDate: completion,
              type: Number(data.cpdType),
              description: data.description,
              comment: data.whatDidYouLearn,
              cpdDocumentDetails: filesResponse?.data?.blobNames,
              id: editRecordId,
            };
            const payloadData: any = {
              claim: cpdEditClaim,
              userId: accountDataContext?.userId,
            };

            const editCpdPoints = await api(
              API.POST_EDIT_BADGE_CPD_CLAIM(payloadData),
            ).then(res => {
              setRefreshData(true);
              return res;
            });

            active.current && handleSuccess(editCpdPoints.data);
          } else {
            const cpdClaim = {
              cpdClaimProductSuiteModel: selectedRecords?.[0],
              certificate: data.certificateId,
              points: Number(data.claimedPoints),
              completionDate: completion,
              type: Number(data.cpdType),
              description: data.description,
              comment: data.whatDidYouLearn,
              cpdDocumentDetails: filesResponse?.data?.blobNames,
            };
            const payloadData: any = {
              claim: cpdClaim,
              userId: accountDataContext?.userId,
            };

            const cpdPoints = await api(
              API.POST_BADGE_CPD_CLAIM(payloadData),
            ).then(res => {
              setRefreshData(true);
              return res;
            });
            active.current && handleSuccess(cpdPoints.data);
          }
        } catch (error) {
          active.current && showErrorModal();
        } finally {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-console
          active.current && setIsApiSubmitting(false);
        }
      }
    },
    [
      accountDataContext?.userId,
      handleSuccess,
      isApiSubmitting,
      selectedFiles,
      selectedRecords,
      editRecordId,
      showErrorModal,
    ],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleSubmit,
    isApiSubmitting,
    refreshData,
  };
};
