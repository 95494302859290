import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';

import {
  AccountDataContext,
  LoginTypeContext,
  SubscriptionEndDateContext,
} from './modules/context';
import { AccountDataType, LoginTypeEnum } from 'modules/utils/types';
import { Auth } from 'pages';
import { AutoLogoutTimer } from 'modules/autoLogoutTimer';
import { Footer } from 'components/newFooter';
import { Router } from 'modules/router';
import { setupPlatform } from 'modules/app/helpers';
import { useNavMenu } from 'modules/app/hooks';
import Header from 'components/Header';
import Modals from 'modules/modals';
import PlatformContext, {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
  PlatformType,
} from 'modules/platform/context';
import ScrollToTop from 'modules/router/ScrollToTop';
// import SignIn from 'components/SSO/SignIn';

const platform: PlatformType = {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isSafari,
  isIE,
  isFirefox,
  isTablet,
  isWindows,
  osVersion,
};

export const App: React.FunctionComponent = () => {
  const [accountDataContext, setAccountDataContext] = useState<
    AccountDataType | undefined
  >();
  const [loginTypeContext, setLoginTypeContext] = useState<
    LoginTypeEnum | undefined
  >();

  const [subscriptionEndDateContext, setSubscriptionEndDateContext] = useState<
    string | undefined
  >();

  const { navMenu, navLoaded } = useNavMenu(loginTypeContext);

  const onloadCallback = () => {
    setTimeout(function () {
      // if (
      //   typeof window !== 'undefined' &&
      //   typeof window.grecaptcha !== 'undefined' &&
      //   window.grecaptcha !== ''
      // ) {
      //   window?.grecaptcha?.render('reCaptcha', {
      //     sitekey: '6LdCqnwUAAAAABU_7-mjFiNqyCrnlgaIcVmBc4ai',
      //     callback: function (response: any) {
      //       $('#reCaptchaResponse').text(response);
      //     },
      //   });
      // }
      // if (!window.grecaptcha) {
      //   console.log('TESTING CAPTCHA');
      // } else {
      //   if (
      //     typeof window.grecaptcha === 'undefined' ||
      //     typeof window.grecaptcha?.render === 'undefined'
      //   ) {
      //     if (window.grecaptcha !== undefined && window.grecaptcha !== null) {
      //       window.grecaptcha?.reset(0);
      //     }
      //   } else {
      //     window?.grecaptcha?.render('reCaptcha', {
      //       sitekey: '6LdCqnwUAAAAABU_7-mjFiNqyCrnlgaIcVmBc4ai',
      //       callback: function (response: any) {
      //         $('#reCaptchaResponse').text(response);
      //       },
      //     });
      //   }
      // }
    }, 1000);
  };

  useEffect(() => {
    setupPlatform(platform);
    onloadCallback();
  }, []);

  return (
    <HelmetProvider>
      <Suspense fallback={<h1>Loading</h1>}>
        <BrowserRouter>
          <PlatformContext.Provider value={platform}>
            <AccountDataContext.Provider
              value={{ accountDataContext, setAccountDataContext }}
            >
              <LoginTypeContext.Provider
                value={{ loginTypeContext, setLoginTypeContext }}
              >
                <SubscriptionEndDateContext.Provider
                  value={{
                    subscriptionEndDateContext,
                    setSubscriptionEndDateContext,
                  }}
                >
                  <ScrollToTop />
                  <AutoLogoutTimer />
                  {/* <SignIn /> */}
                  <Auth />
                  <Header navMenu={navMenu} />
                  <Router />
                  <Footer navMenu={navMenu} navLoaded={navLoaded} />
                  <Modals />
                </SubscriptionEndDateContext.Provider>
              </LoginTypeContext.Provider>
            </AccountDataContext.Provider>
          </PlatformContext.Provider>
        </BrowserRouter>
      </Suspense>
    </HelmetProvider>
  );
};

export default App;
