import React, { useMemo, useState, useContext } from 'react';
import clsx from 'clsx';

import { ButtonStyle } from 'components/Button/constants';
import { ButtonColour, ButtonTarget } from 'components/Button/constants';
import { BadgeSession } from 'pages/badges/components/Badges/constants';
import { ClaimStateTypes } from 'pages/home/types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { ReactComponent as Tick } from 'modules/theme/icons/misc/dashboard-badges-tick.svg';
import { ReactComponent as Timer } from 'modules/theme/icons/misc/badges-timer-icon.svg';
import { TriggerGTMEvent } from 'modules/gtm';
import * as Button from 'components/Button';
import * as Hyperlink from 'components/Hyperlink';
import CtaButton from 'components/Cta/Button';
import PlatformContext from 'modules/platform/context';

import styles from './styles.module.scss';

type StepsTypes = {
  candidateSubmitState: string;
  claiming: boolean;
  claimStates: ClaimStateTypes;
  getBadges: () => void;
  hasBadgesToClaim: boolean;
  hasStatusOne: boolean;
  isSubmitting: boolean;
};

const Steps: React.FunctionComponent<StepsTypes> = ({
  claiming,
  claimStates,
  getBadges,
  hasBadgesToClaim,
  hasStatusOne,
  isSubmitting,
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const { isMobile } = useContext(PlatformContext);

  const isClaiming = useMemo(
    () => sessionStorage.getItem(BadgeSession.DASHBOARD_CLAIMING_TITLE),
    [],
  );

  const className = clsx(isMobile && showHelp && styles.showHelp);

  return (
    <ul>
      <li>
        <p>Enter Candidate Number</p>
        <Tick />
      </li>

      <li>
        <div>
          <p>Pass exam</p>
          {claimStates.examNotPassed ? (
            <div className={styles.badgeCta}>
              <CtaButton
                ariaLabel="Visit Axelos.com"
                custom_url={claimStates.examNotPassedUrl}
                defaultColour={ButtonColour.BRAND_BERRY}
                target={ButtonTarget.BLANK}
                small
                onClick={() =>
                  TriggerGTMEvent({
                    action: EventsActions.ACHIEVE_CERTIFICATION_LINK,
                    category: EventsCategories.CANDIDATE_NUMBERS,
                    label: '',
                  })
                }
              />
            </div>
          ) : (
            <Tick />
          )}
        </div>
      </li>

      <li className={className}>
        {isClaiming != null || claiming ? (
          <div className={styles.claimingBadges}>
            <p>
              Claiming badge...
              <Hyperlink.CustomLink
                href="" // Delete this after PeopleCert+ Transition
                // href="https://www.credly.com/users/sign_in"
                // target={ButtonTarget.BLANK}
                rel="noreferrer"
              >
                Visit Credly
              </Hyperlink.CustomLink>
            </p>
            <Timer
              className={styles.claimingIcon}
              onClick={() => setShowHelp(!showHelp)}
            />
            <div role="tooltip" aria-hidden="true">
              Your badge is on its way from Credly and will appear on the
              badging page shortly.
            </div>
          </div>
        ) : (
          <div>
            <p>Claim badge</p>
            {hasBadgesToClaim && !hasStatusOne ? (
              <div className={styles.claimBadgeCta}>
                <Button.Submit
                  ariaLabel="Submit"
                  className={styles.button}
                  colour={ButtonColour.BRAND_BERRY}
                  disabled={true} // Delete this after PeopleCert+ Transition
                  // disabled={isSubmitting}
                  isSubmitting={isSubmitting}
                  onClick={getBadges}
                  style={ButtonStyle.PRIMARY}
                  small
                />
              </div>
            ) : (
              <React.Fragment>
                {!claimStates.examNotPassed && (
                  <Timer onClick={() => setShowHelp(!showHelp)} />
                )}
                <div role="tooltip" aria-hidden="true">
                  We're preparing your badge for claiming.
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </li>
    </ul>
  );
};

export default React.memo(Steps);
