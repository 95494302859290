import React, { useContext } from 'react';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import Template from './Template';

export const CPD: React.FunctionComponent = () => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);

  return loginTypeContext && accountDataContext ? <Template /> : null;
};
