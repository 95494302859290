import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

import { filtersHookType, resourcesType } from '../../../types';
import { LoginTypeEnum } from 'modules/utils/types';
import { useSelectedFilters } from './hooks/useSelectedFilters';
import * as Button from 'components/Button';
import * as Filters from '../../Filters';

import styles from './styles.module.scss';
import { SortByTypes } from 'pages/resource-hub/utils';
import { ALL_FILTERS_ELEMENT } from 'modules/utils/constants';
import {
  CategoryList,
  filterCategoryType,
  filterResourceType,
} from 'pages/resource-hub/helper';

type FiltersContainerProps = {
  filters: filtersHookType;
  getData: (resetNumberOfitemsShown: boolean) => Promise<void>;
  getSortByValue: (value: SortByTypes) => void;
  loginTypeContext: LoginTypeEnum;
  resources: resourcesType;
  slug: string;
  searchInitiated: boolean;
  searchTerm: string;
};

export const FiltersContainer: React.FunctionComponent<FiltersContainerProps> =
  ({
    filters,
    getData,
    getSortByValue,
    loginTypeContext,
    resources,
    slug = '',
    searchInitiated,
    searchTerm,
  }) => {
    const isResourceHub = window.location.href.includes('/resource-hub')
      ? true
      : false;
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [isResourceTypeSelected, setIsResourceTypeSelected] = useState(false);
    const [isCertificateSelected, setIsCertificateSelected] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const wrapperRef = useRef(null);

    const defaultResourceType = {
      data: [
        {
          uid: '',
          colour: '',
          title: '',
          slug: '',
        },
      ],
    };
    const [newResourceTypes, setNewResourceTypes] =
      useState(defaultResourceType);
    const [newCategoryTypes, setNewCategoryTypes] =
      useState(defaultResourceType);

    const defaultResourcesForEvents = {
      data: [
        ALL_FILTERS_ELEMENT,
        {
          uid: 'bltce636d390f3194a5',
          colour: '',
          title: 'Podcasts',
          slug: 'podcasts',
        },
        {
          uid: 'bltbfe6436ac6a934ac',
          colour: '',
          title: 'Webinars',
          slug: 'webinars',
        },
      ],
    };

    const isMobile = useMediaQuery({ query: '(max-width: 520px)' });

    const closeFilters = () => {
      setIsCategorySelected(false);
      setIsResourceTypeSelected(false);
      setIsCertificateSelected(false);
    };
    const {
      addDeleteResourceType,
      addDeleteCertificate,
      addDeleteCategory,
      refreshResults,
      setRefreshResults,
    } = useSelectedFilters(filters, loginTypeContext, resources, slug);

    const classNames = clsx(
      styles.filtersContainer,
      isMobile && showFilters && styles.showFilters,
    );

    const getSortByType = (value: any) => {
      getSortByValue(value);
    };

    useEffect(() => {
      if (refreshResults) {
        getData(true);
        setRefreshResults(false);
      }
    }, [refreshResults, setRefreshResults, getData]);

    useEffect(() => {
      const resourceType = filterResourceType(filters.contentTypesSelected);
      setNewResourceTypes(resourceType);
    }, [filters.contentTypesSelected]);

    useEffect(() => {
      const categoryTypes = filterCategoryType(filters.topicsSelected);
      setNewCategoryTypes(categoryTypes);
    }, [filters.topicsSelected]);

    return (
      <div className={classNames} ref={wrapperRef}>
        <div>
          <Filters.Filter
            addDeleteItems={addDeleteCategory}
            closeFilters={closeFilters}
            isFilterSelected={isCategorySelected}
            itemsSelected={filters.contentTypesSelected}
            listItems={
              isResourceHub ? newCategoryTypes : resources.contentTypes
            }
            setIsFilterSelected={setIsCategorySelected}
            setItemsSelected={filters.setContentTypesSelected}
            title="Category"
          />
          <Filters.Filter
            addDeleteItems={addDeleteResourceType}
            closeFilters={closeFilters}
            isFilterSelected={isResourceTypeSelected}
            itemsSelected={filters.topicsSelected}
            listItems={
              isResourceHub ? newResourceTypes : defaultResourcesForEvents
            }
            setIsFilterSelected={setIsResourceTypeSelected}
            setItemsSelected={filters.setTopicsSelected}
            title="Resource Type"
          />
          <Filters.Filter
            addDeleteItems={addDeleteCertificate}
            closeFilters={closeFilters}
            isFilterSelected={isCertificateSelected}
            itemsSelected={filters.bestPracticesSelected}
            listItems={resources.bestPractices}
            setIsFilterSelected={setIsCertificateSelected}
            setItemsSelected={filters.setBestPracticesSelected}
            title="Certificate"
          />

          {(filters.topicsSelected[0].title !== 'All' ||
            filters.bestPracticesSelected[0].title !== 'All' ||
            filters.contentTypesSelected[0].title !== 'All' ||
            (searchTerm !== '' && searchInitiated)) && (
            <Filters.SortBy getSortByType={getSortByType} />
          )}

          {isMobile && (
            <Button.Generic
              className={styles.showFiltersButton}
              label="More filters"
              onClick={() => setShowFilters(true)}
            />
          )}
        </div>
        {(filters.topicsSelected[0].title !== 'All' ||
          filters.bestPracticesSelected[0].title !== 'All' ||
          filters.contentTypesSelected[0].title !== 'All') && (
          <div>
            <Filters.Selected
              itemsSelected={filters.contentTypesSelected}
              addDeleteItems={addDeleteCategory}
            />
            <Filters.Selected
              itemsSelected={filters.topicsSelected}
              addDeleteItems={addDeleteResourceType}
            />
            <Filters.Selected
              itemsSelected={filters.bestPracticesSelected}
              addDeleteItems={addDeleteCertificate}
            />
          </div>
        )}
      </div>
    );
  };

export default FiltersContainer;
