import React from 'react';

import { linkText, readTimeText } from 'modules/utils/utils';
import { ReactComponent as ErrorIcon } from 'modules/theme/ims/error_icon.svg';
import { ResourceSearchAzureType } from 'modules/api/endpoints/resource-search';
import ResourceHubItem from 'components/ResourceHubItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';
import { ALL_FILTERS, ALL_FILTERS_ELEMENT } from 'modules/utils/constants';

const itemsToShow = (
  resourceHub: ResourceSearchAzureType[],
  numberOfitemsShown: number,
) =>
  resourceHub.length > 0 ? (
    resourceHub
      ?.slice(0, numberOfitemsShown)
      .map((page: ResourceSearchAzureType, index) => {
        return (
          <li key={`resource-hub-${index}`}>
            <ResourceHubItem
              bookmarked={page.bookmarked}
              buttonLabel={linkText(page.contentType)}
              contentType={page.contentType}
              description={page.description}
              image={page.image}
              liked={page.liked}
              likes={page.likes}
              slug={page.url}
              publicationDate={page.publicationDate}
              readTimeText={readTimeText(page.contentType)}
              timeToRead={page.timeToRead}
              title={page.title}
              uid={page.RowKey}
            />
          </li>
        );
      })
  ) : (
    <li className={styles.noResults}>
      <ErrorIcon />
      <h2>
        No Results were found!
        <br />
        Try changing your filters.
      </h2>
    </li>
  );

export const pageCardList = (
  resourceHub: ResourceSearchAzureType[],
  numberOfitemsShown: number,
  loaded: boolean,
): any => (
  <React.Fragment>
    {loaded ? (
      <ul className={`${styles.resourceHubList}`}>
        {itemsToShow(resourceHub, numberOfitemsShown)}
      </ul>
    ) : (
      <Skeleton />
    )}
  </React.Fragment>
);

export const CategoryList = {
  data: [
    {
      ...ALL_FILTERS_ELEMENT,
      resourceTypes: [
        'Case Studies',
        'Communication Skills',
        'Enterprise Related Topics',
        'Expert Interview',
        'General Interests',
        'Glossary',
        'Guidance Papers',
        'ITIL4 Practice Guides',
        'Industry Benchmarks',
        'Job Profile',
        'Leadership Skills',
        'Market Trends',
        'Market/Industry Trends',
        'Podcasts',
        'PRINCE2 7 Practice Guides',
        'Professional Development',
        'Quick Reference Guides',
        'Quick Tips & Tricks',
        'Sample Papers',
        'Syllabus',
        'Templates & Toolkits',
        'Theory & Practice',
        'Video Tutorials',
        'Visual Charts/Diagrams',
        'Webinars',
      ],
    },
    {
      title: 'Learner Resources',
      resourceTypes: [
        'Sample Papers',
        'Syllabus',
        'Quick Tips & Tricks',
        'Video Tutorials',
        'Glossary',
        'Quick Reference Guides',
        'Visual Charts/Diagrams',
      ],
    },
    {
      title: 'Practitioner Resources',
      resourceTypes: [
        'Templates & Toolkits',
        'ITIL4 Practice Guides',
        'Job Profile',
        'Theory & Practice',
        'PRINCE2 7 Practice Guides',
      ],
    },
    {
      title: 'Articles',
      resourceTypes: [
        'Enterprise Related Topics',
        'Market Trends',
        'Expert Interview',
        'Communication Skills',
        'Leadership Skills',
        'Professional Development',
        'General Interests',
      ],
    },
    {
      title: 'Blog Post',
      resourceTypes: [
        'Enterprise Related Topics',
        'Market Trends',
        'Expert Interview',
        'Communication Skills',
        'Leadership Skills',
        'Professional Development',
        'General Interests',
      ],
    },
    {
      title: 'Research & Reports',
      resourceTypes: [
        'Industry Benchmarks',
        'Market/Industry Trends',
        'Guidance Papers',
        'Case Studies',
      ],
    },
    {
      title: 'Events',
      resourceTypes: ['Webinars', 'Podcasts'],
    },
  ],
};

export function filterResourceType(selectedCategories: any) {
  let resourceTypes: string[] = [ALL_FILTERS];
  selectedCategories.forEach((category: any) => {
    CategoryList?.data.map(item => {
      if (category.title === item.title) {
        const isExist = resourceTypes.includes(item.resourceTypes[0]);
        if (!isExist) resourceTypes = [...resourceTypes, ...item.resourceTypes];
      }
    });
  });

  const result = resourceTypes?.map(item => {
    return {
      uid: '',
      colour: '',
      title: item,
      slug: item
        .toLowerCase()
        .replace(/\//g, '-')
        .replace(/&/g, 'and')
        .replace(/ /g, '-'),
    };
  });

  return { data: result };
}

export function filterCategoryType(selectedResourceTypes: any) {
  const categoryType: string[] = [];
  if (selectedResourceTypes[0].title !== 'All') {
    selectedResourceTypes.forEach((resourceType: any) => {
      CategoryList?.data.map(item => {
        item.resourceTypes.map((type: any) => {
          if (resourceType.title === type) {
            const isExist = categoryType.includes(item.title);

            if (!isExist) categoryType.push(item.title);
          }
        });
      });
    });
  } else {
    CategoryList?.data.map(item => {
      categoryType.push(item.title);
    });
  }

  const result = categoryType?.map(item => {
    return {
      uid: '',
      colour: '',
      title: item,
      slug: item
        .toLowerCase()
        .replace(/\//g, '-')
        .replace(/&/g, 'and')
        .replace(/ /g, '-'),
    };
  });

  return { data: result };
}
