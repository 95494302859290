import yup, { ValdationMessages } from 'modules/validation';

const regex = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/);

export const validateClaimedPoints = (value: string): boolean => {
  const valueInt = Number(value);
  if (valueInt === 0) {
    return false;
  } else if (valueInt < 0 || valueInt > 20) {
    return false;
  }
  return regex.test(value) ? true : false;
};

function validator(this: yup.StringSchema) {
  return this.test(
    'claimedPoints',
    ValdationMessages.CPD_CLAIMED_POINT,
    function (value) {
      return value ? validateClaimedPoints(value) : true;
    },
  );
}

export default validator;
