import { useCallback } from 'react';

import { API, api } from 'modules/api';

export const useGrantAdditionalSubscription = () => {
  const grantAdditionalSubscription = useCallback(
    async (appUserId?: number, userEmail?: string) => {
      if (appUserId === null || userEmail === '') {
        throw new Error();
      }

      api(API.POST_GRANT_ADDITIONAL_SUBSCRIPTION(appUserId, userEmail));
    },
    [],
  );
  return { grantAdditionalSubscription } as const;
};
