import React from 'react';
import clsx from 'clsx';

import { ArrowSpinnerColour } from 'components/Spinner/constants';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { FormControlProps } from './Default';
import * as Button from 'components/Button';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

const FormControl: React.FC<FormControlProps> = ({ isSubmitting }) => {
  const classNames = clsx(
    styles.formControl,
    isSubmitting && styles.submitting,
    styles[`step1`],
  );

  return (
    <div className={classNames}>
      <Button.Submit
        colour={ButtonColour.BRAND_BLACK_GRAPE}
        disabled={true} // remove after PeopleCert+ transition
        // disabled={isSubmitting}
        label="Confirm and pay"
        showSpinner={false}
        style={ButtonStyle.SECONDARY}
      >
        {isSubmitting && (
          <Spinner
            background={false}
            className={styles.spinner}
            colour={ArrowSpinnerColour.WHITE}
          />
        )}
      </Button.Submit>
    </div>
  );
};

export default React.memo(FormControl);
