import { useCallback, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { useLoginToken } from 'modules/hooks';
import { useHistory } from 'react-router';
import logger from 'modules/logger';
import { useJSPeoplecertApplication } from 'modules/utils/PeoplecertExternalApplication';

export const useChangePass = () => {
  const application = useJSPeoplecertApplication();
  const { logOut } = useLoginToken();
  const history = useHistory();
  const [changePassUrl, setChangePassUrl] = useState('');

  useEffect(() => {
    //TODO done useEffect
    let active = true;

    (async () => {
      try {
        active && setChangePassUrl('true');
      } catch (error) {
        active && logger.debug('Get Change Pass URL Error: ', error);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  const logoutAndRedirect = useCallback(async () => {
    const active = true;
    try {
      logOut();
      await api(API.GET_AXELOS_SSO(''));
      // await application.manageDomainCookies('remove', [], '', true);
      history.push(`/reset-password`);
      // await api(API.GET_SSO('')).then(res => {
      //   history.push(`/reset-password`);
      //   // window.location.href = `${changePassUrl}&userName=${userName}`;
      // });
    } catch (error: any) {
      active && logger.error('Error while logging out', error);
    }
  }, [logOut]);

  return [logoutAndRedirect] as const;
};
