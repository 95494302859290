import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { AccountDataContext } from 'modules/context';
import { Body } from './components/Body';
import { filterType, ResourceHubLoadedType } from '../../types';
import { getTodayCookieName } from 'modules/utils/newContentUtils';
import { useContentTypePage } from '../../hooks';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';
import { PageTypeEngagementTime } from 'modules/engagementSummary';
import useIsMounted from 'pages/resource-hub/hooks/useIsMounted';

type ContentTypePageProps = {
  contentTypes: filterType;
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<ResourceHubLoadedType>>;
};

const logEvent = (
  startTime: any,
  userEmail: string,
  pageURL: string,
  contentType: string,
) => {
  const endTime = new Date();
  const diffMs = endTime.getTime() - startTime.getTime();
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

  //insert DB
  PageTypeEngagementTime({
    id: 0,
    userEmail: userEmail,
    pageURL: pageURL,
    contentType: contentType,
    timeSpentInMinutes: diffMins,
    esPageTypeID: 0,
  });
};

export const ContentTypePage: React.FC<ContentTypePageProps> = ({
  loaded,
  setLoaded,
}) => {
  const { page, userEmail, pageURL, contentType } =
    useContentTypePage(setLoaded);
  const history = useHistory();
  const { accountDataContext } = useContext(AccountDataContext);
  const [startTime] = useState(new Date());
  const mounted = useIsMounted();

  // Calculate avg time for summary dashboard
  useEffect(() => {
    return () => {
      if (!mounted()) {
        logEvent(startTime, userEmail, pageURL, contentType);
      }
    };
  }, [mounted]);

  useEffect(() => {
    history.listen((_, action) => {
      if (action === 'POP') {
        setLoaded(prevState => ({
          ...prevState,
          resourceHub: false,
        }));
      }
    });
  }, [history, setLoaded]);

  useEffect(() => {
    if (page?.uid && accountDataContext?.email) {
      const todayCookieName = getTodayCookieName(accountDataContext?.userId);

      const pagesViewedStorage = localStorage.getItem(todayCookieName);
      let pagesViewed = [] as Array<string>;

      if (pagesViewedStorage) {
        pagesViewed = JSON.parse(pagesViewedStorage) as Array<string>;
      }
      if (!pagesViewed.includes(page?.uid)) {
        pagesViewed.push(page?.uid);
      }
      localStorage.setItem(todayCookieName, JSON.stringify(pagesViewed));
    }
  }, [accountDataContext, page]);
  return loaded ? (
    <main className={styles['content-type']}>
      {page && (
        <React.Fragment>
          <Helmet>
            <title>{page?.meta?.window_title}</title>
            <meta name="description" content={page?.meta?.meta_description} />
          </Helmet>
          <Body page={page} />
        </React.Fragment>
      )}
    </main>
  ) : (
    <Skeleton />
  );
};
