import { OptionType } from 'components/Inputs/Select';

export type CpdTypes = {
  id: number;
  name: string;
  isRequiredForExtension: boolean;
  isCurrent: boolean;
};

export type programmeTypes = {
  certificates: OptionType[];
  productSuiteId: number;
  productSuiteName: string;
  isNested: boolean;
  nestedData: OptionType[];
};

export type cpdOptionsTypes = {
  label: string;
  value: number;
  isNested: boolean;
  nestedData: OptionType[];
};

export type programmeOptionsTypes = {
  nestedData: OptionType[];
  value: number;
  label: string;
  isNested: boolean;
};

export type cpdClaimPoints = {
  certificate: string;
  points: number;
  completionDate: string;
  type: number;
  description: string;
  comment: string;
};

export type cpdSubmissionResponse = {
  candidateId: string;
  cpdCompleteThisYear: number;
  cpdClaimWindowEnd: string;
  cpdPointsRemainingThisYear: number;
  cpdPointsRequiredRemainingThisYear: number;
  cpdPointsThisYear: number;
  firstName: string;
  lastName: string;
  qualificationCategory: string;
  qualificationLevel: string;
};

export enum cpdFormHelpText {
  CERTIFICATION_CLAIMING_FOR = 'Choose which certification(s) you would like to submit this CPD for. You can choose one or more certifications, and your submission will be applied to each.',
  TYPE_OF_CPD = 'Which CPD category does your submission fall into? You can find detailed descriptions of each category <a href="https://my.axelos.com/resource-hub/article/continuous-professional-development#7-what-are-the-categories-of-cpd?">here</a>. You need to submit at least 5 points of Professional Experience, and a further 15 points of any other category each year.',
  DESCRIPTION = 'This is where you describe the activity you performed.',
  POINT_CLAIMING = 'This is how many points you are claiming for this activity. You can find further guidance on how to judge how many points to claim <a href="https://my.axelos.com/resource-hub/article/continuous-professional-development#6-how-many-cpd-points-is-an-activity-worth?">here</a>. You need to submit at least 5 points of Professional Experience, and a further 15 points of any other category each year.',
  COMPLETION_DATE = 'This is the date you completed this activity. You can submit CPD retroactively, but only within a given certification year. For example, if your current renewal date is on the 1st April, you can only submit CPD retroactively up to the 1st April of any given year. After the 31st March on any given year, you would no longer be able to submit CPD for the previous year.',
  WHAT_DID_YOU_LEARN = 'Here you should describe how this CPD activity contributed towards your professional skills and knowledge. Think about identifying the skills that you have refreshed and improved, how this activity impacted your performance, and what knowledge you have gained as a result.',
}
