import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { AchievementsTypes, CandidateIdTypes } from '../types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useCandidateDetails = (
  setLoaded: React.Dispatch<React.SetStateAction<any>>,
  detailsForBadges?: boolean,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [candidateIds, setCandidateIds] = useState<
    CandidateIdTypes[] | undefined
  >();

  const [achievements, setAchievements] = useState<
    AchievementsTypes[] | undefined
  >();

  useEffect(() => {
    if (loginTypeContext && loginTypeContext !== LoginTypeEnum.CONSULTANT) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          api(API.GET_CANDIDATE_DETAILS()).then(response => {
            active && setCandidateIds(response.data?.candidateIds);

            active && setAchievements(response.data?.certificatePaths);
          });
        } catch (error) {
          active && logger.error('Get Candidate Details Error', error);
        } finally {
          if (detailsForBadges === true) {
            active &&
              setLoaded((prevState: any) => ({
                ...prevState,
                paths: true,
                candidateId: true,
              }));
          } else {
            active &&
              setLoaded((prevState: any) => ({
                ...prevState,
                achievements: true,
                candidateId: true,
              }));
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loginTypeContext, setLoaded]);

  return { candidateIds, achievements, setCandidateIds, setAchievements };
};
