import React from 'react';
import clsx from 'clsx';

import { BadgesTypes } from 'pages/badges/types';
import { CandidateIdTypes } from './CandidateForm/types';
import { ClaimStateTypes } from 'pages/home/types';
import { BadgeStatus } from 'pages/badges/components/Badges/constants';
import { useAccordion } from 'modules/hooks';
import { useClaimBadge } from './hooks/useClaimBadge';
import { useSubmitCandidate } from './CandidateForm/hooks/useSubmitCandidate';
import CandidateIdForm from './CandidateForm';
import Header from './Header';
import Skeleton from './Skeleton';
import Steps from './Steps';

import styles from './styles.module.scss';

type BadgesProps = {
  allCandidateIds?: CandidateIdTypes[];
  badges?: BadgesTypes[];
  claimStates: ClaimStateTypes;
  loaded: boolean;
  updateBadges: () => Promise<void>;
};

const Badges: React.FunctionComponent<BadgesProps> = ({
  allCandidateIds,
  badges,
  claimStates,
  loaded,
  updateBadges,
}) => {
  if (badges === undefined && badges === null) {
    badges = [];
  }

  const { getBadges, isClaimSubmitting, claiming } = useClaimBadge(
    updateBadges,
    claimStates.setExamNotPassed,
    claimStates.setExamNotPassedUrl,
  );

  const {
    candidateId,
    candidateErrorState,
    candidateSubmitState,
    handleSubmit,
    isApiSubmitting,
  } = useSubmitCandidate(updateBadges, getBadges);

  const { animating, handleClick, open, ref } = useAccordion();

  let hasBadgesToClaim = 0;
  let canUseBadgesWidget = false;
  let noBadgesToClaim = false;

  if (badges !== undefined && badges !== null && badges && badges.length > 0) {
    hasBadgesToClaim =
      badges.filter(claim => claim.status === BadgeStatus.BADGE_CLAIMABLE)
        .length > 0
        ? badges.filter(claim => claim.status === BadgeStatus.BADGE_CLAIMABLE)
            .length
        : 0;

    canUseBadgesWidget =
      (badges &&
        badges.filter(claim => claim.status === BadgeStatus.BADGE_CLAIMABLE)
          .length > 0) ||
      (badges &&
        badges.filter(claim => claim.status === BadgeStatus.BADGE_PENDING)
          .length > 0) ||
      allCandidateIds?.length === 0 ||
      claimStates.examNotPassed;

    noBadgesToClaim =
      badges &&
      badges.filter(claim => claim.status !== BadgeStatus.BADGE_PENDING)
        .length > 0 &&
      badges.filter(claim => claim.status !== BadgeStatus.BADGE_CLAIMABLE)
        .length > 0;
  }

  const canUseBadgesForm = allCandidateIds?.length === 0 && !candidateId;

  const hasCandidateId = candidateId
    ? !!candidateId
    : allCandidateIds && allCandidateIds?.length > 0;

  const stateFormActive = !hasBadgesToClaim && !claimStates.examNotPassed;

  const classNames = clsx(
    styles.badges,
    !noBadgesToClaim && !claimStates.examNotPassed && styles.success,
    claimStates.examNotPassed && styles.noExams,
    stateFormActive && !candidateId && canUseBadgesForm && styles.formActive,
    animating && styles.animating,
    !open && styles.closed,
  );

  const badgesForSteps =
    badges !== undefined && badges.length > 0 ? badges : [];

  return loaded ? (
    <React.Fragment>
      {canUseBadgesWidget !== undefined && !noBadgesToClaim && (
        <section className={classNames}>
          <Header
            handleClick={handleClick}
            hasCandidateId={hasCandidateId}
            open={open}
          />
          {canUseBadgesForm && (
            <CandidateIdForm
              allCandidateIds={allCandidateIds}
              candidateId={candidateId}
              candidateErrorState={candidateErrorState}
              canUseBadgesForm={canUseBadgesForm}
              handleSubmit={handleSubmit}
              isApiSubmitting={isApiSubmitting}
            />
          )}
          {hasCandidateId && (
            <div aria-hidden={!open} id="digital-badges" ref={ref}>
              <Steps
                candidateSubmitState={candidateSubmitState}
                claimStates={claimStates}
                hasBadgesToClaim={!!hasBadgesToClaim}
                hasStatusOne={
                  badgesForSteps?.find(
                    i => i.status === BadgeStatus.BADGE_PENDING,
                  )
                    ? true
                    : false
                }
                getBadges={getBadges}
                isSubmitting={isClaimSubmitting}
                claiming={claiming}
              />
            </div>
          )}
        </section>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default React.memo(Badges);
