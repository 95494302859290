import { useEffect, useState } from 'react';

import { AccountLoadedType } from 'pages/account-management/types';
import { API, api } from 'modules/api';
import { PaymentInfoType } from '../types';
import { updatePaymentMethods } from './utils';
import logger from 'modules/logger';

function useGetPaymentMethods(
  setSkeletonLoaded: React.Dispatch<React.SetStateAction<AccountLoadedType>>,
) {
  const [paymentMethods, setPaymentMethods] = useState<PaymentInfoType[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  //TODO done useEffect
  useEffect(() => {
    if (loading) {
      let active = true;
      (async function () {
        try {
          api(API.GET_PAYMENT_METHODS())
            .then(result => {
              active && updatePaymentMethods(result.data, setPaymentMethods);
            })
            .catch(error => {
              logger.error('GET_PAYMENT_METHODS', error);
            });
        } catch (error) {
          active && logger.error('Error getting payment methods', error);
        } finally {
          if (active) {
            setLoading(false);
            setSkeletonLoaded(prevState => ({
              ...prevState,
              payments: true,
            }));
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loading, setSkeletonLoaded]);

  return { paymentMethods, setLoading, setPaymentMethods };
}

export default useGetPaymentMethods;
