import { ApiConfig } from './types';
import { getConfig } from 'modules/utils/utils';
import { LoginTypeEnum } from 'modules/utils/types';
import { CLIENT_ID_ITEM_NAME, NO_FILTER_TEXT } from 'modules/utils/constants';

export enum ApiMethod {
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export const API = {
  GET_ACCOUNT_DATA: (token: string): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/get-user-details`,
    ...getConfig(token),
    withCredentials: true,
  }),

  GET_USER_ROLES: (id: any): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/get-user-roles`,
    headers: {
      clientid: id,
    },
    withCredentials: true,
  }),

  GET_CONGRATS_PAGE: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-congrats-page`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CONTENT_BY_ID: (slug: string): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/resource-hub/get-content-by-id/${encodeURI(slug)}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_FOOTER: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-footer/`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CONTENT_FROM_CONTENT_STACK: (slug: string): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/${encodeURI(slug)}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CONTENT_TYPE_PAGE: ({
    loginTypeContext,
    contentTypeSlug,
    pageSlug,
  }: {
    loginTypeContext: LoginTypeEnum;
    contentTypeSlug: string;
    pageSlug: string;
  }): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/resource-hub/get-page-by-slug/${loginTypeContext}/${encodeURI(
      contentTypeSlug,
    )}/${encodeURI(pageSlug)}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CURATED_PAGE: ({
    loginTypeContext,
    pageSlug,
  }: {
    loginTypeContext: LoginTypeEnum;
    pageSlug: string;
  }): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/resource-hub/get-curated-page/${loginTypeContext}/${encodeURI(pageSlug)}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_DASHBOARD_EVENTS: ({
    featuredEvent,
    loginTypeContext,
  }: {
    featuredEvent: boolean;
    loginTypeContext: LoginTypeEnum;
  }): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-dashboard-content-events/${loginTypeContext}/${featuredEvent}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_DASHBOARD_FEATURED_RESOURCES: (
    loginTypeContext: LoginTypeEnum,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-dashboard-content-fr/${loginTypeContext}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_DASHBOARD_CONTENT_TYPES: ({
    contentTypeUid,
    keyTemplates,
    loginTypeContext,
    numberOfItems,
  }: {
    contentTypeUid: string;
    keyTemplates: boolean;
    loginTypeContext: LoginTypeEnum;
    numberOfItems: number;
  }): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-dashboard-content-types/${loginTypeContext}/${contentTypeUid}/${numberOfItems}/${keyTemplates}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_DASHBOARD_PAGE: (loginTypeContext: LoginTypeEnum): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-dashboard-page/${loginTypeContext}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_DOWNLOAD_INVOICE_PDF: (getDownloadInvoice: string): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}${getDownloadInvoice}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_PUBLIC_CONTENT_BY_ID: ({
    page,
    slug,
  }: {
    page: string;
    slug: string;
  }): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/resource-hub/get-public-content-by-id/${encodeURI(page)}/${encodeURI(
      slug,
    )}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_USER_SUBSCRIPTION_PLAN: (params: Record<string, any>): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/usersubscription/plan`,
    params,
    ...getConfig(),
    withCredentials: true,
  }),

  PATCH_ACCOUNT_DATA: (request: Record<string, any>): ApiConfig => ({
    method: ApiMethod.PATCH,
    url: `${process.env.REACT_APP_BACKEND_URL}/user`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_FORM_SUBMISSIONS: (request?: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_CONTACT_FORM_URL}api/form-submission`,
    data: request,
    withCredentials: false,
  }),

  POST_IS_USER_SUBSCRIBED: (request?: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/user-exists`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_IS_CANDIDATE_ID_CLAIMABLE: (
    id: string,
    givenName: string,
    surname: string,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/badging/candidate-id/claimable-with-name?candidateId=${encodeURIComponent(
      id,
    )}&GivenName=${encodeURIComponent(givenName)}&Surname=${encodeURIComponent(
      surname,
    )}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CLAIM_BADGES: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/badges/claim`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_LIKE_CONTENT_TYPE: (request: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/post-content-type-like`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_BOOKMARK_CONTENT_TYPE: (request: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/post-content-type-bookmark`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_ALL_BOOKMARKS: ({
    loginType,
    skip,
    limit,
  }: {
    loginType: LoginTypeEnum;
    skip: number;
    limit: number;
  }): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-all-bookmarks/${loginType}/${skip}/${limit}`,
    ...getConfig(),
    withCredentials: true,
  }),

  PUT_USER_SUBSCRIPTION_USER: (request?: Record<string, any>): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${process.env.REACT_APP_BACKEND_URL}/usersubscription/user`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),

  PUT_USER_SUBSCRIPTION_SUBSCRIBE_USER: (
    request?: Record<string, any>,
  ): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${process.env.REACT_APP_BACKEND_URL}/usersubscription/subscribe-user`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_ERROR_MESSAGE: (errorCode: string): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/resource-hub/get-error-message/${encodeURI(errorCode)}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CHANGE_EMAIL_TEXT: (loginType: LoginTypeEnum): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-change-email-text/${loginType}`,
    ...getConfig(),
    withCredentials: true,
  }),

  // GET_CHANGE_PASS_URL: (): ApiConfig => ({
  //   method: ApiMethod.GET,
  //   url: `${process.env.REACT_APP_BACKEND_URL}/auth/password-reset-url`,
  //   withCredentials: true,
  // }),

  POST_CHANGE_PROFILE_PICTURE: (body: FormData): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/photo`,
    data: body,
    ...getConfig(),
    withCredentials: true,
  }),

  DELETE_PROFILE_PICTURE: (): ApiConfig => ({
    method: ApiMethod.DELETE,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/photo`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_PROFILE_PICTURE_ACCEPTED_FILES: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/profile-photo-validation-values`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_GET_TOKENS: (code: string, endpoint: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
    data: `"${code}"`,
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: true,
  }),

  GET_NEWSLETTER_PREFERENCE: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/newsletter-preference`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_NEWSLETTER_PREFERENCE: (request: boolean): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/newsletter-preference`,
    data: JSON.stringify(request),
    ...getConfig(),
    withCredentials: true,
  }),

  GET_BADGES_CERTIFICATE_PATHS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/certificate-paths`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CANDIDATE_ID: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/candidate-ids`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_CANDIDATE_DETAILS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/candidate-details`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_CANDIDATE_POST: (candidateID: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/claim-candidate-id`,
    data: { candidateId: candidateID },
    ...getConfig(),
    withCredentials: true,
  }),

  GET_BADGES: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/badges`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_BADGES_STATUS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/badges/status`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_BADGE_CPD_TYPES: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/cpd-claim-types`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_BADGE_CPD_CLAIM: (form: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/cpd-claim`,
    data: form,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_VERIFY_EMAIL_ADDRESS: (data: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/verify-email-send-passcode`,
    data: `"${data.email ? data.email : data}"`,
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: false,
  }),

  POST_CHANGE_PASSWORD: (passwordModel: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/change-password`,
    data: passwordModel,
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: false,
  }),

  POST_UPDATE_USER_CONSENT_STATUS: (email: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/update-user-consent-status`,
    data: email,
    headers: {
      clientid: getConfig().headers.clientid,
      'content-type': 'application/json',
    },
    withCredentials: false,
  }),

  GET_IS_USER_ONLY_AXELOS: (email: Record<string, any>): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/is-user-only-myaxelos/${email}`,
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: false,
  }),

  POST_VERIFY_VERIFICATION_CODE: (otp: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/validate-passcode`,
    data: `"${otp.otp}"`,
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: false,
  }),

  POST_EDIT_BADGE_CPD_CLAIM: (form: Record<string, any>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/cpd/edit-cpd-claim`,
    data: form,
    headers: {
      Authorization: getConfig().headers.Authorization,
      clientid: getConfig().headers.clientid,
      'content-type': 'application/json',
    },
    withCredentials: true,
  }),

  GET_BADGE_LINKS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-badges`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_BADGES_CLAIM: (badgeGuid: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/badges/${encodeURI(
      badgeGuid,
    )}/claim`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_PAYMENT_HISTORY: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/paymentdetails`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_SUBSCRIPTIONS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/planoptions`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_RESOURCE_HUB_AUTOCOMPLETE: (params?: Record<string, any>): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-autocomplete`,
    params,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_PAYMENT_METHODS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/paymentmethods`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_PAYMENT_TOKEN: (user: boolean): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}${
      user ? '/user/paymenttoken' : '/usersubscription/paymenttoken'
    }`,
    ...getConfig(),
    withCredentials: true,
  }),

  PUT_PAYMENT_METHOD: (
    makeDefault: boolean,
    paymentNonce: string,
  ): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/paymentmethod`,
    data: {
      makeDefault: makeDefault,
      paymentNonce: paymentNonce,
    },
    ...getConfig(),
    withCredentials: true,
  }),

  EDIT_PAYMENT_METHOD: (request: Record<string, any>): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/editpaymentmethod`,
    data: request,
    ...getConfig(),
    withCredentials: true,
  }),
  POST_ADD_FILES: (files: FormData, userId: number): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/badging/upload-cpd-documents/${userId}`,
    data: files,
    headers: {
      Authorization: getConfig().headers.Authorization,
      'content-type': 'application/json',
    },

    withCredentials: true,
  }),

  DELETE_PAYMENT_METHOD: (id: string): ApiConfig => ({
    method: ApiMethod.DELETE,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/user/paymentmethod?paymentMethodId=${encodeURI(id)}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_RESOURCE_SEARCH: (
    loginType: LoginTypeEnum,
    searchText: string,
    contentTypes: any[],
    bestPractices: any[],
    topics: any[],
    skip: number,
    limit: number,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/resource-hub/get-resource-search/${loginType}/${
      searchText || NO_FILTER_TEXT
    }/${contentTypes
      .map(contentType => {
        return contentType.slug || NO_FILTER_TEXT;
      })
      .toString()}/${bestPractices
      .map(bestPractice => {
        return bestPractice.slug || NO_FILTER_TEXT;
      })
      .toString()}/${topics
      .map(topic => {
        return topic.slug || NO_FILTER_TEXT;
      })
      .toString()}/${skip}/${limit}`,
    ...getConfig(),
    withCredentials: true,
  }),

  POST_SUBSCRIPTION_STATE: (candidateToken: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/signup`,
    data: JSON.stringify(candidateToken),
    // headers: {
    //   'content-type': 'application/json',
    // },
    // ...signUpConfig(),
    // ...getConfig(),
    withCredentials: true,
  }),

  PUT_DISMISS_NOTIFICATION: (id: number): ApiConfig => ({
    method: ApiMethod.PUT,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/notifications/dismiss/${id}`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_MM_URL: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/auth/sso/maturitymodel`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_USER_NOTIFICATIONS: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/notifications`,
    ...getConfig(),
    withCredentials: true,
  }),

  GET_AXELOS_SSO: (clientid?: any): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/sso?clientid=${clientid}`,
    withCredentials: false,
  }),
  POST_REQUEST_CHANGE_EMAIL: (newEmailAddress: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${
      process.env.REACT_APP_BACKEND_URL
    }/user/request-change-email?${encodeURI(newEmailAddress)}`,
    ...getConfig(),
    withCredentials: true,
  }),
  POST_VERIFY_CHANGE_EMAIL: (verificationCode: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/verify-change-email?${verificationCode}`,
    ...getConfig(),
    withCredentials: true,
  }),
  GET_LOGOUT: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/auth/logout-url`,
    ...getConfig(),
    withCredentials: true,
  }),
  GET_SIGNUP_REMINDER_MESSAGE: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/resource-hub/get-signup-reminder-message`,
    ...getConfig(),
    withCredentials: false,
  }),
  GET_NOTIFY_FOR_FREE_MONTH_MESSAGE: (
    userId: string,
    userEmail: string,
  ): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/usersubscription/free-subscription-message?${userId}&${userEmail}`,
    ...getConfig(),
    withCredentials: false,
  }),
  POST_CANCEL_SUBSCRIPTION: (userId: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/usersubscription/cancel-subscriptions?${userId}`,
    ...getConfig(),
    withCredentials: true,
  }),
  POST_DASHBOARD_SUMMARY_PAGE_TYPE: (request?: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_DASHBOARD_SUMMARY_URL}/InsertEngagementSummaryPageType`,
    data: request,
    withCredentials: false,
  }),
  POST_PAGE_TYPE_ENGAGEMENT_TIME: (request?: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_DASHBOARD_SUMMARY_URL}/InsertUserPageTypeEngagementTime`,
    data: request,
    withCredentials: false,
  }),
  POST_DASHBOARD_SUMMARY_CONTENT_TYPE: (request?: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_DASHBOARD_SUMMARY_URL}/InsertEngagementSummaryContentType`,
    data: request,
    withCredentials: false,
  }),
  POST_CONTENT_TYPE_ENGAGEMENT_TIME: (request?: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_DASHBOARD_SUMMARY_URL}/InsertUserContentTypeEngagementTime`,
    data: request,
    withCredentials: false,
  }),
  POST_GRANT_ADDITIONAL_SUBSCRIPTION: (
    appUserId?: number,
    userEmail?: string,
  ): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/usersubscription/grant-additional-subscription?appUserId=${appUserId}&userEmail=${userEmail}`,
    ...getConfig(),
    withCredentials: true,
  }),
  POST_LOG_USER_LOGIN_EVENT: (adUserObjectId?: string): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_BACKEND_URL}/user/log-user-login-event?adUserObjectId=${adUserObjectId}`,
    ...getConfig(),
    withCredentials: true,
  }),
  POST_LOGIN_FORM: (cb: any, captchaValue: any, request?: any): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${process.env.REACT_APP_PEOPLECERT_SSO_URL}/identity?captcha=${captchaValue}&cb=${cb}`,
    data: request,
    headers: {
      'content-type': 'text/plain;charset=UTF-8',
    },
    withCredentials: false,
  }),
  GET_SET_PC_SSO_COOKIES: (request?: any): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_PEOPLECERT_SSO_URL}/requester?clientid=${request?.clientid}&application=accessportalsite&cb=${request?.cb}`,
    // headers: {
    //   'content-type': 'text/plain;charset=UTF-8',
    // },
    withCredentials: false,
  }),
  // GET_SSO: (clientid?: any): ApiConfig => ({
  //   method: ApiMethod.GET,
  //   url: `https://stage-accessportal.peoplecert.org/sso?clientid=${clientid}`,
  //   // headers: {
  //   //   'content-type': 'text/plain;charset=UTF-8',
  //   // },
  //   withCredentials: false,
  // }),
  //TODO - update API URL
  DELETE_CPD_HISTORY: (cpdClaimId: number): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/cpd/delete-cpd-points/${cpdClaimId}`,
    ...getConfig(),
    withCredentials: true,
  }),
  GET_CPD_PRODUCT_SUITE_INFO: (userId: number): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/cpd/get-cpd-product-suite-information/${userId}`,
    ...getConfig(),
    withCredentials: false,
  }),
  GET_PROGRAMME_TYPES: (userId: number): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/cpd/get-all-user-cpd-product-suites/${userId}`,
    ...getConfig(),
    withCredentials: true,
  }),
};
