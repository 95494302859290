import React from 'react';

import CPDCertificateItem from './CPDCertificateItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';
import { CPDCertificatesTypes } from 'pages/cpd/types';

type CPDCertificatesProps = {
  cpdCertificates?: CPDCertificatesTypes[];
  loaded: boolean;
  redirect: boolean;
  handleSidebarAccordion: any;
};

const CPDCertificates: React.FunctionComponent<CPDCertificatesProps> = ({
  cpdCertificates,
  loaded,
  handleSidebarAccordion,
  redirect,
}) => {
  const handleCpdProgressTab = (value: boolean, type: string) => {
    handleSidebarAccordion(value, type);
  };
  return loaded ? (
    <React.Fragment>
      {cpdCertificates && cpdCertificates?.length > 0 && (
        <section className={styles.cpdCertificates}>
          <h3>MY CPD PROGRESS</h3>
          <ul>
            {cpdCertificates ? (
              cpdCertificates.map(
                (certificate: CPDCertificatesTypes, index: number) => (
                  <CPDCertificateItem
                    certificate={certificate}
                    key={`certificate-${index}`}
                    handleAccordion={handleCpdProgressTab}
                    redirect={redirect}
                  />
                ),
              )
            ) : (
              <h3>There are no certificate yet</h3>
            )}
          </ul>
        </section>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default CPDCertificates;
