import React, { useEffect } from 'react';
import clsx from 'clsx';

import { ReactComponent as MyaxelosShortStyle } from 'modules/theme/ims/logos/myaxelos-short.svg';
import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type ProgrammeProps = {
  title: string;
  type: number;
  children?: React.ReactNode;
  logoStyle?: number;
  accordionStatus?: any;
  isOpenFromHomePage?: any;
  isAccordionOpen?: boolean;
  setOpenAccordion: (val: string) => void;
};

const Programme: React.FunctionComponent<ProgrammeProps> = ({
  title,
  children,
  type,
  accordionStatus,
  isOpenFromHomePage,
  isAccordionOpen,
  setOpenAccordion,
}) => {
  const isOpen = title === isOpenFromHomePage ? true : false;
  const { animating, handleClick, handleReset, open, ref } =
    useAccordion(isOpen);
  const classNames = clsx(
    styles.programme,
    animating && styles.animating,
    !open && styles.closed,
    `${styles[`CPDProgrammeType-${type}`]}`,
  );

  useEffect(() => {
    handleClick();
  }, [accordionStatus]);

  useEffect(() => {
    if (isAccordionOpen === false) handleReset();
  }, [isAccordionOpen]);

  const handleOnClick = () => {
    handleClick();
    setOpenAccordion(title);
  };

  return (
    <section className={classNames}>
      <h2>
        {<MyaxelosShortStyle />}
        <span>{title}</span>
        <Button.Generic
          ariaControls={title}
          ariaExpanded={open}
          ariaLabel={`${title} section`}
          onClick={handleOnClick}
        >
          <span></span>
        </Button.Generic>
      </h2>
      <div aria-hidden={!open} id={title} ref={ref}>
        <div>{children}</div>
      </div>
    </section>
  );
};

export default Programme;
