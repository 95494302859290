export const domainCreation = (clientid: any) => {
  const domains = [
    {
      id: '1',
      // domain: 'https://www.peoplecert.org/api/accounts/sso',
      domain: process.env.REACT_APP_PEOPLECERT_SSO_DOMAINS,
    },
    {
      id: '2',
      // domain: 'https://www.languagecert.org/api/accounts/sso',
      domain: process.env.REACT_APP_LANGUAGECERT_SSO_DOMAINS,
    },
  ];
  const iurl = window?.location?.href;
  let url = '';
  domains.forEach((item: any) => {
    if (url === '') {
      url = iurl;
    }
    url = item.domain + `?clientid=${clientid}&url=${url}&iurl=${iurl}`;
  });
  window.location.href = url;
};
