import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { BadgesTypes } from 'pages/badges/types';
import {
  BadgeSession,
  BadgeStatus,
} from 'pages/badges/components/Badges/constants';
import { CandidateIdTypes, DashboardLoadedType, DashboardType } from '../types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';
import { APIExceptions } from 'modules/api/exceptions';

export const useBadgesForHomePage = (
  runPolling: (
    homeBadges: BadgesTypes[],
    setHomeBadges: React.Dispatch<
      React.SetStateAction<BadgesTypes[] | undefined>
    >,
  ) => void,
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  candidateIds?: CandidateIdTypes[],
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [homeBadges, setHomeBadges] = useState<BadgesTypes[] | undefined>();

  // useInitClaimBadges Code
  const [examNotPassed, setExamNotPassed] = useState(false);
  const [examNotPassedUrl, setExamNotPassedUrl] = useState('');

  useEffect(() => {
    if (
      dashboard &&
      loginTypeContext &&
      loginTypeContext !== LoginTypeEnum.CONSULTANT
    ) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          api(API.GET_CLAIM_BADGES()).then(response => {
            if (active) {
              // useInitClaimBadges Code
              setExamNotPassed(false);
              if (
                sessionStorage.getItem(
                  `Claiming:${response.data.assertionId}`,
                ) != null
              ) {
                sessionStorage.setItem(
                  BadgeSession.DASHBOARD_CLAIMING_TITLE,
                  BadgeSession.DASHBOARD_CLAIMING_VALUE,
                );
              }

              candidateIds &&
                candidateIds !== undefined &&
                candidateIds.length > 0 &&
                setHomeBadges(response.data !== undefined ? response.data : []);

              let badgesStatusIndex = [];
              if (
                response?.data !== undefined &&
                response?.data !== null &&
                response?.data.length > 0
              ) {
                badgesStatusIndex = response?.data?.findIndex(
                  (el: BadgesTypes) =>
                    el.status === BadgeStatus.BADGE_PENDING ||
                    el.status === BadgeStatus.BADGE_CLAIMABLE,
                );
                if (badgesStatusIndex > -1) {
                  runPolling(response?.data, setHomeBadges);
                }
              }
            }
          });
        } catch (error: any) {
          active &&
            candidateIds &&
            candidateIds.length > 0 &&
            logger.error('Get Badges Error', error);

          // useInitClaimBadges Code
          if (active) {
            if (
              error.response.data.type === APIExceptions.NoExamPassedException
            ) {
              setExamNotPassed(true);
              setExamNotPassedUrl(error.response.data.message);
            } else {
              logger.error('Failed to load claimable badge', error);
            }
          }
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              homeBadges: true,
              claim: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [candidateIds, dashboard, loginTypeContext, runPolling, setLoaded]);

  return {
    homeBadges,
    setHomeBadges,
    claimStates: {
      examNotPassed,
      examNotPassedUrl,
      setExamNotPassed,
      setExamNotPassedUrl,
    },
  };
};
