import React, { useContext, useEffect, useState } from 'react';
import { boolean } from 'yup';
import * as Layout from 'components/Layout';
import { API, api } from 'modules/api';
import logger from 'modules/logger';
import { AccountDataContext } from 'modules/context';

import Programme from './components/Programme';
import CPDCertificates from './components/CPDCertificates';
import CPDTrack from './components/CPDTrack';
import NoCertification from './components/NoCertification';
import styles from './components/CPDCertificates/styles.module.scss';
import { openValue } from 'pages/home/Template';
import SkeletonCertificates from './components/CPDCertificates/Skeleton';
import Skeleton from 'components/Accordion/Skeleton';

export const isOpenValue = boolean;

const Template: React.FunctionComponent<any> = () => {
  const [isFetchData, setIsFetchData] = useState(false);
  const [hasCertificates, setHasCertificates] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [itilAccordion, setItilAccordion] = useState(false);
  const [prince2Accordion, setPrince2Accordion] = useState(false);
  const [agileAccordion, setAgileAccordion] = useState(false);
  const [resiliaAccordion, setResiliaAccordion] = useState(false);
  const [isOpenFromHomePage] = useState(openValue);
  const [openAccordion, setOpenAccordion] = useState('-');

  const handleSidebarAccordion = (value: any, type: string) => {
    setOpenAccordion(type);
    switch (type) {
      case 'ITIL 4':
        setItilAccordion(!itilAccordion);
        break;

      case 'Project, Programme & Portfolio Management':
        setPrince2Accordion(!prince2Accordion);
        break;

      case 'AgileShift':
        setAgileAccordion(!agileAccordion);
        break;

      case 'RESILIA':
        setResiliaAccordion(!resiliaAccordion);
        break;
    }
  };

  useEffect(() => {
    if (isOpenFromHomePage !== '') {
      handleSidebarAccordion(true, openValue);
    }
  }, [isOpenFromHomePage]);

  // const handleAccordion = (handleClick: any, setOpen: any, type: string) => {
  //   switch (type) {
  //     case 'ITIL 4':
  //       // setOpen(itilAccordion);
  //       break;

  //     case 'Prince 2':
  //       // setOpen(prince2Accordion);
  //       break;

  //     case 'AgileShift':
  //       // setOpen(agileAccordion);
  //       break;

  //     case 'RESILIA':
  //       // setOpen(resiliaAccordion);
  //       break;
  //   }
  // };

  const [CPDTrackRecords, setCPDTrackRecord] = useState([]);
  const { accountDataContext } = useContext(AccountDataContext);
  const userId =
    accountDataContext?.userId !== undefined ? accountDataContext?.userId : 0;

  useEffect(() => {
    try {
      api(API.GET_CPD_PRODUCT_SUITE_INFO(userId))
        .then(res => {
          setCPDTrackRecord(res?.data);
          setIsLoaded(true);
          if (res?.data.length > 0) {
            setHasCertificates(true);
          }
        })
        .catch(error => {
          logger.error('Get GET_CPD_PRODUCT_SUITE_INFO Error', error);
        });
    } catch (error) {
      logger.error('Error Getting CPD Product Suite Info', error);
    }
  }, [userId, isFetchData]);

  const certificatesList = CPDTrackRecords?.map((item: any) => {
    item.cpdSuiteProgress.expiryDate = item?.expiryDate;

    if (item.name === openAccordion) {
      item.isAccordionOpen = true;
    } else {
      item.isAccordionOpen = false;
    }

    return item.cpdSuiteProgress;
  });

  // useEffect(() => {
  //   let result = false;
  //   certificatesList.forEach(record => {
  //     if (record.cpdStatus === 'Eligible') result = true;
  //     setHasCertificates(result);
  //   });
  // });

  const checkAccordionStatus = (type: string) => {
    switch (type) {
      case 'ITIL 4':
        return itilAccordion;

      case 'Project, Programme & Portfolio Management':
        return prince2Accordion;

      case 'AgileShift':
        return agileAccordion;

      case 'RESILIA':
        return resiliaAccordion;
    }
  };

  CPDTrackRecords.map((item: any) => {
    if (item.name === openAccordion) {
      item.isAccordionOpen = true;
    } else {
      item.isAccordionOpen = false;
    }
  });

  return (
    <Layout.Container>
      <>
        <Layout.Content>
          {isLoaded ? (
            <>
              {!hasCertificates && <NoCertification />}

              {hasCertificates &&
                CPDTrackRecords?.length > 0 &&
                CPDTrackRecords?.map((item: any, index: number) => (
                  <Programme
                    key={`Programme-${index}`}
                    title={item.name}
                    type={index}
                    accordionStatus={checkAccordionStatus(item.name)}
                    isOpenFromHomePage={isOpenFromHomePage}
                    isAccordionOpen={item.isAccordionOpen}
                    setOpenAccordion={setOpenAccordion}
                  >
                    <CPDTrack
                      setIsFetchDataCallback={setIsFetchData}
                      loaded={true}
                      name={item.name}
                      cpdTrackItem={item}
                    />
                  </Programme>
                ))}
            </>
          ) : (
            <Skeleton />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          {isLoaded ? (
            <CPDCertificates
              cpdCertificates={certificatesList}
              redirect={false}
              loaded={true}
              handleSidebarAccordion={handleSidebarAccordion}
            />
          ) : (
            <SkeletonCertificates />
          )}
        </Layout.Sidebar>
      </>
    </Layout.Container>
  );
};

export default Template;
