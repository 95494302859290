import { CertificatesOtherBestPracticesTitles } from './constants';

export const certificatesOtherBestPractices = [
  {
    category: 'MOV',
    certificates: [
      {
        title: CertificatesOtherBestPracticesTitles.FOUNDATION,
        uniqueTitle: 'MoV Foundation',
      },
      {
        title: CertificatesOtherBestPracticesTitles.PRACTITIONER,
        uniqueTitle: 'MoV Practitioner',
      },
    ],
  },
  {
    category: 'RESILIA',
    certificates: [
      {
        title: CertificatesOtherBestPracticesTitles.RESILIA_FOUNDATION,
        uniqueTitle: 'Resilia Foundation',
      },
      {
        title: CertificatesOtherBestPracticesTitles.RESILIA_PRACTITIONER,
        uniqueTitle: 'Resilia Practitioner',
      },
    ],
  },
  {
    category: 'AGILESHIFT',
    certificates: [
      {
        title: CertificatesOtherBestPracticesTitles.AGILESHIFT_FOUNDATION,
        uniqueTitle: 'AgileSHIFT Foundation',
      },
    ],
  },
];
