import { API, api } from 'modules/api';
import logger from 'modules/logger';
import {
  mapContentTypeEngagementTime,
  mapEngagementSummaryContentType,
  mapEngagementSummaryPageType,
  mapPageTypeEngagementTime,
} from './utility';

export function EngagementSummaryPageType(data: any) {
  const mappedData = mapEngagementSummaryPageType(data);

  // Post Engagement Summary Page Type
  (async () => {
    try {
      api(API.POST_DASHBOARD_SUMMARY_PAGE_TYPE(mappedData));
    } catch (error) {
      logger.debug('EngagementSummaryPageType Error', error);
    }
  })();
}

export function EngagementSummaryContentType(data: any) {
  const mappedData = mapEngagementSummaryContentType(data);

  // Post Engagement Summary Content Type
  (async () => {
    try {
      api(API.POST_DASHBOARD_SUMMARY_CONTENT_TYPE(mappedData));
    } catch (error) {
      logger.debug('EngagementSummaryContentType Error', error);
    }
  })();
}

export function ContentTypeEngagementTime(data: any) {
  const mappedData = mapContentTypeEngagementTime(data);

  // Post Engagement Summary Content Type
  (async () => {
    try {
      api(API.POST_CONTENT_TYPE_ENGAGEMENT_TIME(mappedData));
    } catch (error) {
      logger.debug('ContentTypeEngagementTime Error', error);
    }
  })();
}

export function PageTypeEngagementTime(data: any) {
  const mappedData = mapPageTypeEngagementTime(data);

  // Post Engagement Summary Page Type
  (async () => {
    try {
      api(API.POST_PAGE_TYPE_ENGAGEMENT_TIME(mappedData));
    } catch (error) {
      logger.debug('PageTypeEngagementTime Error', error);
    }
  })();
}
