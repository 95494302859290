import React, { useEffect, useMemo, useState } from 'react';

import { CuratedPageType } from 'pages/curated-pages/types';
import {
  filtersHookType,
  MuiAutocompleteProps,
  resourcesType,
  ResourceHubLoadedType,
} from './types';
import { LoginTypeEnum } from 'modules/utils/types';
import { pageCardList } from './helper';
import { EVENTS_URL, RESOURCE_HUB_URL } from '../../modules/utils/constants';
import * as Button from 'components/Button';
import * as Layout from 'components/Layout';
import * as Filters from './components/Filters';
import CuratedPages from './components/CuratedPages';
import SearchBar from './components/SearchBar';
import { SortByTypes, sortByType } from './utils';

import styles from './styles.module.scss';
import { sortByOptions } from './components/Filters/FiltersSortBy';
import { useHistory } from 'react-router-dom';

type TemplateProps = {
  curatedPages?: CuratedPageType[];
  filters: filtersHookType;
  getData: (resetNumberOfitemsShown: boolean) => Promise<void>;
  handleEnterKeyDown: (event: React.KeyboardEvent) => void;
  handleSearchButtonClick: () => void;
  searchInitiated: boolean;
  loaded: ResourceHubLoadedType;
  loginTypeContext: LoginTypeEnum;
  muiAutocompleteProps: MuiAutocompleteProps;
  pathname: string;
  resources: resourcesType;
  searchTerm: string;
  showMore: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  curatedPages,
  filters,
  getData,
  handleEnterKeyDown,
  handleSearchButtonClick,
  loaded,
  loginTypeContext,
  muiAutocompleteProps,
  pathname,
  resources,
  searchTerm,
  showMore,
  searchInitiated,
}) => {
  const numberOfMoreItems = useMemo(() => {
    return filters.numTotalPages - filters.numberOfitemsShown > 10
      ? 10
      : filters.numTotalPages - filters.numberOfitemsShown;
  }, [filters.numTotalPages, filters.numberOfitemsShown]);

  const [sortByTypesVal, setSortByTypesVal] = useState(SortByTypes.Date_DESC);
  const [sortByLabel, setSortByLabel] = useState('Date (newest first)');
  const [sortedResults, setSortedResults] = useState([...filters.resourceHub]);
  const getSortByValue = (value: SortByTypes) => {
    setSortByTypesVal(value);
  };

  const isResourceHub = window.location.href.includes('/resource-hub')
    ? true
    : false;
  const history = useHistory();
  const path = !isResourceHub ? EVENTS_URL : RESOURCE_HUB_URL;

  // useEffect(() => {
  //   if (isEvent) {
  //     history.push('/events-hub/events');
  //   }
  // });

  useEffect(() => {
    setSortedResults(sortByType([...filters.resourceHub], sortByTypesVal));

    const label = sortByOptions
      .filter(e => e.value === sortByTypesVal)
      .map(e => e.label);
    setSortByLabel(label[0]);
  }, [filters.resourceHub, sortByTypesVal]);

  useEffect(() => {
    if (
      filters.topicsSelected[0].title === 'All' &&
      filters.bestPracticesSelected[0].title === 'All' &&
      filters.contentTypesSelected[0].title === 'All'
    ) {
      setSortByTypesVal(SortByTypes.Date_DESC);
    }
    if (searchTerm === '' && searchInitiated) {
      setSortByTypesVal(SortByTypes.Date_DESC);
    }
  }, [
    filters.topicsSelected,
    filters.bestPracticesSelected,
    filters.contentTypesSelected,
    searchTerm,
    searchInitiated,
  ]);

  return (
    <Layout.Container>
      <Layout.Content>
        <SearchBar
          handleEnterKeyDown={handleEnterKeyDown}
          handleSearchButtonClick={handleSearchButtonClick}
          muiAutocompleteProps={muiAutocompleteProps}
          isEvent={!isResourceHub}
        />
        <Filters.Container
          filters={filters}
          getData={getData}
          getSortByValue={getSortByValue}
          loginTypeContext={loginTypeContext}
          resources={resources}
          slug={pathname.replace(path, '')}
          searchInitiated={searchInitiated}
          searchTerm={searchTerm}
        />
        {loaded.resourceHub && searchTerm && filters.numTotalPages !== 0 && (
          <div className={styles.searchResults}>
            <p>{`${filters.numTotalPages} results for '${searchTerm}'`}</p>
          </div>
        )}

        {sortByLabel !== undefined &&
          (filters.topicsSelected[0].title !== 'All' ||
            filters.bestPracticesSelected[0].title !== 'All' ||
            filters.contentTypesSelected[0].title !== 'All' ||
            (searchTerm !== '' && searchInitiated)) && (
            <p className={styles.sortByLabel}>
              Sort By: <span>{sortByLabel}</span>
            </p>
          )}

        {pageCardList(
          sortedResults,
          filters.numberOfitemsShown,
          loaded.resourceHub,
        )}
        {loaded.resourceHub &&
          filters.numberOfitemsShown < filters.numTotalPages && (
            <Button.Generic
              className={styles.showMoreButton}
              label={`Show ${numberOfMoreItems} more`}
              onClick={showMore}
            />
          )}
      </Layout.Content>
      <Layout.Sidebar>
        <CuratedPages curatedPages={curatedPages} loaded={loaded.curated} />
      </Layout.Sidebar>
    </Layout.Container>
  );
};

export default Template;
