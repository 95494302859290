import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type useMarketingProps = (isLoggedIn: boolean) => {
  candidateToken: string;
  plan: ChargebeeSubscriptionPlanId;
};
export const useMarketing: useMarketingProps = isLoggedIn => {
  const { search: params } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(params), [params]);

  const plan = useMemo(
    () => searchParams.get('plan') as ChargebeeSubscriptionPlanId,
    [searchParams],
  );
  const candidateToken = useMemo(
    () => searchParams.get('peoplecert-passport') ?? '',
    [searchParams],
  );
  // check if the input is a valid enum key
  const planIsSet = useMemo(
    () => Object.values(ChargebeeSubscriptionPlanId).includes(plan),
    [plan],
  );
  const tokenIsSet = useMemo(
    () => candidateToken && candidateToken.length,
    [candidateToken],
  );
  const noPlanOrToken = useMemo(
    () => !planIsSet && !tokenIsSet,
    [planIsSet, tokenIsSet],
  );
  const loggedOutAndNoPlan = useMemo(
    () => isLoggedIn && !planIsSet,
    [isLoggedIn, planIsSet],
  );

  if (!window.location.href.includes('/signup')) {
    if (noPlanOrToken || loggedOutAndNoPlan) {
      window.location.href = process.env.REACT_APP_NOROLE_URL!;
    }
  }

  return { candidateToken, plan: plan } as const;
};
