import React from 'react';

import { InputSize } from 'components/Inputs/Input/constants';
import { QuestionProps } from '../Template/types';
import * as Input from 'components/Inputs/Input';
import * as Question from 'components/Question';

type QuestionTextProps<T> = QuestionProps<T> & {
  autoComplete?: string;
  readOnly?: boolean;
  handleCheckIsUserMyAxelos?: boolean;
  useIsUserMyAxelos?: any;
  handleRoundValue?: boolean;
};

function Component<T>({
  autoComplete,
  control,
  disabled,
  help,
  showHelpOnClick,
  isSubmitting,
  handleCheckIsUserMyAxelos,
  label,
  useIsUserMyAxelos,
  handleRoundValue,
  question,
  readOnly,
  size = InputSize.REGULAR,
}: QuestionTextProps<T>) {
  const handleRoundValueBlur = (e: any, onChange: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useIsUserMyAxelos(e.target.value);

    const inputToNumber = Number(e.target.value);
    const roundedValue = Math.round(inputToNumber * 2) / 2;
    onChange(`${roundedValue}`);
  };
  return (
    <Question.Template
      control={control}
      help={help}
      showHelpOnClick={showHelpOnClick}
      label={label}
      question={question}
    >
      {({ invalid, name, onBlur, onChange, ref, value }) => (
        <Input.String
          autoComplete={autoComplete}
          forwardedRef={ref}
          id={name}
          invalid={invalid}
          label={label}
          name={name}
          onBlur={
            handleRoundValue
              ? (e: any) => handleRoundValueBlur(e, onChange)
              : onBlur
          }
          onChange={onChange}
          readOnly={isSubmitting || readOnly}
          tabIndex={disabled ? -1 : 0}
          value={value}
          size={size}
        />
      )}
    </Question.Template>
  );
}

export default React.memo(Component) as typeof Component;
