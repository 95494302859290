import { useState, useEffect, useRef, useCallback } from 'react';

import { API, api } from 'modules/api';
import { BadgeSession } from 'pages/badges/components/Badges/constants';
import { BADGES_URL } from 'modules/utils/constants';
import { APIExceptions } from 'modules/api/exceptions';
import logger from 'modules/logger';

export const useClaimBadge = (
  updateBadges: () => Promise<void>,
  setExamNotPassed: React.Dispatch<React.SetStateAction<boolean>>,
  setExamNotPassedUrl: React.Dispatch<React.SetStateAction<string>>,
) => {
  const [isClaimSubmitting, setIsClaimSubmitting] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const active = useRef(true);

  const getBadges = useCallback(
    async (openLink = true) => {
      try {
        setIsClaimSubmitting(true);
        api(API.GET_CLAIM_BADGES())
          .then(res => {
            if (active.current) {
              setExamNotPassed(false);
              if (openLink) {
                setClaiming(true);
                sessionStorage.setItem(
                  BadgeSession.DASHBOARD_CLAIMING_TITLE,
                  BadgeSession.DASHBOARD_CLAIMING_VALUE,
                );
                // Set session item for badges page
                sessionStorage.setItem(
                  `Claiming:${res.data.assertionId}`,
                  res.data.assertionId,
                );
                window.open(res.data.acceptUrl, '_blank');
                updateBadges();
              }
            }
          })
          .catch(error => {
            active && logger.error('GET_CLAIM_BADGES Error', error);
          });
      } catch (error: any) {
        if (active.current) {
          logger.error('Error getting badge claim link', error);
          if (
            error.response.data.type === APIExceptions.NoExamPassedException
          ) {
            setExamNotPassed(true);
            setExamNotPassedUrl(error.response.data.message);
          }
          if (error.response.data.type === APIExceptions.NoBadgeException) {
            if (openLink) {
              window.location.href = BADGES_URL;
            }
          }
        }
      } finally {
        setIsClaimSubmitting(false);
      }
    },
    [setExamNotPassed, setExamNotPassedUrl, updateBadges],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    getBadges,
    isClaimSubmitting,
    claiming,
  };
};
