import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { DashboardLoadedType } from '../types';
import { LoginTypeContext } from 'modules/context';
import { ResourceHubItemType } from 'components/FeaturedResources/types';
import logger from 'modules/logger';

export const useFeaturedResources = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [featuredResources, setFeaturedResources] =
    useState<ResourceHubItemType[]>();

  useEffect(() => {
    if (loginTypeContext) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          api(API.GET_DASHBOARD_FEATURED_RESOURCES(loginTypeContext)).then(
            response => {
              active && setFeaturedResources(response.data.entries);
            },
          );
        } catch (error) {
          active && logger.error('Get Featured Resources Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              featuredResources: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loginTypeContext, setLoaded]);

  return { featuredResources };
};
