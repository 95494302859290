import { QuestionType } from 'components/QuestionDate/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  email: yup.string().email().required('This field is required'),
});

export type Model = {
  email: string;
};

const email: QuestionType<Model> = {
  name: 'email',
};

export const model = {
  email,
};
