export enum CertificatesPropPathTitles {
  PRINCE2_FOUNDATION = 'PRINCE2 Foundation',
  PRINCE2_PRACTITIONER = 'PRINCE2 Practitioner',
  PRINCE2_7_FOUNDATION = 'PRINCE2 7 Foundation',
  PRINCE2_7_PRACTITIONER = 'PRINCE2 7 Practitioner',
  PRINCE2_AGILE_FOUNDATION = 'PRINCE2 Agile Foundation',
  PRINCE2_AGILE_PRACTITIONER = 'PRINCE2 Agile Practitioner',
  MSP_FOUNDATION = 'MSP Foundation',
  MSP_PRACTITIONER = 'MSP Practitioner',
  MOR_PRACTITIONER = 'M_o_R Practitioner',
  P3O_FOUNDATION = 'P3O Foundation',
  P3O_PRACTITIONER = 'P3O Practitioner',
  MOP_FOUNDATION = 'MoP Foundation',
  MOP_PRACTITIONER = 'MoP Practitioner',
}

export enum ProPathDesignationsTitles {
  PROJECT_EXPERT = 'ProPath Project Expert',
  AGILE_PROJECT_EXPERT = 'ProPath Agile Project Expert',
  PROGRAMME_LEADER = 'ProPath Programme Leader',
}

export enum CertificatesItilTitles {
  FOUNDATION = 'ITIL Foundation',
  CREATE_DELIVER_SUPPORT = 'Create, Deliver & Support',
  DRIVE_STAKEHOLDER_VALUE = 'Drive Stakeholder Value',
  HIGH_VELOCITY_IT = 'High Velocity IT',
  DIRECT_PLAN_IMPROVE = 'Direct, Plan & Improve',
  DIGITAL_IT_STRATEGY = 'Digital & IT Strategy',
  SUSTAINABILITY_IN_DIGITAL_IT = 'Sustainability in Digital & IT',
  ACQUIRING_MANAGING_CLOUD_SERVICES = 'Acquiring & Managing Cloud Services',
  BRM = 'Business Relationship Management',
  ITAM = 'IT Asset Management',
  MPT = 'Managing Professional Transition',

  MONITOR_SUPPORT_AND_FULFIL = 'Monitor, Support and Fulfil',
  INCIDENT_MANAGEMENT = 'Incident Management',
  SERVICE_DESK = 'Service Desk',
  SERVICE_REQUEST_MANAGEMENT = 'Service Request Management',
  MONITORING_AND_EVENT_MANAGEMENT = 'Monitoring and Event Management',

  PROBLEM_MANAGEMENT = 'Problem Management',
  IT_ASSET_MANAGEMENT = 'IT Asset Management',
  CHANGE_ENABLEMENT = 'Change Enablement',
  DEPLOYMENT_MANAGEMENT = 'Deployment Management',
  RELEASE_MANAGEMENT = 'Release Management',
  SERVICE_CONFIGURATION_MANAGEMENT = 'Service Configuration Management',
  PLAN_IMPLEMENT_AND_CONTROL = 'Plan, Implement and Control',

  COLLABORATE_ASSURE_AND_IMPROVE = 'Collaborate Assure and Improve',
  CONTINUAL_IMPROVEMENT = 'Continual Improvement',
  SERVICE_LEVEL_MANAGEMENT = 'Service Level Management',
  RELATIONSHIP_MANAGEMENT = 'Relationship Management',
  INFORMATION_SECURITY_MANAGEMENT = 'Information Security Management',
  SUPPLIER_MANAGEMENT = 'Supplier Management',
}

export enum ItilDesignationsTitles {
  MANAGING_PROFESSIONAL = 'ITIL Managing Professional',
  STRATEGIC_LEADER = 'ITIL Strategic Leader',
  PRACTICE_MANAGER = 'ITIL Practice Manager',
  ITIL_4_MASTER = 'ITIL 4 Master',
}

export enum CertificatesOtherBestPracticesTitles {
  FOUNDATION = 'MoV Foundation',
  PRACTITIONER = 'MoV Practitioner',
  RESILIA_FOUNDATION = 'Resilia Foundation',
  RESILIA_PRACTITIONER = 'Resilia Practitioner',
  AGILESHIFT_FOUNDATION = 'AgileSHIFT Foundation',
}
