import React, { memo, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BadgeSvg } from 'modules/theme/icons/misc/dashboard-badge.svg';

import styles from './styles.module.scss';
import { BadgeQualifications, CPDCertificatesTypes } from 'pages/cpd/types';
import * as Hyperlink from 'components/Hyperlink';
import { getDateFormattedForCPD } from 'modules/utils/utils';

type CPDCertificateItemTypes = {
  certificate: CPDCertificatesTypes;
  handleAccordion: any;
  redirect: boolean;
};

const CPDCertificateItem: React.FunctionComponent<CPDCertificateItemTypes> = ({
  certificate,
  handleAccordion,
  redirect,
}) => {
  const [tab, setTab] = useState(false);
  const hasAchieved = certificate?.qualifications?.filter(
    (a: BadgeQualifications) => a.achieved,
  ).length;
  const complete = hasAchieved === certificate.qualifications?.length;
  const finishCount = `${hasAchieved} of ${certificate.qualifications?.length} completed`;
  const history = useHistory();
  const classNames = clsx(
    styles.cpdCertificateItem,
    // complete && styles.complete,
    // complete && certificate.family === 'ProPath' && styles.proPath,
  );

  const handleClick = (e: any, type: string) => {
    setTab(!tab);
    handleAccordion(!tab, type);
    e.preventDefault();
  };

  return !certificate.isCertificate ? (
    <li className={classNames}>
      <div>
        <BadgeSvg />
        <p>{certificate.family}</p>
      </div>

      {certificate !== undefined && certificate.expiryDate !== null && (
        <p className={styles.expiryDate}>
          <b>
            {' '}
            {certificate.cpdStatus === 'Expired'
              ? 'Inactive'
              : 'Renewal'} : {getDateFormattedForCPD(certificate.expiryDate)}
          </b>
        </p>
      )}

      <hr />

      {certificate.cpdLogInPeriod !== '' &&
        certificate.cpdLogInPeriod !== null && (
          <p className={styles.expiryDateGrey}>
            Current CPD log in period : <b>{certificate.cpdLogInPeriod}</b>
          </p>
        )}

      <div className={styles.flex}>
        <p className={styles.expiryDateGrey}>CPD Status:</p>
        <b>
          {certificate.cpdStatus === 'On Track' ||
          certificate.cpdStatus === 'Eligible' ? (
            <p className={styles.success}>{certificate.cpdStatus}</p>
          ) : (
            <p className={styles.failure}>{certificate.cpdStatus}</p>
          )}
        </b>
      </div>

      <br />

      <div>
        <Hyperlink.CustomLink href={''}>
          <p
            className={styles.learnMore}
            onClick={(e: any) =>
              redirect
                ? history.push('/cpd')
                : handleClick(e, certificate.family)
            }
          >
            {certificate.cpdStatus === 'On Track' ||
            certificate.cpdStatus === 'Eligible'
              ? 'Manage CPD'
              : 'Learn more'}
          </p>
        </Hyperlink.CustomLink>

        {/* <span>{finishCount}</span>
        {complete ? (
          <React.Fragment>
            <em>Finished</em>
            <AchievedTrophySvg />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <em>In Progress</em>
            <TrophySvg />
          </React.Fragment>
        )} */}
      </div>
    </li>
  ) : null;
};

export default memo(CPDCertificateItem);
