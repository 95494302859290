import { QuestionType } from 'components/QuestionDate/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  otp: yup.string().required('This field is required'),
});

export type Model = {
  otp: string;
};

const otp: QuestionType<Model> = {
  name: 'otp',
};

export const model = {
  otp,
};
