import React, { useEffect, useState } from 'react';
import { LinearProgress, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import styles from './styles.module.scss';

const Modal: React.FunctionComponent = () => {
  const [level, setLevel] = useState(0);

  const BorderLinearProgress = withStyles({
    root: {
      height: 50,
      width: '100%',
      backgroundColor: '#282d3d ',
      borderRadius: '10px',
      border: '3px solid #282d3d',
    },
    bar: {
      borderRadius: 20,
      backgroundColor: '#6de2e2',
    },
  })(LinearProgress);

  useEffect(() => {
    const timer = setInterval(() => {
      setLevel(newLevel => (newLevel >= 95 ? 95 : newLevel + 5));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    document.documentElement.classList.add('lock-screen');
    return () => {
      document.documentElement.classList.remove('lock-screen');
    };
  }, []);

  return (
    <div className={styles.loaderModal}>
      <Box position="relative" display="inline-flex" style={{ width: '30%' }}>
        <BorderLinearProgress variant="determinate" value={level} />
      </Box>
    </div>
  );
};

export default Modal;
