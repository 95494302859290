import { useEffect, useState } from 'react';
import { API, api } from 'modules/api';

import { AccountLoadedType } from '../../types';
import { LoginTypeEnum } from 'modules/utils/types';
import { PaymentHistoryResponse } from 'modules/api/endpoints/payment-history';
import { PaymentHistoryYearItemProps } from './PaymentHistoryYear/PaymentHistoryYearItem';
import logger from 'modules/logger';

export const usePaymentHistory = (
  setSkeletonLoaded: React.Dispatch<React.SetStateAction<AccountLoadedType>>,
  loginTypeContext?: LoginTypeEnum,
) => {
  const [paymentHistoryList, setPaymentHisyoryList] =
    useState<PaymentHistoryYearItemProps[]>();
  const [paymentYears, setPaymentYears] = useState<number[]>();
  //TODO done useEffect
  useEffect(() => {
    if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
      let active = true;
      (async () => {
        try {
          api(API.GET_PAYMENT_HISTORY()).then(
            (response: PaymentHistoryResponse) => {
              if (active) {
                const yearList: number[] = [];
                let paymentHistoryListAux = response.data || [];
                paymentHistoryListAux = paymentHistoryListAux.sort(
                  (
                    a: PaymentHistoryYearItemProps,
                    b: PaymentHistoryYearItemProps,
                  ) => (a.paymentDate > b.paymentDate ? -1 : 1),
                );

                paymentHistoryListAux?.forEach(
                  (paymentHistory: PaymentHistoryYearItemProps) => {
                    const paymentDate = new Date(
                      paymentHistory.paymentDate.split('T')[0],
                    );
                    const year = paymentDate.getFullYear();

                    if (!yearList.includes(year)) {
                      yearList.push(year);
                    }
                  },
                );

                setPaymentHisyoryList(paymentHistoryListAux);
                setPaymentYears(yearList);
              }
            },
          );
        } catch (error) {
          if (active) {
            logger.error('Get Payment History Error', error);
            setPaymentHisyoryList([]);
            setPaymentYears([]);
          }
        } finally {
          active &&
            setSkeletonLoaded(prevState => ({
              ...prevState,
              paymentHistory: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    } else {
      setSkeletonLoaded(prevState => ({
        ...prevState,
        payments: true,
      }));
    }
  }, [loginTypeContext, setSkeletonLoaded]);

  return { paymentHistoryList, paymentYears };
};
