import React from 'react';

import { PaymentInfoType } from './types';
import { ReactComponent as ArrowSVG } from './arrow.svg';
import { useCancelSubscriptionHelp } from '../hooks';
import * as Button from 'components/Button';
import PaymentMethod from './PaymentMethod';

import styles from './styles.module.scss';

type PaymentInformationProps = {
  handleAddPaymentMethod: () => void;
  handleEditPaymentMethod: (paymentMethod: PaymentInfoType) => void;
  handleRemovePaymentMethod: (id: string) => void;
  paymentMethods: PaymentInfoType[];
};

const PaymentInformation: React.FunctionComponent<PaymentInformationProps> = ({
  handleAddPaymentMethod,
  handleEditPaymentMethod,
  handleRemovePaymentMethod,
  paymentMethods,
}) => {
  const { scrollTo } = useCancelSubscriptionHelp();
  return (
    <div className={styles.paymentInformation}>
      <h3>
        <span>Payment information</span>
        <Button.Generic
          onClick={handleAddPaymentMethod}
          disabled={true} // Delete this after PeopleCert+ Transition
        >
          Add credit or debit card
          <ArrowSVG />
        </Button.Generic>
      </h3>
      {paymentMethods.map(item => {
        return item.paymentMethodDeleting ? null : (
          <PaymentMethod
            cardNumber={item.paymentMethodIdentifier}
            cardType={item.paymentMethodType}
            closed={true}
            defaultCard={item.paymentMethodDefault === 'True'}
            key={item.paymentMethodId}
            onEdit={() => handleEditPaymentMethod(item)}
            onRemove={() => handleRemovePaymentMethod(item.paymentMethodId)}
            valid={item.paymentMethodValid === 'True'}
          />
        );
      })}
      <p>
        If you would like to downgrade your subscription, please contact us{' '}
        <Button.Generic label="here" onClick={() => scrollTo()} />.
      </p>
    </div>
  );
};

export default React.memo(PaymentInformation);
