import React from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import Questions from 'components/Questions';
import * as Button from 'components/Button';
import * as Question from 'components/Question';
import { usePersonalInfo } from './hooks';
import { model, schema } from './model';

import styles from './styles.module.scss';

const PersonalInfo: React.FunctionComponent = () => {
  const { defaultValues, handleSubmit, isApiSubmitting, loading } =
    usePersonalInfo();
  return (
    <div className={styles.personalInfo}>
      <Questions
        defaultValues={defaultValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        schema={schema}
      >
        {({ control, isFormSubmitting }) => (
          <React.Fragment>
            <Question.Text
              control={control}
              label="First name"
              question={model.firstName}
              isSubmitting={isApiSubmitting || isFormSubmitting || loading}
              readOnly={true}
            />
            <Question.Text
              control={control}
              label="Last name"
              question={model.lastName}
              isSubmitting={isApiSubmitting || isFormSubmitting || loading}
              readOnly={true}
            />
            <Question.Text
              control={control}
              label="Job title"
              question={model.jobTitle}
              isSubmitting={isApiSubmitting || isFormSubmitting || loading}
            />
            <div>
              <Button.Submit
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={isApiSubmitting || isFormSubmitting || loading}
                label="Save Changes"
                isSubmitting={isApiSubmitting || isFormSubmitting}
                small
                style={ButtonStyle.PRIMARY}
              />
            </div>
          </React.Fragment>
        )}
      </Questions>
    </div>
  );
};

export default PersonalInfo;
