import React, { useEffect, useState } from 'react';

import { BadgesPageType, ProgrammePageType } from './types';
import { BadgesLoadedType } from './types';
import { certificatesItil, itilDesignations } from './certificatesItil';
import {
  certificatesPropPath,
  proPathDesignations,
} from './certificatesProPath';
import { useBadgesPolling } from './hooks/useBadgesPolling';
import { useBadges, useBadgesLinks } from './hooks';
import { usePathSlider } from './hooks/useSliderPaths';
import { usePathSliderControl } from './hooks/usePathSliderControl';
import Template from './Template';
import { useCandidateDetails } from 'pages/home/hooks';
import { certificatesOtherBestPractices } from './certificatesOtherBestPractices';
import Skeleton from 'pages/resource-hub/Skeleton';

export const Badges: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState<BadgesLoadedType>({
    badges: false,
    candidateId: false,
    certificates: false,
    paths: false,
  });

  const {
    candidateIds,
    setCandidateIds,
    achievements: paths,
  } = useCandidateDetails(setLoaded, true);

  const { badgesLinks } = useBadgesLinks(setLoaded);
  const { cancelPolling, runPolling } = useBadgesPolling();
  const { badges, setBadges } = useBadges(setLoaded, runPolling, candidateIds);

  const [
    proPathSlidePosition,
    itilSlidePosition,
    handleNextPropPath,
    handlePrevPropPath,
    handleNextItil,
    handlePrevItil,
  ] = usePathSliderControl(paths);

  const { filterPaths, sliderPaths } = usePathSlider();
  // const [newSliderPaths, setNewSliderPaths] = useState(sliderPaths);

  // useEffect(() => {
  //   setNewSliderPaths(sliderPaths);
  // }, [sliderPaths]);

  useEffect(() => {
    paths && filterPaths(paths);
  }, [paths, filterPaths]);

  const badgesData: BadgesPageType = {
    links: badgesLinks,
    earned:
      badges?.filter(b => b?.claimed) &&
      badges?.filter(b => b?.claimed).length > 0
        ? badges?.filter(b => b?.claimed).length
        : false,
    records: badges,
  };

  const filterSliderPaths = (path: any, predicate: any) => {
    let end = 0;
    for (let i = 0; i < path.length; i++) {
      const obj = path[i];
      if (predicate(obj)) {
        path[end++] = obj;
      }
    }
    path.length = end;
  };
  const toDelete = new Set(badges?.map(b => b.systemName));

  filterSliderPaths(sliderPaths, (obj: any) => !toDelete.has(obj.designation));

  const proPath: ProgrammePageType = {
    certificates: certificatesPropPath,
    designations: proPathDesignations,
    handleNext: handleNextPropPath,
    handlePrev: handlePrevPropPath,
    paths: sliderPaths?.filter(p => p.family === 'ProPath'),
    showPaths:
      sliderPaths && sliderPaths?.filter(p => p.family === 'ProPath').length > 0
        ? sliderPaths?.filter(p => p.family === 'ProPath').length
        : false,
    slidePosition: proPathSlidePosition,
  };

  const itil: ProgrammePageType = {
    certificates: certificatesItil,
    designations: itilDesignations,
    handleNext: handleNextItil,
    handlePrev: handlePrevItil,
    paths: sliderPaths?.filter(p => p.family === 'ITIL 4'),
    showPaths:
      sliderPaths && sliderPaths?.filter(p => p.family === 'ITIL 4').length > 0
        ? sliderPaths?.filter(p => p.family === 'ITIL 4').length
        : false,
    slidePosition: itilSlidePosition,
  };

  const otherBestPractices: ProgrammePageType = {
    certificates: certificatesOtherBestPractices,
    designations: [],
    handleNext: handleNextItil,
    handlePrev: handlePrevItil,
    paths: undefined,
    showPaths: false,
    slidePosition: 0,
  };

  return (
    <Template
      badges={badgesData}
      candidateId={candidateIds}
      cancelPolling={cancelPolling}
      itil={itil}
      otherBestPractices={otherBestPractices}
      loaded={loaded}
      proPath={proPath}
      runPolling={runPolling}
      setBadges={setBadges}
      setCandidateIds={setCandidateIds}
    />
  );
};
