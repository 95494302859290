import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
  ALL_FILTERS,
  ALL_FILTERS_ELEMENT,
  EVENTS_URL,
  RESOURCE_HUB_ELEMENTS_TO_SHOW,
  RESOURCE_HUB_URL,
} from 'modules/utils/constants';
import { API, api } from 'modules/api';
import { LoginTypeEnum } from 'modules/utils/types';
import { ResourceSearchAzureType } from 'modules/api/endpoints/resource-search';
import logger from 'modules/logger';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import {
  ContentTypeEngagementTime,
  EngagementSummaryContentType,
} from 'modules/engagementSummary';
import { AccountDataContext } from 'modules/context';

export const useFilters = (slug: string, loginTypeContext?: LoginTypeEnum) => {
  const [resourceHub, setResourceHub] = useState<ResourceSearchAzureType[]>([]);
  const [numberOfitemsShown, setNumberOfitemsShown] = useState(0);
  const [numTotalPages, setNumTotalPages] = useState(0);
  const path = window.location.href.includes('/resource-hub')
    ? RESOURCE_HUB_URL
    : EVENTS_URL;
  const [topicsSelected, setTopicsSelected] = useState([ALL_FILTERS_ELEMENT]);
  const [bestPracticesSelected, setBestPracticesSelected] = useState([
    ALL_FILTERS_ELEMENT,
  ]);
  const [contentTypesSelected, setContentTypesSelected] = useState([
    path === RESOURCE_HUB_URL
      ? ALL_FILTERS_ELEMENT
      : {
          title: 'Events',
          slug: 'events',
          uid: 'blte42f533344ab930a',
          colour: '',
        },
  ]);
  const active = useRef(true);
  const { accountDataContext } = useContext(AccountDataContext);
  const userEmail = accountDataContext?.email;

  //TODO done useCallback
  const fetchSearchData = useCallback(
    async (
      loginType: LoginTypeEnum,
      searchText: string,
      resetNumberOfitemsShown: boolean,
    ) => {
      try {
        const contentTypesSelectedAux = contentTypesSelected;
        const bestPracticesSelectedAux = bestPracticesSelected;
        const topicsSelectedAux = topicsSelected;
        const pages = await api(
          API.GET_RESOURCE_SEARCH(
            loginType,
            searchText,
            contentTypesSelectedAux,
            bestPracticesSelectedAux,
            topicsSelectedAux,
            resetNumberOfitemsShown ? 0 : numberOfitemsShown,
            RESOURCE_HUB_ELEMENTS_TO_SHOW,
          ),
        );

        if (active.current) {
          if (pages.data && pages.data.count > 0) {
            if (resetNumberOfitemsShown) {
              setResourceHub([...pages.data.entries]);
              setNumberOfitemsShown(pages.data.entries.length);
            } else {
              setResourceHub([...resourceHub, ...pages.data.entries]);
              setNumberOfitemsShown(
                numberOfitemsShown + pages.data.entries.length,
              );
            }
          } else {
            setResourceHub([]);
            setNumberOfitemsShown(0);
          }
          setNumTotalPages(pages?.data.count);

          // Logged Google Analytics for content types
          contentTypesSelectedAux.forEach(contentType => {
            if (contentType.title !== ALL_FILTERS) {
              let action = '';

              if (loginType === LoginTypeEnum.INDIVIDUAL) {
                action = EventsActions.CONTENT_TYPES_VISITED_INDIVIDUALS;
              } else if (loginType === LoginTypeEnum.CONSULTANT) {
                action = EventsActions.CONTENT_TYPES_VISITED_CONSULTANTS;
              }

              action = `${contentType.slug} ${action}`;
              const actionText =
                action.charAt(0).toUpperCase() + action.slice(1);
              TriggerGTMEvent({
                action: actionText,
                category: EventsCategories.PAGE_VIEWS,
                label: contentType.slug,
              });
            }
          });

          // Engagement Summary Dashboard
          const allContentTypes = contentTypesSelectedAux.map(type => {
            return type.title;
          });
          EngagementSummaryContentType({
            id: 0,
            pageURL: window.location.href,
            contentType: allContentTypes.join(', '),
            pageviews: 1,
            downloads: 0,
            indivisualUser: loginTypeContext === 1 ? 1 : 0,
            consultantUser: loginTypeContext === 2 ? 1 : 0,
          });

          ContentTypeEngagementTime({
            id: 0,
            userEmail: userEmail,
            pageURL: window.location.href,
            contentType: allContentTypes.join(', '),
            timeSpentInMinutes: 0,
            esContentTypeID: 0,
          });
        }
      } catch (error) {
        active.current && logger.debug('Fetch Search Data Error: ', error);
      }
    },
    [
      bestPracticesSelected,
      contentTypesSelected,
      numberOfitemsShown,
      resourceHub,
      topicsSelected,
      loginTypeContext,
      userEmail,
    ],
  );

  useEffect(() => {
    if (slug === '') {
      if (topicsSelected[0].title !== ALL_FILTERS) {
        setTopicsSelected([ALL_FILTERS_ELEMENT]);
      }
      if (path === RESOURCE_HUB_URL) {
        if (contentTypesSelected[0].title !== ALL_FILTERS) {
          setContentTypesSelected([ALL_FILTERS_ELEMENT]);
        }
      }
      if (bestPracticesSelected[0].title !== ALL_FILTERS) {
        setBestPracticesSelected([ALL_FILTERS_ELEMENT]);
      }
    }
  }, [
    slug,
    bestPracticesSelected,
    contentTypesSelected,
    setBestPracticesSelected,
    setContentTypesSelected,
    setTopicsSelected,
    topicsSelected,
  ]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    filters: {
      resourceHub,
      numberOfitemsShown,
      numTotalPages,
      topicsSelected,
      setTopicsSelected,
      bestPracticesSelected,
      setBestPracticesSelected,
      contentTypesSelected,
      setContentTypesSelected,
      fetchSearchData,
    },
  };
};
