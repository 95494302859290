import React, { useRef, useState } from 'react';

import Select from 'components/Inputs/Select';
import { SortByTypes } from 'pages/resource-hub/utils';

import styles from './styles.module.scss';

type FiltersSortByProps = {
  getSortByType: (value: SortByTypes) => void;
};

export const sortByOptions = [
  { label: 'Date (newest first)', value: 'publicationDate_desc' },
  { label: 'Date (oldest first)', value: 'publicationDate_asc' },
  { label: 'Number of likes', value: 'likes_desc' },
  { label: 'Read time (longest)', value: 'timeToRead_desc' },
  { label: 'Read time (shortest)', value: 'timeToRead_asc' },
  { label: 'Alphabetically (A-Z)', value: 'title_asc' },
  { label: 'Alphabetically (Z-A)', value: 'title_desc' },
  // { label: 'Author (A-Z)', value: 'author_asc' },
  // { label: 'Author (Z-A)', value: 'author_desc' },
];

const FiltersSortBy: React.FunctionComponent<FiltersSortByProps> = ({
  getSortByType,
}) => {
  const sortByDropdownRef = useRef(null);
  const [sortByValue, setSortByValue] = useState(SortByTypes.Date_DESC);

  const onChange = (event: any) => {
    event.preventDefault();
    setSortByValue(event.target.value);
    getSortByType(event.target.value);
  };

  return (
    <div className={styles.filtersSortBy}>
      <Select
        ariaLabel={'Sort By'}
        disabled={sortByOptions === undefined}
        forwardedRef={sortByDropdownRef}
        invalid={false}
        label={'Sort By'}
        name={'Sort By'}
        onChange={onChange}
        options={sortByOptions}
        readOnly={false}
        tabIndex={0}
        value={sortByValue}
      />
    </div>
  );
};

export default FiltersSortBy;
