import { useCallback, useEffect, useRef, useState } from 'react';
import { api, API } from 'modules/api';
import { Model } from '../modal/verifyChangePassword';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { ModalType } from 'modules/modals/constants';
import { CLIENT_EMAIL } from 'modules/utils/constants';

export const useChangePassword = () => {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);
  const active = useRef(true);

  const showErrorModal = useCallback(() => {
    errorModal.current = modalShow({
      onClose: () => {
        !!errorModal.current && modalHide(errorModal.current);
        errorModal.current = null;
      },
      title: 'Error',
      text: 'There was an error trying to send your data. Please try again later',
      type: ModalType.INFO,
    });
  }, [modalHide, modalShow]);

  const handleChangePassword = useCallback(
    async (data: Model) => {
      const obj = {
        password: data.password,
        code: data.token,
        email:
          data.email !== undefined || data.email !== null || data.email !== ''
            ? data.email
            : sessionStorage.getItem(CLIENT_EMAIL),
      };
      try {
        setIsApiSubmitting(true);
        const handleChangePasswordSuccess = await api(
          API.POST_CHANGE_PASSWORD(obj),
        );
        if (handleChangePasswordSuccess) {
          setIsApiSubmitting(false);
          setSuccess(handleChangePasswordSuccess.data.result);
        }
      } catch (error) {
        active.current && showErrorModal();
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    },
    [showErrorModal],
  );
  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleChangePassword,
    success,
    isApiSubmitting,
  };
};
