import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { AccountDataType, LoginTypeEnum } from 'modules/utils/types';
import {
  ACCOUNT_LABEL_CONSULTANT,
  ACCOUNT_LABEL_INDIVIDUAL,
  USER_ROLE_CONSULTANT,
  USER_ROLE_INDIVIDUAL,
} from 'modules/utils/constants';
import { LoginTypeContext } from 'modules/context';
import { useLoginToken } from 'modules/hooks';
import { useLapsedUserModal } from 'modules/modals/hooks/useLapsedUserModal';

type useAccountProps = {
  accountDataContext?: AccountDataType;
  handleCloseMenu: () => void;
  setToggleAccountMenu?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type AccountTypes = {
  label: string;
  value: LoginTypeEnum;
};

export const useAccount = ({
  accountDataContext,
  handleCloseMenu,
  setToggleAccountMenu,
}: useAccountProps) => {
  const { loginTypeContext, setLoginTypeContext } =
    useContext(LoginTypeContext);
  const history = useHistory();
  const { saveUserRoleCookie, logOutAndRedirection } = useLoginToken();
  const { displayLapsedUserModal } = useLapsedUserModal();

  const accountTypes = useMemo(() => {
    const accountTypesArray: AccountTypes[] = [];

    if (accountDataContext?.userRoles.includes(USER_ROLE_INDIVIDUAL)) {
      accountTypesArray.push({
        label: ACCOUNT_LABEL_INDIVIDUAL,
        value: LoginTypeEnum.INDIVIDUAL,
      });
    }

    if (accountDataContext?.userRoles.includes(USER_ROLE_CONSULTANT)) {
      accountTypesArray.push({
        label: ACCOUNT_LABEL_CONSULTANT,
        value: LoginTypeEnum.CONSULTANT,
      });
    }

    return accountTypesArray;
  }, [accountDataContext]);

  const handleCloseAccountMenu = useCallback(() => {
    setToggleAccountMenu && setToggleAccountMenu(false);
    handleCloseMenu();
  }, [handleCloseMenu, setToggleAccountMenu]);

  const handleChangeLoginType = useCallback(
    (loginType: LoginTypeEnum) => {
      // ToDo:
      if (
        loginType === LoginTypeEnum.INDIVIDUAL &&
        accountDataContext !== undefined &&
        accountDataContext !== null &&
        accountDataContext?.userRoles.includes(USER_ROLE_INDIVIDUAL) &&
        accountDataContext?.subscription?.status !== 'Active' &&
        accountDataContext?.subscription?.status !== 'InTrial' &&
        accountDataContext?.subscription?.status !== 'NonRenewing'
      ) {
        // displayLapsedUserModal();
        window.location.href = `https://www.peoplecert.org/en/overview`;
      }

      if (loginType !== loginTypeContext) {
        saveUserRoleCookie(loginType);
        setLoginTypeContext(loginType);
        handleCloseAccountMenu();
        history.push('/');
      }
    },
    [
      handleCloseAccountMenu,
      history,
      loginTypeContext,
      saveUserRoleCookie,
      setLoginTypeContext,
      accountDataContext,
      displayLapsedUserModal,
    ],
  );

  const handleLogOut = useCallback(() => {
    // handleCloseAccountMenu();
    logOutAndRedirection();
  }, [logOutAndRedirection]);

  return {
    accountTypes,
    handleCloseAccountMenu,
    handleChangeLoginType,
    handleLogOut,
    loginTypeContext,
  } as const;
};
