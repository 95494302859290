import React from 'react';

import { AchievementsTypes, BadgeQualifications } from '../../types';
import { BADGES_URL } from 'modules/utils/constants';
import { ButtonColour } from 'components/Button/constants';
import AchievementsItem from './AchievementsItem';
import CtaButton from 'components/Cta/Button';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type AchievementsProps = {
  achievements?: AchievementsTypes[];
  loaded: boolean;
};

const Achievements: React.FunctionComponent<AchievementsProps> = ({
  achievements,
  loaded,
}) => {
  const isDesignations =
    achievements &&
    achievements?.findIndex((el: AchievementsTypes) => !el.isCertificate) > -1;
  let uniqueAchievementsByKey;

  if (achievements) {
    // get achieved badges designation
    const achievedBadges = achievements.reduce(function (
      filtered: any,
      achievement,
    ) {
      const hasAchieved = achievement?.qualifications.filter(
        (a: BadgeQualifications) => a.achieved,
      ).length;
      const complete = hasAchieved === achievement.qualifications.length;

      if (complete) {
        filtered.push(achievement.designation);
      }
      return filtered;
    },
    []);

    // If Badges achieved then remove duplicates
    const achievedBadgesArray = achievements.filter(item =>
      achievedBadges.includes(item.designation),
    );

    const key = 'designation';
    const achievedBadgesByKey = [
      ...new Map(achievedBadgesArray?.map(item => [item[key], item])).values(),
    ];

    // Get non achieved badges
    const nonAchievedBadgesArray = achievements.filter(
      item => !achievedBadges.includes(item.designation),
    );

    // Merge unique achieved badges and all non achieved badges
    uniqueAchievementsByKey = [
      ...achievedBadgesByKey,
      ...nonAchievedBadgesArray,
    ];
  }

  return loaded ? (
    <React.Fragment>
      {uniqueAchievementsByKey &&
        uniqueAchievementsByKey.length > 0 &&
        isDesignations && (
          <section className={styles.achievements}>
            <h3>Your achievements</h3>
            <ul>
              {uniqueAchievementsByKey &&
                uniqueAchievementsByKey.map(
                  (achievement: AchievementsTypes, index: number) => (
                    <AchievementsItem
                      achievement={achievement}
                      key={`achievement-${index}`}
                    />
                  ),
                )}
            </ul>
            <div>
              <CtaButton
                defaultColour={ButtonColour.BRAND_BERRY}
                label="Go to Badges"
                page_link={BADGES_URL}
                small
              />
            </div>
          </section>
        )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Achievements;
