/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from 'react';

import * as Question from 'components/Question';
import styles from '../../styles.module.scss';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { model, schema } from '../modal/verifyEmailmodel';
import * as Button from 'components/Button';
import Questions from 'components/Questions';
import { useVerifyEmail } from '../hooks/useVerifyEmail';

// eslint-disable-next-line @typescript-eslint/ban-types
type VerifyEmailProps = {
  setActiveTab: Function;
  setReSubmitEmail: Function;
  setToken: Function;
  setEmail: Function;
};

// eslint-disable-next-line no-empty-pattern
const VerifyEmail: React.FunctionComponent<VerifyEmailProps> = ({
  setActiveTab,
  setReSubmitEmail,
  setToken,
  setEmail,
}) => {
  const { handleResetEmail, verificationCode, emailInput, isApiSubmitting } =
    useVerifyEmail();
  const defaultValues = {
    email: '',
  };

  useEffect(() => {
    if (verificationCode !== '') {
      setActiveTab('VerifiedOtp');
      setReSubmitEmail(emailInput);
      setToken(verificationCode);
      setEmail(emailInput);
    }
  }, [setActiveTab, verificationCode]);

  return (
    <div>
      <h2 className="email-title">Verify your email</h2>
      <div id="api" role="main">
        <div
          className="localAccount"
          aria-label="Sign in with your existing account"
          role="form"
        >
          <Questions
            defaultValues={defaultValues}
            enableReinitialize={false}
            onSubmit={handleResetEmail}
            schema={schema}
          >
            {({ control, isFormSubmitting }) => (
              <React.Fragment>
                <div className={styles.entry}>
                  <div className={styles.entryItemLeftButton}>
                    <label htmlFor="resetpassword">Email Address</label>
                    <Question.Text
                      control={control}
                      isSubmitting={isFormSubmitting}
                      question={model.email}
                      label=""
                    />
                    <div>
                      <button
                        type="submit"
                        className={
                          isApiSubmitting
                            ? styles.buttonLoadingVerifyEmail
                            : styles.buttonNewVerifyEmail
                        }
                        disabled={isApiSubmitting ? true : false}
                      >
                        <span className={styles.button__text}>
                          Send verification code&nbsp;
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Questions>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VerifyEmail);
