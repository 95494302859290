import React from 'react';

import BadgesItem from './BadgesItem';
import {
  BadgeLinksTypes,
  BadgesTypes,
  CertificateTitleTypes,
} from 'pages/badges/types';
import { BadgesType } from './constants';
import { ProgrammeType } from '../Programme/constants';
import { useClaimBadge } from '../../hooks/useClaimBadge';

type BadgesListProps = {
  badgeLinks?: BadgeLinksTypes;
  badges?: BadgesTypes[];
  badgesType: BadgesType;
  cancelPolling?: () => void;
  item: CertificateTitleTypes;
  isDesignation?: boolean;
  runPolling?: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void;
  setBadges?: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>;
  type: ProgrammeType;
};

const BadgesList: React.FunctionComponent<BadgesListProps> = ({
  badgeLinks,
  badges,
  badgesType,
  cancelPolling,
  item,
  isDesignation,
  runPolling,
  setBadges,
  type,
}) => {
  const links = badgeLinks?.filter(
    link => link.certificate_link[0]?.certificate_title === item.title,
  );
  // const itemBadgeData =
  //   badges && badges.filter(b => b?.systemName === item.uniqueTitle);
  const itemBadgeData =
    badges &&
    badges.filter(b => {
      const systemName = b?.systemName.replace(' Re-Registration', '');
      if (systemName === item.uniqueTitle) {
        return true;
      }
    });

  const { handleClaimBadge, isSubmitting } = useClaimBadge(
    cancelPolling,
    runPolling,
    setBadges,
    badges,
    itemBadgeData,
  );

  return type === ProgrammeType.ITIL ? (
    <BadgesItem
      achieved={itemBadgeData}
      badgesType={badgesType}
      findOutMoreLink={links}
      title={item.title}
      type={type}
      handleClaimBadge={handleClaimBadge}
      isSubmitting={isSubmitting}
    />
  ) : (
    <BadgesItem
      achieved={itemBadgeData}
      badgesType={badgesType}
      findOutMoreLink={links}
      title={item.title}
      type={type}
      handleClaimBadge={handleClaimBadge}
      isSubmitting={isSubmitting}
    />
  );
};

export default BadgesList;
