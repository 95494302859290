import { useState, useContext, useCallback, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { AccountDataContext } from 'modules/context';
import { API, api } from 'modules/api';
import {
  CLIENTID_COOKIE_NAME,
  CLIENT_ID_ITEM_NAME,
  COOOKIE_BANNER_COOKIE_NAME,
  mainUserRoles,
  REDIRECT_URL_COOKIE,
  ROLE_LABEL_CONSULTANT,
  ROLE_LABEL_EMPLOYEE,
  ROLE_LABEL_INDIVIDUAL,
  SIGN_UP_URL,
  TOKEN_PARAM_NAME,
  USER_ROLE_CONSULTANT,
  USER_ROLE_COOKIE_NAME,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_INDIVIDUAL,
} from 'modules/utils/constants';
import { getAccountData, logOutRedirection } from 'modules/utils/utils';
import { LoginTypeEnum, UserRolesKeys } from 'modules/utils/types';
import { removeOldPagesViewedCookies } from 'modules/utils/newContentUtils';
import { useCookieBanner } from 'modules/cookie-banner';
import { useLapsedUserModal } from 'modules/modals/hooks/useLapsedUserModal';
import { userRoleElement } from 'pages/auth/types';
import logger from 'modules/logger';
import { useNotifyOfFreeMonth } from 'pages/home/hooks/useNotifyOfFreeMonth';
import { useJSPeoplecertApplication } from 'modules/utils/PeoplecertExternalApplication';
import { domainCreation } from 'components/SSO/Signin/utils';

export const useLoginToken = () => {
  const application = useJSPeoplecertApplication();
  const [displayLoadingModal, setDisplayLoadingModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const [token, setToken] = useState<string | undefined>();
  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);
  const [cookie, setCookie, removeCookie] = useCookies<string>([
    USER_ROLE_COOKIE_NAME,
  ]);

  const { displayLapsedUserModal } = useLapsedUserModal();
  const active = useRef(true);

  const { showCookieBanner } = useCookieBanner();
  const { notifyOfFreeMonth } = useNotifyOfFreeMonth();

  const [cookieRedirectURL, , removeCookieRedirectURL] = useCookies<string>([
    REDIRECT_URL_COOKIE,
  ]);

  const isLoggedIn = useCallback(
    (): boolean =>
      sessionStorage.getItem(CLIENT_ID_ITEM_NAME) !== null &&
      sessionStorage.getItem(CLIENT_ID_ITEM_NAME) !== 'null' && // TODO - refactor this string null check
      sessionStorage.getItem(CLIENT_ID_ITEM_NAME) !== undefined &&
      sessionStorage.getItem(CLIENT_ID_ITEM_NAME) !== 'undefined' &&
      sessionStorage.getItem(CLIENT_ID_ITEM_NAME) !== '',
    [],
  );

  const setNewtoken = useCallback((newToken: string) => {
    setToken(newToken);
  }, []);

  const handleRedirectCookie = useCallback(() => {
    const cookieValue = cookieRedirectURL[REDIRECT_URL_COOKIE];
    removeCookieRedirectURL(REDIRECT_URL_COOKIE, { path: '/' });

    if (cookieValue || pathname === '/auth' || pathname === '/auth-reset') {
      const redirectURL = cookieValue ? cookieValue : '/';
      history.push(redirectURL);
    }
  }, [cookieRedirectURL, history, pathname, removeCookieRedirectURL]);

  //TODO done useCallback
  const postGetTokens = async (code: string): Promise<string | undefined> => {
    try {
      let endpoint = 'auth';
      if (pathname === '/auth-reset') {
        endpoint = 'auth/auth-reset';
      }
      const res = await api(API.POST_GET_TOKENS(code, endpoint));
      if (active.current) {
        sessionStorage.setItem(TOKEN_PARAM_NAME, res.data);
        return res.data;
      }
    } catch (error: any) {
      active.current && logOutRedirection();
    }
  };

  //TODO done useCallback
  const fetchAccountDataAndStoreToken = async (code: string): Promise<void> => {
    try {
      logOut();
      const tokenResponse = await postGetTokens(code);
      if (tokenResponse && active.current) {
        fetchAccountData(tokenResponse);
      }
    } catch (error) {
      active.current &&
        logger.debug('Fetch Data And Store Token Error: ', error);
    }
  };

  const displayLapsedSubscriptionScreen = (accountData: any) => {
    const redirectCookieValue: string = cookieRedirectURL[REDIRECT_URL_COOKIE];
    const regExpSignup = new RegExp(/\/signup\?*/);

    const rolesAvailable = accountData?.userRoles?.filter((userRole: string) =>
      mainUserRoles?.includes(userRole),
    );

    if (
      !rolesAvailable.includes('AXELOS Consultant') &&
      !window.location.href.includes('/signup') &&
      accountData !== undefined &&
      accountData !== null &&
      (accountData?.subscription === null ||
        accountData?.subscription === 'null' ||
        accountData?.redirectToSubscribePage === true)
    ) {
      displayLapsedUserModal();
    }

    if (
      rolesAvailable.length === 0 &&
      ((redirectCookieValue && !regExpSignup.test(redirectCookieValue)) ||
        (!redirectCookieValue && pathname !== SIGN_UP_URL))
    ) {
      //if the redirectCookieValue is signup we don't display the screen
      //as that means the user is buying a new subscription
      setDisplayLoadingModal(true);
      displayLapsedUserModal();
    }

    if (
      ((redirectCookieValue && !regExpSignup.test(redirectCookieValue)) ||
        (!redirectCookieValue && pathname !== SIGN_UP_URL)) &&
      !rolesAvailable.includes('AXELOS Consultant') &&
      accountData !== undefined &&
      accountData !== null &&
      accountData?.subscription?.status !== 'Active' &&
      accountData?.subscription?.status !== 'InTrial' &&
      accountData?.subscription?.status !== 'NonRenewing'
    ) {
      setDisplayLoadingModal(true);
      displayLapsedUserModal();
    }
  };

  //TODO done useCallback
  const fetchAccountData = async (tokenResponse?: string): Promise<void> => {
    if (!accountDataContext) {
      try {
        const tokenAux =
          tokenResponse ?? (sessionStorage.getItem(TOKEN_PARAM_NAME) || '');
        const accountData = await getAccountData(tokenAux);
        if (active.current) {
          if (accountData) {
            // Check if user has only Individual role, Then Redirect to PeopleCert
            const rolesAvailable = accountData?.userRoles.filter(
              (userRole: string) => mainUserRoles.includes(userRole),
            );
            if (
              rolesAvailable.length === 1 &&
              rolesAvailable.includes(USER_ROLE_INDIVIDUAL)
            ) {
              window.location.href = `https://www.peoplecert.org/en/overview`;
            } else {
              //show free months message
              notifyOfFreeMonth(accountData?.userId, accountData?.email);
              displayLapsedSubscriptionScreen(accountData);
              setAccountDataContext(accountData);
              setToken(tokenAux);
            }
          }
          // removeOldPagesViewedCookies(accountData.userId);
        } else {
          // logOutRedirection();
          console.log('accountData error', accountData);
        }
      } catch (error) {
        active.current && logger.debug('Get Account Data Error: ', error);
      } finally {
        setIsLoaded(true);
      }
    }
    handleRedirectCookie();
  };

  const getUserRolesDisplayed = useCallback((): userRoleElement[] => {
    const arrayRet = [];
    if (accountDataContext?.userRoles?.includes(USER_ROLE_INDIVIDUAL)) {
      arrayRet.push({
        key: UserRolesKeys[ROLE_LABEL_INDIVIDUAL],
        label: ROLE_LABEL_INDIVIDUAL,
      });
    }

    if (accountDataContext?.userRoles?.includes(USER_ROLE_CONSULTANT)) {
      arrayRet.push({
        key: UserRolesKeys[ROLE_LABEL_CONSULTANT],
        label: ROLE_LABEL_CONSULTANT,
      });
    }

    if (accountDataContext?.userRoles?.includes(USER_ROLE_EMPLOYEE)) {
      arrayRet.push({
        key: UserRolesKeys[ROLE_LABEL_EMPLOYEE],
        label: ROLE_LABEL_EMPLOYEE,
      });
    }

    return arrayRet;
  }, [accountDataContext]);

  const onlyOneRoleAvailable = useCallback((): boolean => {
    return getUserRolesDisplayed().length === 1;
  }, [getUserRolesDisplayed]);

  const saveOnlyAvailableUserRole = (): LoginTypeEnum => {
    const userRolesDisplayed = getUserRolesDisplayed()[0];
    removeCookie(USER_ROLE_COOKIE_NAME, { path: '/' });
    setCookie(USER_ROLE_COOKIE_NAME, userRolesDisplayed.key, {
      path: '/',
    });

    return userRolesDisplayed.key;
  };
  const userRoleCookie = (): LoginTypeEnum | undefined => {
    const cookieValue = parseInt(cookie[USER_ROLE_COOKIE_NAME]);

    if (
      getUserRolesDisplayed().some((element: userRoleElement) => {
        return element.key === cookieValue;
      })
    ) {
      return cookieValue;
    }
  };

  const saveUserRoleCookie = (value: LoginTypeEnum) => {
    removeCookie(USER_ROLE_COOKIE_NAME, { path: '/' });
    setCookie(USER_ROLE_COOKIE_NAME, value, { path: '/' });
  };

  const logOut = useCallback(() => {
    sessionStorage.clear();

    removeCookie(USER_ROLE_COOKIE_NAME, {
      path: '/',
      domain: '.axelos.com',
    });
    removeCookie(CLIENTID_COOKIE_NAME, {
      path: '/',
      domain: '.axelos.com',
    });
    removeCookie(COOOKIE_BANNER_COOKIE_NAME, {
      path: '/',
      domain: '.axelos.com',
    });
  }, []);

  //TODO done useCallback
  const logOutAndRedirection = useCallback(async () => {
    try {
      logOut();
      domainCreation('');

      setTimeout(() => {
        window.location.href = `/sign-in`;
        api(API.GET_AXELOS_SSO(''));
      }, 5000);

      // await application.manageDomainCookies('remove', [], '', true);
      // window.location.href = `/sign-in`;
      // history.push('/sign-in');

      // await api(API.GET_SSO('')).then(res => {
      //   window.location.href = `/sign-in`;
      //   // history.push('/sign-in');
      // });
    } catch (error: any) {
      active.current && logger.error('Error while logging out', error);
      window.location.href = `/sign-in`;
    }
  }, [logOut]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    displayLoadingModal,
    isLoggedIn,
    fetchAccountDataAndStoreToken,
    fetchAccountData,
    onlyOneRoleAvailable,
    saveOnlyAvailableUserRole,
    userRoleCookie,
    getUserRolesDisplayed,
    saveUserRoleCookie,
    logOut,
    token,
    setNewtoken,
    logOutAndRedirection,
    setDisplayLoadingModal,
    isLoaded,
  };
};
