import { useCallback, useState } from 'react';

import { defaultValues, Model } from '../../QuestionSet/model';
import { countries } from 'modules/countries';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';
import useSubscribeUser from 'pages/sign-up/hooks/useSubscribeUser';
import { isSubscriptionUpgrade } from 'pages/account-management/components/Subscriptions/Subscription/useSubscriptions';

type UseSignUpWithPaymentProps = {
  candidateToken: string;
  peopleCertDefaultValues: Model;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
};

function useSignUpWithPayment({
  candidateToken,
  peopleCertDefaultValues,
  subscriptionPlan,
  subscriptionState,
}: UseSignUpWithPaymentProps) {
  const { subscribeNewUser, isSubmitting } = useSubscribeUser();
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState<Model>(
    subscriptionState === SubscriptionStateEnum.PeopleCert_User ||
      subscriptionPlan?.isExistInPeoplecert === true
      ? { ...defaultValues, ...peopleCertDefaultValues }
      : defaultValues,
  );

  let convertedCountryName = '';
  if (
    subscriptionPlan?.isExistInPeoplecert !== null &&
    subscriptionPlan?.isExistInPeoplecert !== undefined &&
    subscriptionPlan?.isExistInPeoplecert === true &&
    subscriptionPlan?.countryCode !== null &&
    subscriptionPlan?.countryCode !== undefined
  ) {
    countries?.forEach(item => {
      const country = subscriptionPlan?.countryCode;

      if (country != null) {
        if (item.label.toLowerCase() === country.toLowerCase()) {
          // eslint-disable-next-line no-console
          console.log(
            `item.label : ${item.label}, subscriptionPlan?.countryCode : ${subscriptionPlan?.countryCode}`,
          );
          convertedCountryName = item.value;
          return;
        }
      }
    });
  }

  const pcDefaultValues = {
    candidateNumber: '',
    candidateToken:
      subscriptionPlan?.candidateToken !== null &&
      subscriptionPlan?.candidateToken !== undefined
        ? subscriptionPlan?.candidateToken
        : '',
    countryCode:
      subscriptionPlan?.countryCode !== null &&
      subscriptionPlan?.countryCode !== undefined
        ? subscriptionPlan?.countryCode.length > 2
          ? convertedCountryName
          : subscriptionPlan?.countryCode
        : '',
    email:
      subscriptionPlan?.email !== null && subscriptionPlan?.email !== undefined
        ? subscriptionPlan?.email
        : '',
    givenName:
      subscriptionPlan?.givenName !== null &&
      subscriptionPlan?.givenName !== undefined
        ? subscriptionPlan?.givenName
        : '',
    requirePayment: 'N',
    subscriptionPlanId:
      subscriptionPlan?.subscriptionPlanId !== null &&
      subscriptionPlan?.subscriptionPlanId !== undefined
        ? subscriptionPlan?.subscriptionPlanId
        : '',
    surname:
      subscriptionPlan?.surname !== null &&
      subscriptionPlan?.surname !== undefined
        ? subscriptionPlan?.surname
        : '',
    confirmEmail:
      subscriptionPlan?.email !== null && subscriptionPlan?.email !== undefined
        ? subscriptionPlan?.email
        : '',
    consent: false,
    paymentMethodId: '',
  };

  const handleBack = useCallback(() => {
    window.scrollTo(0, 0);
    setActiveStep(1);
  }, []);

  //TODO done useCallback
  const handleSubmitStep2 = useCallback(
    async (data: Model & { nonce: string }) => {
      console.log('upgrade', isSubscriptionUpgrade);
      await subscribeNewUser(
        {
          candidateNumber: data.candidateNumber,
          candidateToken: candidateToken,
          countryCode: data.countryCode,
          couponCode: subscriptionPlan?.couponCode,
          email: data.email,
          givenName: data.givenName,
          paymentNonce: data.nonce,
          subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
          surname: data.surname,
          isSubscriptionUpgrade: isSubscriptionUpgrade,
        },
        subscriptionState,
        subscriptionPlan?.subscriptionPlanId,
      );
    },
    [candidateToken, subscribeNewUser, subscriptionPlan, subscriptionState],
  );

  return {
    activeStep,
    defaultValues:
      subscriptionPlan?.isExistInPeoplecert !== null &&
      subscriptionPlan?.isExistInPeoplecert !== undefined &&
      subscriptionPlan?.isExistInPeoplecert === true
        ? pcDefaultValues
        : formData,
    handleBack,
    handleSubmitStep2,
    isSubmitting,
    setActiveStep,
    setFormData,
  };
}

export default useSignUpWithPayment;
