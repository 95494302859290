import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  AchievementsTypes,
  CandidateIdTypes,
  ClaimStateTypes,
  DashboardLoadedType,
  DashboardType,
  useContentTypesType,
  useEventsType,
} from './types';
import {
  BadgesCandidateIdTypes,
  BadgesPageType,
  BadgesTypes,
} from 'pages/badges/types';
import { EMPLOYEE_CONTENT_TYPE_FILTER } from 'modules/utils/constants';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum, NotificationItem } from 'modules/utils/types';
import { MaturityModelCtaType } from 'components/MaturityModels/types';
import { ResourceHubItemType } from '../../components/FeaturedResources/types';
import * as Layout from 'components/Layout';
import Achievements from './components/Achievements';
import Badges from './components/Badges';
import ContentTypesList from 'components/ContentTypeList';
import Events from './components/Events';
import FeaturedResources from 'components/FeaturedResources';
import KeyTemplates from 'components/KeyTemplates';
import MaturityModels from 'components/MaturityModels';
import Notifications from './components/Notifications';
import Overview from 'pages/badges/components/Overview';
import EarnedBadges from 'pages/badges/components/EarnedBadges';
import AddCandidate from 'pages/badges/components/AddCandidate';
import CandidateIds from 'pages/badges/components/CandidateIds';
import { BadgeStatus } from 'pages/badges/components/Badges/constants';
import CPDCertificates from 'pages/cpd/components/CPDCertificates';
import { dummyCertificates } from 'pages/cpd/types';
import { API, api } from 'modules/api';
import { AccountDataContext } from 'modules/context';
import logger from 'modules/logger';

export let openValue = '';

type TemplateProps = {
  achievements?: AchievementsTypes[];
  homeBadges?: BadgesTypes[];
  badges: BadgesPageType;
  candidateIds?: CandidateIdTypes[];
  contentTypes?: useContentTypesType;
  claimStates: ClaimStateTypes;
  dashboard?: DashboardType;
  events?: useEventsType;
  featuredResources?: ResourceHubItemType[];
  isEmployee: boolean;
  keyTemplates?: ResourceHubItemType[];
  loaded: DashboardLoadedType;
  maturityModelsUrl: MaturityModelCtaType;
  notifications: NotificationItem[];
  updateBadges: () => Promise<void>;
  setCandidateIds: React.Dispatch<
    React.SetStateAction<BadgesCandidateIdTypes[] | undefined>
  >;
  cancelPolling: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  achievements,
  homeBadges,
  badges,
  candidateIds,
  contentTypes,
  claimStates,
  dashboard,
  events,
  featuredResources,
  isEmployee,
  keyTemplates,
  loaded,
  maturityModelsUrl,
  notifications,
  updateBadges,
  setCandidateIds,
  cancelPolling,
}) => {
  const { loginTypeContext } = useContext(LoginTypeContext);

  let hasBadgesToClaim = 0;
  if (homeBadges !== undefined && homeBadges.length > 0) {
    hasBadgesToClaim =
      homeBadges.filter(claim => claim.status === BadgeStatus.BADGE_CLAIMABLE)
        .length > 0
        ? homeBadges.filter(
            claim => claim.status === BadgeStatus.BADGE_CLAIMABLE,
          ).length
        : 0;
  }

  const [hasCertificates, setHasCertificates] = useState(false);
  const [CPDTrackRecords, setCPDTrackRecord] = useState([]);
  const { accountDataContext } = useContext(AccountDataContext);
  const [isFetchData, setIsFetchData] = useState(false);
  const userId =
    accountDataContext?.userId !== undefined ? accountDataContext?.userId : 0;

  const history = useHistory();

  const handleAccordion = (value: any, type: string) => {
    console.log('type > value', type, value);
    switch (type) {
      case 'ITIL 4':
        openValue = type;
        history.push('/cpd');
        break;

      case 'RESILIA':
        openValue = type;
        history.push('/cpd');
        break;

      case 'AgileShift':
        openValue = type;
        history.push('/cpd');
        break;

      case 'Project, Programme & Portfolio Management':
        openValue = type;
        history.push('/cpd');
        break;
    }
  };

  useEffect(() => {
    try {
      api(API.GET_CPD_PRODUCT_SUITE_INFO(userId))
        .then(res => {
          setCPDTrackRecord(res?.data);
          if (res?.data.length > 0) {
            setHasCertificates(true);
          }
        })
        .catch(error => {
          logger.error('Get GET_CPD_PRODUCT_SUITE_INFO Error', error);
        });
    } catch (error) {
      logger.error('Error Getting CPD Product Suite Info', error);
    }
  }, [userId, isFetchData]);

  const certificatesList = CPDTrackRecords?.map((item: any) => {
    item.cpdSuiteProgress.expiryDate = item?.expiryDate;

    return item.cpdSuiteProgress;
  });

  // useEffect(() => {
  //   let result = false;
  //   certificatesList.forEach(record => {
  //     if (record.cpdStatus === 'Eligible') result = true;
  //     setHasCertificates(result);
  //   });
  // });

  const handleClick = (e: any, index: any) => {
    return { e, index };
  };
  return (
    <Layout.Container>
      <Layout.Content>
        {loginTypeContext === LoginTypeEnum.INDIVIDUAL &&
          notifications
            .filter(
              (notification: NotificationItem) => !notification.isDismissed,
            )
            .map((notification: NotificationItem) => (
              <Notifications
                buttonLabel={notification.buttonLabel}
                date={notification.date}
                id={notification.id}
                key={`notification-${notification.id}`}
                message={notification.message}
                type={notification.type}
              />
            ))}
        <FeaturedResources
          data={featuredResources}
          loaded={loaded.featuredResources}
          title={dashboard?.featured_resources?.label}
        />
        <ContentTypesList
          buttonLabel={`Go to ${dashboard?.content_types?.label}`}
          contentTypeName={
            isEmployee ? EMPLOYEE_CONTENT_TYPE_FILTER : contentTypes?.slug
          }
          data={contentTypes?.entries}
          loaded={loaded.contentTypes}
          title={contentTypes?.title}
        />
        <Events
          events={events?.entries}
          featuredEvent={events?.featured}
          isEmployee={isEmployee}
          loaded={loaded.events}
          title={dashboard?.events?.label}
        />
      </Layout.Content>
      <Layout.Sidebar>
        {hasCertificates && loginTypeContext === LoginTypeEnum.INDIVIDUAL && (
          <CPDCertificates
            cpdCertificates={certificatesList}
            loaded={true}
            redirect={false}
            handleSidebarAccordion={handleAccordion}
          />
        )}
        {loginTypeContext === LoginTypeEnum.CONSULTANT && (
          <MaturityModels
            loaded={loaded.maturityModels}
            maturityModelsUrl={maturityModelsUrl}
            mm_section={dashboard?.mm_section}
          />
        )}
        {loginTypeContext === LoginTypeEnum.INDIVIDUAL && (
          <React.Fragment>
            {!badges.earned && (
              <Badges
                allCandidateIds={candidateIds}
                badges={homeBadges}
                claimStates={claimStates}
                loaded={loaded.badges && loaded.claim}
                updateBadges={updateBadges}
              />
            )}

            {badges.earned && (
              <>
                <Overview
                  badgesEarned={badges.earned}
                  candidateId={candidateIds}
                  loaded={loaded.badges}
                />
                <EarnedBadges
                  badges={badges.records?.filter(b => b?.claimed)}
                  badgesEarned={badges.earned}
                  candidateId={candidateIds}
                  loaded={loaded.badges}
                />
                <AddCandidate
                  candidateId={candidateIds}
                  cancelPolling={cancelPolling}
                  loaded={loaded.candidateId}
                  setCandidateIds={setCandidateIds}
                />
                <CandidateIds ids={candidateIds} loaded={loaded.candidateId} />
              </>
            )}

            <Achievements
              achievements={achievements}
              loaded={loaded.achievements}
            />
          </React.Fragment>
        )}
        <KeyTemplates
          keyTemplates={keyTemplates}
          loaded={loaded.keyTemplates}
          title={dashboard?.curate_key_templates?.label}
        />
      </Layout.Sidebar>
    </Layout.Container>
  );
};

export default Template;
