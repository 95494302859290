import { useCallback, useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { ContentTypesGetResponse } from 'modules/api/endpoints/content-types';
import { EMPLOYEE_CONTENT_TYPE_TITLE } from 'modules/utils/constants';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { ContentTypesType } from 'components/ContentTypeList/types';
import {
  DashboardLoadedType,
  DashboardType,
  useContentTypesType,
} from '../types';

export const useContentTypes = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [entries, setEntries] = useState<ContentTypesType[]>();
  const [slug, setSlug] = useState<string | undefined>('');
  const [title, setTitle] = useState<string | undefined>('');

  const handleContentType = useCallback(
    (response: ContentTypesGetResponse) => {
      if (dashboard && dashboard.content_types.show_content_type) {
        setEntries(response.data.entries);
        setSlug(dashboard.content_types.type[0]?.slug);
        if (loginTypeContext === LoginTypeEnum.EMPLOYEE) {
          setTitle(EMPLOYEE_CONTENT_TYPE_TITLE);
        } else {
          setTitle(dashboard.content_types?.label);
        }
      }
    },
    [dashboard, loginTypeContext],
  );

  useEffect(() => {
    if (dashboard && loginTypeContext) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          api(
            API.GET_DASHBOARD_CONTENT_TYPES({
              contentTypeUid: dashboard?.content_types.type[0]?.uid,
              keyTemplates: false,
              loginTypeContext,
              numberOfItems:
                dashboard?.content_types.number_items_displayed || 3,
            }),
          ).then(response => {
            active && handleContentType(response);
          });
        } catch (error) {
          active && logger.error('Get Content Types Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              contentTypes: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [dashboard, handleContentType, loginTypeContext, setLoaded]);

  useEffect(() => {
    let label = '';
    let action = '';

    if (loginTypeContext === LoginTypeEnum.CONSULTANT) {
      label = 'Login (Consultants)';
      action = EventsActions.LOGINS_CONSULTANTS;
    } else if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
      label = 'Login (Individuals)';
      action = EventsActions.LOGINS_INDIVIDUALS;
    }

    TriggerGTMEvent({
      action: action,
      category: EventsCategories.PAGE_VIEWS,
      label: label,
    });
  }, [loginTypeContext]);

  const contentTypes: useContentTypesType | undefined = {
    entries,
    slug,
    title,
  };

  return { contentTypes };
};
