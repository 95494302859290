import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { API, api } from 'modules/api';
import {
  getAccountData,
  getRefreshToken,
  getTokenExpDate,
  logInRedirection,
  logOutRedirection,
  protectedURL,
} from 'modules/utils/utils';
import { LoginTypeEnum } from 'modules/utils/types';
import {
  CLIENTID_COOKIE_NAME,
  CLIENT_ID_ITEM_NAME,
  LOG_OUT_TRIGGERED,
  mainUserRoles,
  REDIRECT_URL_COOKIE,
  ROLE_SELECT_SCREEN_CONTENT_ID,
  USER_ROLE_INDIVIDUAL,
  VERIFY_PAGE_URL,
} from 'modules/utils/constants';
import { ModalType } from 'modules/modals/constants';
import { useLapsedUserModal } from 'modules/modals/hooks/useLapsedUserModal';
import { useLoginToken } from 'modules/hooks';
import { userRoleDescription } from './types';
import * as Loading from 'components/Loading';
import AuthLanding from 'components/AuthLanding';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { CookieSetOptions } from 'pages/sign-up/hooks/types';
import { getUserRolesEntries } from './utils';
import { useJSPeoplecertApplication } from 'modules/utils/PeoplecertExternalApplication';

export const Auth: React.FunctionComponent = () => {
  const application = useJSPeoplecertApplication();
  const { search: params, pathname } = useLocation();
  const code = new URLSearchParams(params).get('code');
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext, setLoginTypeContext } =
    useContext(LoginTypeContext);
  const [cookieRedirectURL, setCookieRedirectURL] = useCookies<string>([
    REDIRECT_URL_COOKIE,
  ]);
  const [roleSelectScreenContent, setRoleSelectScreenContent] =
    useState<userRoleDescription[]>();
  const [displayLogo, setDisplayLogo] = useState(true);
  const { displayLapsedUserModal } = useLapsedUserModal();
  const history = useHistory();

  const {
    displayLoadingModal,
    isLoggedIn,
    fetchAccountDataAndStoreToken,
    fetchAccountData,
    onlyOneRoleAvailable,
    saveOnlyAvailableUserRole,
    userRoleCookie,
    getUserRolesDisplayed,
    saveUserRoleCookie,
    token,
    setNewtoken,
    setDisplayLoadingModal,
    isLoaded,
  } = useLoginToken();

  const [cookie, setCookie, removeCookie] = useCookies<string>([
    CLIENTID_COOKIE_NAME,
  ]);
  const sessionClientId = sessionStorage.getItem(CLIENT_ID_ITEM_NAME);
  const cookieClientId = cookie.clientid;

  useEffect(() => {
    if (
      loginTypeContext === LoginTypeEnum.INDIVIDUAL &&
      !window.location.href.includes('/signup') &&
      accountDataContext !== undefined &&
      accountDataContext !== null &&
      accountDataContext?.userRoles.includes(USER_ROLE_INDIVIDUAL)
      // accountDataContext?.subscription?.status !== 'Active' &&
      // accountDataContext?.subscription?.status !== 'InTrial' &&
      // accountDataContext?.subscription?.status !== 'NonRenewing'
    ) {
      // displayLapsedUserModal();
      window.location.href = `https://www.peoplecert.org/en/overview`;
    }
  }, [accountDataContext, displayLapsedUserModal, loginTypeContext]);

  useEffect(() => {
    // console.log(
    //   'sessionStorage.getItem(LOG_OUT_TRIGGERED) > ',
    //   sessionStorage.getItem(LOG_OUT_TRIGGERED),
    // );
    if (sessionStorage.getItem(LOG_OUT_TRIGGERED) !== 'logged_out') {
      const today = new Date();
      today.setMinutes(today.getMinutes() + 30);
      const cookieOptions: CookieSetOptions = {
        path: '/',
        maxAge: 3600,
        expires: today,
        domain: '.axelos.com',
        // sameSite: 'strict',
      };

      // If clientID exist in session storage Then Set 'clientid' in Cookies and vice-versa
      if (
        sessionClientId !== '' &&
        sessionClientId !== undefined &&
        sessionClientId !== 'undefined' && // TODO - refactor
        sessionClientId !== null
      ) {
        setCookie(CLIENTID_COOKIE_NAME, sessionClientId, cookieOptions);
        // application.manageDomainCookies('add', [], sessionClientId);
      } else if (
        cookieClientId !== '' &&
        cookieClientId !== undefined &&
        cookieClientId !== 'undefined' && // TODO - refactor
        cookieClientId !== null
      ) {
        sessionStorage.setItem(CLIENT_ID_ITEM_NAME, cookie.clientid);
        // application.manageDomainCookies('add', [], cookieClientId);
      }
    } else {
      // console.log(
      //   'Auth > LOG_OUT_TRIGGERED else >',
      //   sessionStorage.getItem(LOG_OUT_TRIGGERED),
      // );
      sessionStorage.setItem(LOG_OUT_TRIGGERED, '');
      window.location.href = `/sign-in`;
    }
  }, []);

  useEffect(() => {
    // if (code && !token) {
    //   fetchAccountDataAndStoreToken(code);
    // } else if (isLoggedIn()) {
    //   fetchAccountData();
    // } else if (!isLoggedIn() && protectedURL(pathname)) {
    //   setCookieRedirectURL(REDIRECT_URL_COOKIE, `${pathname}${params}`, {
    //     path: '/',
    //   });
    //   setDisplayLogo(false);
    //   logInRedirection();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // if (!isLoggedIn() && protectedURL(pathname)) {
    //   setCookieRedirectURL(REDIRECT_URL_COOKIE, `${pathname}${params}`, {
    //     path: '/',
    //   });
    //   setDisplayLogo(false);
    //   logInRedirection();
    // }

    if (isLoggedIn()) {
      fetchAccountData();
    } else if (
      pathname !== '/signup' &&
      pathname !== '/reset-password' &&
      pathname !== '/policies/terms-of-service' &&
      pathname !== '/policies/privacy-policy' &&
      pathname !== '/congratulations'
    ) {
      history.push('/sign-in');
    }
    setDisplayLoadingModal(true);
  }, []);

  //useEffect(() => {
  //TODO done useEffect
  // let active = true;
  // const fetchRefreshToken = async () => {
  //   try {
  //     const newToken = await getRefreshToken();
  //     if (active) {
  //       setNewtoken(newToken);
  //       const accountData = await getAccountData(newToken, true);

  //       if (accountData) {
  //         const rolesAvailable = accountData?.userRoles.filter(
  //           (userRole: string) => mainUserRoles.includes(userRole),
  //         );
  //         if (rolesAvailable.length === 0) {
  //           displayLapsedUserModal();
  //         }
  //       } else {
  //         logOutRedirection();
  //       }
  //     }
  //   } catch {
  //     active && logOutRedirection();
  //   }
  // };
  // if (token) {
  //   const tokenExpireDate = getTokenExpDate(token);
  //   const currentDate = Date.now();
  //   const offsetTimeout = 1000 * 60 * 5;

  //   const intervalTime = tokenExpireDate
  //     ? tokenExpireDate * 1000 - currentDate
  //     : 1000 * 60 * 25;

  //   if (intervalTime <= offsetTimeout) {
  //     fetchRefreshToken();
  //     return;
  //   }

  //     const timer = setTimeout(
  //       () => {
  //         fetchRefreshToken();
  //       },
  //       tokenExpireDate ? intervalTime - offsetTimeout : intervalTime,
  //     );

  //     return () => {
  //       active = false;
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [displayLapsedUserModal, setNewtoken, token]);

  const handleClickUserRole = useCallback(
    (value: LoginTypeEnum) => {
      // TODO: check if subscription is active for individual roles
      // else redirect to Subscription Expire (model) page
      if (
        value === LoginTypeEnum.INDIVIDUAL &&
        accountDataContext !== undefined &&
        accountDataContext !== null &&
        accountDataContext?.userRoles.includes(USER_ROLE_INDIVIDUAL)
        // accountDataContext?.subscription?.status !== 'Active' &&
        // accountDataContext?.subscription?.status !== 'InTrial' &&
        // accountDataContext?.subscription?.status !== 'NonRenewing'
      ) {
        // displayLapsedUserModal();
        window.location.href = `https://www.peoplecert.org/en/overview?ts=${Date.now()}`;
      } else if (value === LoginTypeEnum.CONSULTANT) {
        setLoginTypeContext(value);
        saveUserRoleCookie(value);
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      }
    },
    [
      accountDataContext,
      displayLapsedUserModal,
      modalHide,
      saveUserRoleCookie,
      setLoginTypeContext,
    ],
  );

  useEffect(() => {
    if (
      token &&
      !roleSelectScreenContent &&
      protectedURL(pathname) &&
      pathname !== '/auth' &&
      getUserRolesDisplayed().length > 1
    ) {
      const entities = getUserRolesEntries(accountDataContext?.userRoles);
      setRoleSelectScreenContent(entities);
      // let active = true;
      // (async () => {
      //   try {
      //     const res = await api(
      //       API.GET_CONTENT_BY_ID(
      //         `${ROLE_SELECT_SCREEN_CONTENT_ID}/user_roles`,
      //       ),
      //     );
      //     active && setRoleSelectScreenContent(res?.data.entries);
      //   } catch (error) {
      //     active && logger.debug('Get Content By ID Error: ', error);
      //   }
      // })();
      // return () => {
      //   active = false;
      // };
    }
  }, [getUserRolesDisplayed, pathname, roleSelectScreenContent, token]);

  useEffect(() => {
    if (accountDataContext) {
      const userRoleCookieValue = userRoleCookie();
      const onlyOneRole = onlyOneRoleAvailable();

      if (userRoleCookieValue) {
        setLoginTypeContext(userRoleCookieValue);
        setDisplayLoadingModal(true);
      } else if (onlyOneRole || pathname === VERIFY_PAGE_URL) {
        const roleAvailable = saveOnlyAvailableUserRole();
        setLoginTypeContext(roleAvailable);
        setDisplayLoadingModal(true);
      } else if (
        !modal.current &&
        accountDataContext &&
        roleSelectScreenContent &&
        isLoggedIn()
      ) {
        modal.current = modalShow({
          onClick: handleClickUserRole,
          type: ModalType.ROLES,
          userRoles: getUserRolesDisplayed(),
          rolesDescription: roleSelectScreenContent,
        });
        setDisplayLoadingModal(true);
      }
    }
  }, [
    accountDataContext,
    getUserRolesDisplayed,
    handleClickUserRole,
    isLoggedIn,
    modalHide,
    modalShow,
    onlyOneRoleAvailable,
    pathname,
    roleSelectScreenContent,
    saveOnlyAvailableUserRole,
    setDisplayLoadingModal,
    setLoginTypeContext,
    userRoleCookie,
  ]);

  return protectedURL(pathname) &&
    !isLoaded &&
    pathname !== '/sign-in' &&
    pathname !== '/reset-password' &&
    pathname !== '/congratulations' &&
    !pathname.includes('/resource-hub') ? (
    <div className="page-container">
      <AuthLanding />
      {displayLogo && <Loading.Modal />}
    </div>
  ) : null;
};
