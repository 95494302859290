import { CertificatesItilTitles, ItilDesignationsTitles } from './constants';

export const certificatesItil = [
  {
    category: 'ITIL FOUNDATION',
    certificates: [
      {
        title: CertificatesItilTitles.FOUNDATION,
        uniqueTitle: 'ITIL 4 Foundation',
      },
    ],
  },
  {
    category: 'ITIL SPECIALIST',
    certificates: [
      {
        title: CertificatesItilTitles.CREATE_DELIVER_SUPPORT,
        uniqueTitle: 'ITIL 4 Specialist: Create, Deliver & Support',
      },
      {
        title: CertificatesItilTitles.DRIVE_STAKEHOLDER_VALUE,
        uniqueTitle: 'ITIL 4 Specialist: Drive Stakeholder Value',
      },
      {
        title: CertificatesItilTitles.HIGH_VELOCITY_IT,
        uniqueTitle: 'ITIL 4 Specialist: High Velocity IT',
      },
      {
        title: CertificatesItilTitles.MONITOR_SUPPORT_AND_FULFIL,
        uniqueTitle: 'ITIL 4 Specialist: Monitor, Support and Fulfil',
      },
      {
        title: CertificatesItilTitles.PLAN_IMPLEMENT_AND_CONTROL,
        uniqueTitle: 'ITIL 4 Specialist: Plan, Implement and Control',
      },
      {
        title: CertificatesItilTitles.COLLABORATE_ASSURE_AND_IMPROVE,
        uniqueTitle: 'ITIL 4 Specialist: Collaborate, Assure and Improve',
      },
    ],
  },
  {
    category: 'ITIL STRATEGIST',
    certificates: [
      {
        title: CertificatesItilTitles.DIRECT_PLAN_IMPROVE,
        uniqueTitle: 'ITIL 4 Strategist: Direct, Plan & Improve',
      },
    ],
  },
  {
    category: 'ITIL LEADER',
    certificates: [
      {
        title: CertificatesItilTitles.DIGITAL_IT_STRATEGY,
        uniqueTitle: 'ITIL 4 Leader: Digital and IT Strategy',
      },
    ],
  },
  {
    category: 'ITIL EXTENSION MODULES',
    certificates: [
      {
        title: CertificatesItilTitles.ACQUIRING_MANAGING_CLOUD_SERVICES,
        uniqueTitle: 'ITIL 4 Specialist: Acquiring & Managing Cloud Services',
      },
      {
        title: CertificatesItilTitles.SUSTAINABILITY_IN_DIGITAL_IT,
        uniqueTitle: 'ITIL 4 Specialist: Sustainability in Digital & IT',
      },
      {
        title: CertificatesItilTitles.BRM,
        uniqueTitle: 'ITIL 4 Specialist: Business Relationship Management',
      },
      {
        title: CertificatesItilTitles.ITAM,
        uniqueTitle: 'ITIL 4 Specialist: IT Asset Management',
      },
    ],
  },
  // {
  //   category: 'MANAGING PROFESSIONAL TRANSITION',
  //   certificates: [
  //     {
  //       title: CertificatesItilTitles.MPT,
  //       uniqueTitle: 'Managing Professional Transition',
  //     },
  //   ],
  // },
  {
    category: 'ITIL PRACTICE MANAGER',
    certificates: [
      {
        title: CertificatesItilTitles.INCIDENT_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Incident Management',
      },
      {
        title: CertificatesItilTitles.SERVICE_DESK,
        uniqueTitle: 'ITIL 4 Practitioner: Service Desk',
      },
      {
        title: CertificatesItilTitles.SERVICE_REQUEST_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Service Request Management',
      },
      {
        title: CertificatesItilTitles.MONITORING_AND_EVENT_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Monitoring and Event Management',
      },
      {
        title: CertificatesItilTitles.PROBLEM_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Problem Management',
      },
      {
        title: CertificatesItilTitles.IT_ASSET_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: IT Asset Management',
      },
      {
        title: CertificatesItilTitles.CHANGE_ENABLEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Change Enablement',
      },
      {
        title: CertificatesItilTitles.DEPLOYMENT_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Deployment Management',
      },
      {
        title: CertificatesItilTitles.RELEASE_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Release Management',
      },
      {
        title: CertificatesItilTitles.SERVICE_CONFIGURATION_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Service Configuration Management',
      },
      {
        title: CertificatesItilTitles.CONTINUAL_IMPROVEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Continual Improvement',
      },
      {
        title: CertificatesItilTitles.SERVICE_LEVEL_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Service Level Management',
      },
      {
        title: CertificatesItilTitles.RELATIONSHIP_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Relationship Management',
      },
      {
        title: CertificatesItilTitles.INFORMATION_SECURITY_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Information Security Management',
      },
      {
        title: CertificatesItilTitles.SUPPLIER_MANAGEMENT,
        uniqueTitle: 'ITIL 4 Practitioner: Supplier Management',
      },
    ],
  },
];

export const itilDesignations = [
  {
    title: ItilDesignationsTitles.MANAGING_PROFESSIONAL,
    uniqueTitle: 'ITIL 4 Managing Professional',
  },
  {
    title: ItilDesignationsTitles.STRATEGIC_LEADER,
    uniqueTitle: 'ITIL 4 Strategic Leader',
  },
  {
    title: ItilDesignationsTitles.PRACTICE_MANAGER,
    uniqueTitle: 'ITIL 4 Practice Manager',
  },
  {
    title: ItilDesignationsTitles.ITIL_4_MASTER,
    uniqueTitle: 'ITIL 4 Master',
  },
];
