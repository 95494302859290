import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { model, schema } from './model';
import { useCpdTypes } from './hooks/useCpdTypes';
import { useSubmitCpd } from './hooks/useSubmitCpd';
import { useProgrammeTypes } from './hooks/useProgrammeTypes';
import * as Button from 'components/Button';
import * as Question from 'components/Question';
import Fieldset from 'components/Fieldset';
import QuestionDate from 'components/QuestionDate';
import Questions from 'components/Questions';

import formModalStyles from 'components/Modal/Form/styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import styles from './styles.module.scss';
import { cpdFormHelpText } from './types';
import AddFile from 'pages/cpd/components/AddFile';
import { AccountDataContext } from 'modules/context';

type CpdFormProps = {
  examDateTaken: string;
  certificateId: string;
  modalRef: MutableRefObject<string | null>;
  onCancel: () => void;
  onDelete: (id: number) => void;
  pointsOptions: {
    label: string;
    value: string;
  }[];
  title: string;
  currentProductSuiteId?: number;
  isCurrentCPDYear?: boolean;
  setIsFetchDataCallback: (val: boolean) => void;
  editFormValues?: any;
};

const CpdForm: React.FunctionComponent<CpdFormProps> = ({
  certificateId,
  examDateTaken,
  modalRef,
  onCancel,
  onDelete,
  pointsOptions,
  title,
  currentProductSuiteId,
  isCurrentCPDYear,
  setIsFetchDataCallback,
  editFormValues,
}) => {
  const { accountDataContext } = useContext(AccountDataContext);
  const userId =
    accountDataContext?.userId !== undefined ? accountDataContext?.userId : 0;
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isFilePicked, setIsFilePicked] = useState<boolean>(false);

  const initialCertificates =
    editFormValues === undefined
      ? []
      : editFormValues.cpdClaimProductSuiteModel.certificates;
  const initialRecords = [
    {
      productSuiteId: 0,
      productSuiteName: '',
      certificates: initialCertificates,
    },
  ];
  const [selectedRecords, setSelectedRecords] = useState(initialRecords);
  const [selectedCertificates, setSelectedCertificates] =
    useState(initialCertificates);

  const [fetchCpdTypes, isLoading, cpdTypesOptions] = useCpdTypes();
  const [fetchingData, setFetchingData] = useState(true);
  const { handleSubmit, isApiSubmitting, refreshData } = useSubmitCpd(
    modalRef,
    selectedFiles,
    selectedRecords,
    editFormValues?.id,
  );

  const [refreshResults, setRefreshResults] = useState(false);
  const [programmeTypes, programmeSelectionType, loadData] =
    useProgrammeTypes(userId);

  const calcMinDate = useMemo(() => {
    const dateTaken = dayjs(examDateTaken);
    const today = dayjs();
    let cpdPointsYearStart = dateTaken;
    while (cpdPointsYearStart.add(1, 'year') < today) {
      cpdPointsYearStart = cpdPointsYearStart.add(1, 'year');
    }
    return cpdPointsYearStart.toISOString();
  }, [examDateTaken]);

  const defaultValue1 = {
    cpdType: '',
    description: '',
    claimedPoints: '',
    completionDate: '',
    whatDidYouLearn: '',
    certificateClaimingFor: '',
    certificateId: certificateId || '',
    minDate: calcMinDate || '',
  };
  const editDefaultValue = {
    cpdType: editFormValues?.type,
    //cpdType: editFormValues?.claimType,
    description: editFormValues?.description,
    claimedPoints: editFormValues?.points,
    completionDate: editFormValues?.completionDate,
    whatDidYouLearn: editFormValues?.comment,
    certificateClaimingFor: editFormValues?.cpdClaimProductSuiteModel,
    certificateId: certificateId,
    minDate: calcMinDate || '',
  };
  const defaultValue =
    editFormValues?.id === 0 ? defaultValue1 : editDefaultValue;

  useEffect(() => {
    if (fetchingData) {
      setFetchingData(false);
      programmeTypes();
      fetchCpdTypes();
    }
  }, [
    fetchCpdTypes,
    programmeTypes,
    programmeSelectionType,
    cpdTypesOptions,
    isLoading,
    loadData,
    fetchingData,
  ]);

  useEffect(() => {
    if (editFormValues != null) {
      const uploadedFiles: File[] = [];
      editFormValues.cpdDocumentDetails.forEach(
        (document: { fileName: string; type: any }) => {
          const fileToBeAddedToTheArray = new File(
            [editFormValues.cpdDocumentDetails],
            document.fileName,
            { type: document.type },
          );
          uploadedFiles.push(fileToBeAddedToTheArray);
        },
      );
      setSelectedFiles([...uploadedFiles]);
      setIsFilePicked(true);
    }

    if (editFormValues !== undefined) {
      setSelectedRecords(current => [
        {
          productSuiteId:
            editFormValues?.cpdClaimProductSuiteModel?.productSuiteId,
          productSuiteName:
            editFormValues?.cpdClaimProductSuiteModel?.productSuiteName,
          certificates: editFormValues?.cpdClaimProductSuiteModel?.certificates,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonClassNames = clsx(
    styles.buttons,
    modalStyles.buttons,
    formModalStyles.buttons,
  );

  const handleSelectedOption = (selectedValues: any) => {
    // console.log('selectedValues', selectedValues);

    let isExist = false;
    selectedRecords[0].certificates.forEach((cert: any) => {
      if (
        cert.qualificationName ===
        selectedValues.certificates[0].qualificationName
      ) {
        isExist = true;
      }
    });
    //.............................................................................
    if (!isExist) {
      setSelectedCertificates((current: any) => [
        ...current,
        ...selectedValues.certificates,
      ]);

      setSelectedRecords([
        {
          productSuiteId: selectedValues.productSuiteId,
          productSuiteName: selectedValues.productSuiteName,
          certificates: [
            ...selectedCertificates,
            ...selectedValues.certificates,
          ],
        },
      ]);
    }
  };

  // console.log(
  //   'selectedRecords[0].certificates',
  //   selectedRecords[0].certificates,
  // );

  // console.log('selectedRecords', selectedRecords);

  const removeCertificate = (event: any, index: any) => {
    // console.log('removeCertificate > event', event?.target?.dataset?.value);
    const certToBeRemoved = selectedRecords[0].certificates.splice(index, 1);
    const newCerts = selectedRecords[0].certificates.filter(
      (cert: any) => cert !== certToBeRemoved,
    );
    setSelectedCertificates(() => newCerts);
    setRefreshResults(true);
    event.target.dataset.value = '';
  };

  useEffect(() => {
    setIsFetchDataCallback(refreshData);
  }, [refreshData]);

  useEffect(() => {
    if (refreshResults) {
      setRefreshResults(false);
    }
  }, [refreshResults, setRefreshResults]);

  useEffect(() => {
    setIsFetchDataCallback(refreshData);
  }, [refreshData]);

  return (
    <div className={styles.cpdForm}>
      <Questions
        enableReinitialize={false}
        defaultValues={defaultValue}
        onSubmit={handleSubmit}
        schema={schema}
      >
        {({ control, isFormSubmitting }) => (
          <Fieldset title={title} cpdForm>
            <Question.Select
              control={control}
              readOnly={!isCurrentCPDYear}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="Certificate(s) I'm claiming for"
              options={programmeSelectionType}
              question={model.certificateClaimingFor}
              help={
                <span>
                  Choose which certification(s) you would like to submit this
                  CPD for. You can choose one or more certifications, and your
                  submission will be applied to each.
                </span>
              }
              showHelpOnClick={true}
              isNested={true}
              nestedData={programmeSelectionType}
              currentProductSuiteId={currentProductSuiteId}
              selectBoxDataCallback={handleSelectedOption}
            />

            {selectedRecords?.map((item: any, index) => {
              if (item?.productSuiteId !== 0) {
                return (
                  <div
                    key={`certificate-${index}`}
                    className={styles.selectedCertificates}
                  >
                    <p className={styles.certificatesGroupName}>
                      {item?.productSuiteName}
                    </p>

                    <div className={styles.certificatesContainer}>
                      {selectedCertificates?.map(
                        (certificate: any, certIndex: number) => {
                          return (
                            <div
                              key={`certificatesName-${certIndex}`}
                              className={styles.certificatesName}
                            >
                              <span>{certificate.qualificationName}</span>
                              {isCurrentCPDYear && (
                                <span
                                  key={certIndex}
                                  onClick={event =>
                                    removeCertificate(event, certIndex)
                                  }
                                  className={styles.close}
                                  data-value={certificate.qualificationName}
                                >
                                  x
                                </span>
                              )}
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                );
              }

              return null;
            })}

            <Question.Select
              control={control}
              readOnly={!isCurrentCPDYear}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label={'Type of CPD'}
              options={cpdTypesOptions}
              question={model.cpdType}
              help={
                <span>
                  Which CPD category does your submission fall into? You can
                  find detailed descriptions of each category{' '}
                  <a
                    target="_blank"
                    href="https://my.axelos.com/resource-hub/article/continuous-professional-development#7-what-are-the-categories-of-cpd?"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  . You need to submit at least 5 points of Professional
                  Experience, and a further 15 points of any other category each
                  year.
                </span>
              }
              showHelpOnClick={true}
              className={styles.typeOfCPD}
            />
            <div className={styles.cpdTextarea}>
              <Question.Textarea
                readOnly={!isCurrentCPDYear}
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Description"
                question={model.description}
                help={cpdFormHelpText.DESCRIPTION.toString()}
                showHelpOnClick={true}
              />
            </div>
            <Question.Text
              control={control}
              readOnly={!isCurrentCPDYear}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="Points I'm claiming"
              handleRoundValue={true}
              question={model.claimedPoints}
              help={
                <span>
                  This is how many points you are claiming for this activity.
                  You can find further guidance on how to judge how many points
                  to claim{' '}
                  <a
                    target="_blank"
                    href="https://my.axelos.com/resource-hub/article/continuous-professional-development#6-how-many-cpd-points-is-an-activity-worth?"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  . You need to submit at least 5 points of Professional
                  Experience, and a further 15 points of any other category each
                  year.
                </span>
              }
              showHelpOnClick={true}
            />
            <QuestionDate
              control={control}
              isSubmitting={
                isApiSubmitting || isFormSubmitting || !isCurrentCPDYear
              }
              label="Completion date"
              min={calcMinDate}
              question={model.completionDate}
              help={cpdFormHelpText.COMPLETION_DATE.toString()}
              showHelpOnClick={true}
            />
            <Question.Textarea
              control={control}
              readOnly={!isCurrentCPDYear}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label="What did you learn?"
              question={model.whatDidYouLearn}
              help={cpdFormHelpText.WHAT_DID_YOU_LEARN.toString()}
              showHelpOnClick={true}
            />
            <AddFile
              readOnly={!isCurrentCPDYear}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isFilePicked={isFilePicked}
              setIsFilePicked={setIsFilePicked}
            />

            {editFormValues !== undefined && isCurrentCPDYear === false && (
              <p className={styles.deleteNotAvailable}>
                For audit purposes, CPD submissions from previous years cannot
                be deleted
              </p>
            )}
            <div className={buttonClassNames}>
              {(isApiSubmitting || isFormSubmitting) && (
                <span className={styles.spinner}></span>
              )}
              <Button.Generic
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={isApiSubmitting || isFormSubmitting}
                label="Cancel"
                onClick={onCancel}
                readOnly={isApiSubmitting || isFormSubmitting}
                style={ButtonStyle.SECONDARY}
              />
              {editFormValues !== undefined && isCurrentCPDYear === true && (
                <Button.Generic
                  colour={ButtonColour.BRAND_BLACK_GRAPE}
                  disabled={isApiSubmitting || isFormSubmitting}
                  label="Delete"
                  onClick={() => onDelete(editFormValues?.id)}
                  readOnly={isApiSubmitting || isFormSubmitting}
                  style={ButtonStyle.SECONDARY}
                />
              )}
              <Button.Submit
                colour={ButtonColour.BRAND_BLACK_GRAPE}
                disabled={
                  isApiSubmitting || isFormSubmitting || !isCurrentCPDYear
                }
                label="Submit"
                isSubmitting={isApiSubmitting || isFormSubmitting}
                showSpinner={false}
                style={ButtonStyle.SECONDARY}
              />
            </div>
          </Fieldset>
        )}
      </Questions>
    </div>
  );
};

export default CpdForm;
