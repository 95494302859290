import { useCallback, useEffect, useRef, useState } from 'react';
import { api, API } from 'modules/api';
import { Model } from '../modal/verifyOtpModal';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { ModalType } from 'modules/modals/constants';

export const useVerifyOtp = () => {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const [successfullyVerified, setSuccessfullyVerified] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const [verifyToken, setVerifyToken] = useState('');
  const errorModal = useRef<string | null>(null);
  const active = useRef(true);
  const showErrorModal = useCallback(() => {
    errorModal.current = modalShow({
      onClose: () => {
        !!errorModal.current && modalHide(errorModal.current);
        errorModal.current = null;
      },
      title: 'Error',
      text: 'There was an error trying to send your data. Please try again later',
      type: ModalType.INFO,
    });
  }, [modalHide, modalShow]);

  const checkVerificationCode = useCallback(
    async (data: Model) => {
      try {
        setIsApiSubmitting(true);
        const handleResetcheck = await api(
          API.POST_VERIFY_VERIFICATION_CODE(data),
        );
        if (handleResetcheck.data.result === true) {
          setVerifyToken(data.otp);
          setIsApiSubmitting(false);
          setSuccessfullyVerified(true);
        } else {
          showErrorModal();
        }
      } catch (error) {
        active.current && showErrorModal();
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    },
    [showErrorModal],
  );
  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);
  return {
    successfullyVerified,
    checkVerificationCode,
    verifyToken,
    isApiSubmitting,
  };
};
