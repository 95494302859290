import { BadgeLinksTypes, BadgesTypes } from 'pages/badges/types';
import {
  CertificatesItilTitles,
  CertificatesPropPathTitles,
  ItilDesignationsTitles,
  ProPathDesignationsTitles,
} from 'pages/badges/constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { API, api } from 'modules/api';
import logger from 'modules/logger';
import { useCallback } from 'react';

export const useAchieved = (
  achieved?: BadgesTypes[],
  findOutMoreLink?: BadgeLinksTypes,
) => {
  let canRecordCpd;
  let className;

  type LinkType = {
    href?: string | null;
    text?: string | null;
  };

  const link: LinkType = {
    href: '',
    text: '',
  };

  const certificateID =
    achieved && achieved.length > 0 && achieved[0].certificate != null
      ? achieved[0].certificate.id
      : '';

  const certificatesCanRecordCpd =
    achieved && achieved.length > 0 && achieved[0].certificate != null
      ? achieved[0].certificate.canRecordCpd
      : false;

  const hasDateExpiry =
    achieved &&
    achieved.length > 0 &&
    achieved[0].certificate != null &&
    achieved[0].certificate.dateExpires != null
      ? true
      : false;

  const date = {
    expiry: () => {
      if (achieved && achieved.length > 0) {
        const d = new Date(achieved[0].certificate.dateExpires);
        return `Renew by: ${d.getMonth() + 1}/${d.getFullYear()}`;
      }
      return null;
    },
    taken:
      achieved && achieved.length > 0 && achieved[0].certificate != null
        ? achieved[0].certificate.dateTaken
        : '',
  };

  switch (achieved && achieved.length > 0 && achieved[0].status) {
    case 4:
      canRecordCpd = certificatesCanRecordCpd;
      className = 'claimed';
      link.href = (achieved && achieved[0]?.viewUrl) || null;
      link.text = 'View';
      break;
    case 2:
      canRecordCpd = false;
      className = 'canClaim';
      link.href = (achieved && achieved[0]?.acceptUrl) || null;
      link.text = 'Claim Badge';
      break;
    case 1:
      canRecordCpd = false;
      className = 'examPassed';
      link.href = null;
      link.text = null;
      break;
    default:
      canRecordCpd = false;
      className = null;
      link.href = findOutMoreLink
        ? findOutMoreLink[0]?.find_out_more_link
        : null;
      link.text = 'Find out more';
      break;
  }

  return {
    canRecordCpd,
    certificateID,
    className,
    date,
    hasDateExpiry,
    link,
  };
};

export const useClaimBadgeGTMTrigger = () => {
  const handleBadgeClaimed = (badgeTitle: string, linktext: string) => {
    if (linktext === 'Claim Badge') {
      let actionType = '';

      switch (badgeTitle) {
        case CertificatesPropPathTitles.PRINCE2_FOUNDATION:
          actionType = EventsActions.CLICK_PRINCE2_F;
          break;
        case CertificatesPropPathTitles.PRINCE2_PRACTITIONER:
          actionType = EventsActions.CLICK_PRINCE2_P;
          break;
        case CertificatesPropPathTitles.PRINCE2_7_FOUNDATION:
          actionType = EventsActions.CLICK_PRINCE2_7_F;
          break;
        case CertificatesPropPathTitles.PRINCE2_7_PRACTITIONER:
          actionType = EventsActions.CLICK_PRINCE2_7_P;
          break;
        case CertificatesPropPathTitles.PRINCE2_AGILE_FOUNDATION:
          actionType = EventsActions.CLICK_PRINCE2_AGILE_F;
          break;
        case CertificatesPropPathTitles.PRINCE2_AGILE_PRACTITIONER:
          actionType = EventsActions.CLICK_PRINCE2_AGILE_P;
          break;
        case CertificatesPropPathTitles.MSP_FOUNDATION:
          actionType = EventsActions.CLICK_MSP_F;
          break;
        case CertificatesPropPathTitles.MSP_PRACTITIONER:
          actionType = EventsActions.CLICK_MSP_P;
          break;
        case CertificatesPropPathTitles.MOR_PRACTITIONER:
          actionType = EventsActions.CLICK_M_O_R;
          break;
        case CertificatesPropPathTitles.P3O_FOUNDATION:
          actionType = EventsActions.CLICK_P30_F;
          break;
        case CertificatesPropPathTitles.P3O_PRACTITIONER:
          actionType = EventsActions.CLICK_P30_P;
          break;
        case CertificatesPropPathTitles.MOP_FOUNDATION:
          actionType = EventsActions.CLICK_MOP_F;
          break;
        case CertificatesPropPathTitles.MOP_PRACTITIONER:
          actionType = EventsActions.CLICK_MOP_P;
          break;
        case ProPathDesignationsTitles.PROJECT_EXPERT:
          actionType = EventsActions.CLICK_PROJECT_EXPERT;
          break;
        case ProPathDesignationsTitles.AGILE_PROJECT_EXPERT:
          actionType = EventsActions.CLICK_AGILE_PROJECT_EXPERT;
          break;
        case ProPathDesignationsTitles.PROGRAMME_LEADER:
          actionType = EventsActions.CLICK_PROGRAMME_LEADER;
          break;
        case CertificatesItilTitles.FOUNDATION:
          actionType = EventsActions.CLICK_ITIL_F;
          break;
        case CertificatesItilTitles.CREATE_DELIVER_SUPPORT:
          actionType = EventsActions.CLICK_ITIL_CDS;
          break;
        case CertificatesItilTitles.DRIVE_STAKEHOLDER_VALUE:
          actionType = EventsActions.CLICK_ITIL_DSV;
          break;
        case CertificatesItilTitles.HIGH_VELOCITY_IT:
          actionType = EventsActions.CLICK_ITIL_HVIT;
          break;
        case CertificatesItilTitles.SUSTAINABILITY_IN_DIGITAL_IT:
          actionType = EventsActions.CLICK_ITIL_SIDIT;
          break;
        case CertificatesItilTitles.ACQUIRING_MANAGING_CLOUD_SERVICES:
          actionType = EventsActions.CLICK_ITIL_AMCS;
          break;
        case CertificatesItilTitles.DIRECT_PLAN_IMPROVE:
          actionType = EventsActions.CLICK_ITIL_DPI;
          break;
        case CertificatesItilTitles.DIGITAL_IT_STRATEGY:
          actionType = EventsActions.CLICK_ITIL_DITS;
          break;
        case ItilDesignationsTitles.MANAGING_PROFESSIONAL:
          actionType = EventsActions.CLICK_ITIL_MP;
          break;
        case ItilDesignationsTitles.STRATEGIC_LEADER:
          actionType = EventsActions.CLICK_ITIL_SL;
          break;
        case CertificatesItilTitles.BRM:
          actionType = EventsActions.CLICK_ITIL_SL;
          break;

        case CertificatesItilTitles.MONITOR_SUPPORT_AND_FULFIL:
          actionType = EventsActions.CLICK_ITIL_MONITOR_SUPPORT_AND_FULFIL;
          break;
        case CertificatesItilTitles.INCIDENT_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_INCIDENT_MANAGEMENT;
          break;
        case CertificatesItilTitles.SERVICE_DESK:
          actionType = EventsActions.CLICK_ITIL_SERVICE_DESK;
          break;
        case CertificatesItilTitles.SERVICE_REQUEST_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_SERVICE_REQUEST_MANAGEMENT;
          break;
        case CertificatesItilTitles.MONITORING_AND_EVENT_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_MONITORING_AND_EVENT_MANAGEMENT;
          break;
        case CertificatesItilTitles.PROBLEM_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_PROBLEM_MANAGEMENT;
          break;

        case CertificatesItilTitles.IT_ASSET_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_IT_ASSET_MANAGEMENT;
          break;
        case ItilDesignationsTitles.PRACTICE_MANAGER:
          actionType = EventsActions.CLICK_ITIL_PRACTICE_MANAGER;
          break;
        case ItilDesignationsTitles.ITIL_4_MASTER:
          actionType = EventsActions.CLICK_ITIL_ITIL_4_MASTER;
          break;
        case CertificatesItilTitles.CHANGE_ENABLEMENT:
          actionType = EventsActions.CLICK_ITIL_CHANGE_ENABLEMENT;
          break;
        case CertificatesItilTitles.DEPLOYMENT_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_DEPLOYMENT_MANAGEMENT;
          break;
        case CertificatesItilTitles.RELEASE_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_RELEASE_MANAGEMENT;
          break;
        case CertificatesItilTitles.SERVICE_CONFIGURATION_MANAGEMENT:
          actionType =
            EventsActions.CLICK_ITIL_SERVICE_CONFIGURATION_MANAGEMENT;
          break;

        case CertificatesItilTitles.COLLABORATE_ASSURE_AND_IMPROVE:
          actionType = EventsActions.CLICK_ITIL_COLLABORATE_ASSURE_AND_IMPROVE;
          break;
        case CertificatesItilTitles.CONTINUAL_IMPROVEMENT:
          actionType = EventsActions.CLICK_ITIL_CONTINUAL_IMPROVEMENT;
          break;
        case CertificatesItilTitles.SERVICE_LEVEL_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_SERVICE_LEVEL_MANAGEMENT;
          break;
        case CertificatesItilTitles.RELATIONSHIP_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_RELATIONSHIP_MANAGEMENT;
          break;
        case CertificatesItilTitles.INFORMATION_SECURITY_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_INFORMATION_SECURITY_MANAGEMENT;
          break;
        case CertificatesItilTitles.SUPPLIER_MANAGEMENT:
          actionType = EventsActions.CLICK_ITIL_SUPPLIER_MANAGEMENT;
          break;

        case CertificatesItilTitles.PLAN_IMPLEMENT_AND_CONTROL:
          actionType = EventsActions.CLICK_ITIL_PLAN_IMPLEMENT_AND_CONTROL;
          break;
      }
      TriggerGTMEvent({
        action: actionType,
        category: EventsCategories.BADGING,
        label: '',
      });
    }
  };

  return { handleBadgeClaimed };
};

export const useOnDelete = () => {
  const handleOnDelete = useCallback(async (id: number) => {
    try {
      api(API.DELETE_CPD_HISTORY(id))
        .then(res => {
          console.log('Record deleted successfully', res);
        })
        .catch(error => {
          logger.error('Get handleOnDelete Error', error);
        });
    } catch (error) {
      logger.error('Error deleting record', error);
    }
  }, []);

  return {
    handleOnDelete,
  };
};
