import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import clsx from 'clsx';

import { BadgeLinksTypes, BadgesTypes } from 'pages/badges/types';
import { BadgesType, BadgeStatus } from '../constants';
import { ButtonTarget } from 'components/Button/constants';
import { ProgrammeType } from '../../Programme/constants';
import { ReactComponent as BadgeIcon } from 'modules/theme/icons/misc/badge.svg';
import { ReactComponent as Timer } from 'modules/theme/icons/misc/badges-timer-icon.svg';
import { ReactComponent as TrophyIcon } from 'modules/theme/icons/misc/trophy.svg';
import { useAchieved, useClaimBadgeGTMTrigger, useOnDelete } from './hooks';
import * as Button from 'components/Button';
import * as Hyperlink from 'components/Hyperlink';
import PlatformContext from 'modules/platform/context';
import useModalAction from 'modules/modals/hooks/useModalAction';

import styles from './styles.module.scss';

type BadgesItemProps = {
  achieved?: BadgesTypes[];
  badgesType: BadgesType;
  findOutMoreLink?: BadgeLinksTypes;
  title: string;
  type: ProgrammeType;
  handleClaimBadge: () => void;
  isSubmitting: boolean;
};

const Badge: React.FunctionComponent<BadgesItemProps> = ({
  achieved,
  badgesType,
  findOutMoreLink,
  title,
  type,
  handleClaimBadge,
  isSubmitting,
}) => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const [showHelp, setShowHelp] = useState(false);
  const { isMobile } = useContext(PlatformContext);

  const { canRecordCpd, certificateID, className, date, hasDateExpiry, link } =
    useAchieved(achieved, findOutMoreLink);

  const { handleBadgeClaimed } = useClaimBadgeGTMTrigger();

  const isClaiming = useMemo(
    () => `Claiming:${achieved && achieved[0]?.assertionId}`,
    [achieved],
  );

  const classNames = clsx(
    styles.badge,
    styles[type.replace(/\s/g, '-')],
    !!className && `${styles[className]}`,
    isSubmitting && styles.isSubmitting,
    isClaiming != null && styles.isClaiming,
    isMobile && showHelp && styles.showHelp,
  );

  useEffect(() => {
    if (
      achieved &&
      achieved[0]?.status === BadgeStatus.BADGE_CLAIMED &&
      isClaiming != null
    ) {
      sessionStorage.removeItem(
        `Claiming:${achieved && achieved[0]?.assertionId}`,
      );
    }
  }, [achieved, isClaiming]);

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    };
  }, [modalHide]);

  return (
    <li className={classNames}>
      <h4>
        <span>{title}</span>
        {badgesType === BadgesType.DESIGNATION ? (
          <TrophyIcon />
        ) : (
          <React.Fragment>
            {achieved && achieved[0]?.status === BadgeStatus.BADGE_PENDING ? (
              <React.Fragment>
                <Timer onClick={() => setShowHelp(!showHelp)} />
                <div role="tooltip" aria-hidden="true">
                  We're preparing your badge for claiming.
                </div>
              </React.Fragment>
            ) : isClaiming != null &&
              achieved &&
              achieved[0]?.status === BadgeStatus.BADGE_CLAIMABLE ? (
              <React.Fragment>
                <Timer onClick={() => setShowHelp(!showHelp)} />
                <div role="tooltip" aria-hidden="true">
                  Your badge is on its way from Credly and will appear on the
                  badging page shortly.
                </div>
              </React.Fragment>
            ) : (
              <BadgeIcon />
            )}
          </React.Fragment>
        )}
      </h4>
      <div>
        {achieved &&
        achieved.length > 0 &&
        achieved[0].status === BadgeStatus.BADGE_PENDING ? (
          <span>Exam Passed!</span>
        ) : achieved &&
          achieved.length > 0 &&
          achieved[0].status === BadgeStatus.BADGE_CLAIMABLE ? (
          <React.Fragment>
            {isClaiming != null ? (
              <React.Fragment>
                <span>Claiming...</span>
                <Hyperlink.CustomLink
                  className={styles.credleyLink}
                  href="" // Delete this after PeopleCert+ Transition
                  // href="https://www.credly.com/users/sign_in"
                  // target={ButtonTarget.BLANK}
                  rel="noreferrer"
                >
                  Visit Credly
                </Hyperlink.CustomLink>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button.Generic
                  disabled={true} // Delete this after PeopleCert+ Transition
                  // disabled={isSubmitting}
                  label="Claim Badge"
                  onClick={handleClaimBadge}
                />
                {isSubmitting && <span className={styles.spinner}></span>}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {link.href && (
              <Hyperlink.CustomLink
                href={link.href}
                onClick={() => handleBadgeClaimed(title, link.text || '')}
                rel="noreferrer"
                target={ButtonTarget.BLANK}
              >
                {link.text}
              </Hyperlink.CustomLink>
            )}
            {hasDateExpiry && <span>{date.expiry()}</span>}
          </React.Fragment>
        )}
      </div>
    </li>
  );
};

export default React.memo(Badge);
