import { useCallback, useEffect, useRef, useState } from 'react';
import { api, API } from 'modules/api';
import { Model } from '../modal/verifyEmailmodel';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { ModalType } from 'modules/modals/constants';

export const useVerifyEmail = () => {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);
  const active = useRef(true);

  const showErrorModal = useCallback(() => {
    errorModal.current = modalShow({
      onClose: () => {
        !!errorModal.current && modalHide(errorModal.current);
        errorModal.current = null;
      },
      title: 'Error',
      text: 'There was an error trying to send your data. Please try again later',
      type: ModalType.INFO,
    });
  }, [modalHide, modalShow]);

  const handleResetEmail = useCallback(
    async (data: Model) => {
      try {
        setIsApiSubmitting(true);
        const handleResetCode = await api(API.POST_VERIFY_EMAIL_ADDRESS(data));
        setEmailInput(data.email);
        setVerificationCode(handleResetCode.data.result);
      } catch (error) {
        active.current && showErrorModal();
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    },
    [showErrorModal],
  );

  const handleResetWithoutModelEmail = useCallback(
    async (email: string) => {
      try {
        setIsApiSubmitting(true);
        const handleResetCode = await api(API.POST_VERIFY_EMAIL_ADDRESS(email));
        setVerificationCode(handleResetCode.data.code);
      } catch (error) {
        active.current && showErrorModal();
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    },
    [showErrorModal],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    verificationCode,
    handleResetWithoutModelEmail,
    emailInput,
    handleResetEmail,
    isApiSubmitting,
  };
};
