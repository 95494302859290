import { StatusCodes } from 'http-status-codes';
import retry from 'async-retry';
import axios from 'axios';

import { ApiConfig, DefaultApiResponse } from './types';
import { APIExceptions } from './exceptions';
import logger from 'modules/logger';
import { getRefreshToken, logOutRedirection } from 'modules/utils/utils';

export { API } from './constants';

const RETRIES = 0;

const errorCodes = [
  StatusCodes.BAD_REQUEST,
  StatusCodes.UNPROCESSABLE_ENTITY,
  StatusCodes.NOT_FOUND,
  StatusCodes.FORBIDDEN,
  StatusCodes.CONFLICT,
];

export const api = async (config: ApiConfig): Promise<DefaultApiResponse> => {
  const response = await retry(
    async bail => {
      try {
        return await axios({ ...config });
      } catch (error: any) {
        if (error.response?.status === StatusCodes.UNAUTHORIZED) {
          logger.warn('API TOKEN REFRESH');
          try {
            // await getRefreshToken();
          } catch {
            logger.warn('API TOKEN FAIL');
            logOutRedirection();
            return;
          }
        } else if (errorCodes.includes(error.response?.status)) {
          logger.warn('API BAIL');

          if (error.response.status === StatusCodes.FORBIDDEN) {
            //if the subscription has expired we reload to display
            //the lapsed subscription screen
            //LOGOUT THE USER IF STATUS FORBIDDEN
            logOutRedirection();
            // window.location.reload();
          }
          if (
            error.response.status === StatusCodes.CONFLICT &&
            error.response.data.type !== APIExceptions.NoBadgeException &&
            error.response.data.type !== APIExceptions.NoExamPassedException
          ) {
            throw error;
          }
          return bail(error);
        }
        logger.warn('API RETRY');
        throw error;
      }
    },
    {
      retries: RETRIES,
    },
  );
  return response as DefaultApiResponse;
};
