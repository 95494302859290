import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { getDateFormattedForCPD } from 'modules/utils/utils';
import { api, API } from 'modules/api';
import logger from 'modules/logger';

import * as Button from 'components/Button';
import styles from './styles.module.scss';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import CpdForm from 'pages/badges/components/Badges/BadgesItem/CpdForm';
import { ModalType } from 'modules/modals/constants';
import { pointsOptions } from 'pages/badges/components/Badges/BadgesItem/CpdForm/selectOptions';
import {
  useAchieved,
  useOnDelete,
} from 'pages/badges/components/Badges/BadgesItem/hooks';

type cpdClaimsSummary = {
  id: number;
  userId: number;
  examId: number;
  type: number;
  claimType: string;
  description: string;
  points: number;
  completionDate: string;
  comment: string | null;
  cpdClaimProductSuiteModel: any;
  cpdDocumentDetails: [];
};

// type cpdClaimsSummary = {
//   completionDate: string;
//   description: string;
//   points: number;
// };

export type CPDTrackRecordItem = {
  cpdClaims: cpdClaimsSummary[];
  endDate: string;
  startDate: string;
  totalCpdPoints: number;
  isCurrentCPDYear: boolean;
};

type CPDTrackRecordProps = {
  CPDTrackRecordItem: CPDTrackRecordItem;
  year: number;
  lastYearPoints: number;
  productSuiteId: number;
  productSuiteName: string;
  setIsFetchDataCallback: (val: boolean) => void;
};

const CPDTrackRecord: React.FunctionComponent<CPDTrackRecordProps> = ({
  CPDTrackRecordItem,
  year,
  lastYearPoints,
  productSuiteId,
  productSuiteName,
  setIsFetchDataCallback,
}) => {
  const initialCpdClaim = [
    {
      id: 0,
      userId: 0,
      examId: 0,
      type: 0,
      claimType: '',
      description: '',
      points: 0,
      completionDate: '',
      comment: null,
      cpdClaimProductSuiteModel: null,
      cpdDocumentDetails: [],
    },
  ];

  const [cpdClaimId, setCpdClaimId] = useState(initialCpdClaim);

  const { cpdClaims, endDate, startDate, totalCpdPoints, isCurrentCPDYear } =
    CPDTrackRecordItem;

  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const modal2 = useRef<string | null>(null);
  const modal3 = useRef<string | null>(null);
  const [experiencePoints, setExperiencePoints] = useState(0);

  const { canRecordCpd, certificateID, className, date, hasDateExpiry, link } =
    useAchieved();

  const classNames = clsx(
    styles.CPDTrackRecord,
    // Initial Year State
    totalCpdPoints === 0 &&
      cpdClaims.length === 0 &&
      isCurrentCPDYear === true &&
      lastYearPoints >= 20 &&
      styles.initialYearState,

    // Partial Year State
    (totalCpdPoints < 20 || experiencePoints < 5) &&
      cpdClaims.length > 0 &&
      isCurrentCPDYear === true &&
      styles.partialYearState,

    // Complete Year State
    totalCpdPoints >= 20 &&
      experiencePoints >= 5 &&
      cpdClaims.length > 0 &&
      styles.completeYearState,

    // Missed cpd add points option due to date
    isCurrentCPDYear === false &&
      new Date(endDate) < new Date() &&
      totalCpdPoints < 20 &&
      styles.missedExamRenewalState,

    // Incomplete Year State
    totalCpdPoints < 20 &&
      isCurrentCPDYear === false &&
      styles.incompleteYearState,

    // Future Year State
    isCurrentCPDYear === false &&
      cpdClaims.length === 0 &&
      styles.futureYearState,

    // Incomplete Future Year State
    lastYearPoints < 20 && styles.incompleteFutureYearState,
  );

  const showFormModal = () => {
    modal.current = modalShow({
      onCancel: () => {
        handleOnCancel();
      },
      renderContent: onCancel => (
        <CpdForm
          certificateId={certificateID}
          examDateTaken={startDate}
          onCancel={onCancel}
          onDelete={handleOnDelete}
          modalRef={modal}
          pointsOptions={pointsOptions}
          title={productSuiteName}
          currentProductSuiteId={productSuiteId}
          isCurrentCPDYear={isCurrentCPDYear}
          setIsFetchDataCallback={setIsFetchDataCallback}
        />
      ),
      title: "Let's claim your CPD!",
      type: ModalType.FORM,
    });
  };

  const showEditFormModal = (obj?: any) => {
    modal.current = modalShow({
      onCancel: () => {
        if (new Date(endDate) > new Date()) {
          handleOnCancel();
        } else {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        }
      },
      renderContent: onCancel => (
        <CpdForm
          certificateId={certificateID}
          examDateTaken={startDate}
          onCancel={onCancel}
          onDelete={handleOnDelete}
          modalRef={modal}
          pointsOptions={pointsOptions}
          title={productSuiteName}
          currentProductSuiteId={productSuiteId}
          isCurrentCPDYear={isCurrentCPDYear}
          editFormValues={obj[0]}
          setIsFetchDataCallback={setIsFetchDataCallback}
        />
      ),
      title: 'Edit your CPD Submission',
      type: ModalType.FORM,
    });
  };

  const onDelete = (id: number) => {
    try {
      api(API.DELETE_CPD_HISTORY(id))
        .then((res: any) => {
          setIsFetchDataCallback(true);
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        })
        .catch(error => {
          logger.error('Get handleOnDelete Error', error);
        });
    } catch (error) {
      logger.error('Error deleting record', error);
    }
  };

  const handleOnCancel = useCallback(() => {
    if (!modal3.current) {
      modal3.current = modalShow({
        onCancel: () => {
          !!modal3.current && modalHide(modal3.current);
          modal3.current = null;
        },
        onConfirm: () => {
          !!modal3.current && modalHide(modal3.current);
          modal3.current = null;
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        title: 'Unsaved Changes',
        text: 'Are you sure you want to proceed without saving your changes?',
        type: ModalType.CONFIRM,
      });
    }
  }, [modal3, modalHide, modalShow]);

  const handleOnDelete = useCallback(
    (id: number) => {
      if (!modal2.current) {
        modal2.current = modalShow({
          onCancel: () => {
            !!modal2.current && modalHide(modal2.current);
            modal2.current = null;
          },
          onConfirm: () => {
            onDelete(id);
            !!modal2.current && modalHide(modal2.current);
            modal2.current = null;
          },
          title: 'Remove CPD Claim Record',
          text: 'Are you sure you want to remove this record?',
          type: ModalType.CONFIRM,
        });
      }
    },
    [modal2, modalHide, modalShow],
  );

  // Delete this after PeopleCert+ Transition
  const disableCPDEdit = () => {
    console.log('Clicked e.target.dataset.id');
  };

  const handleCPDEdit = (e: any) => {
    console.log('e.target.dataset.id', e.target.dataset.id);
    const obj = cpdClaims.filter((el: any) => {
      return el?.id == e.target.dataset.id;
    });
    setCpdClaimId(current => [
      {
        id: obj[0].id,
        userId: obj[0].userId,
        examId: obj[0].examId,
        type: obj[0].type,
        claimType: obj[0].claimType,
        description: obj[0].description,
        points: obj[0].points,
        completionDate: obj[0].completionDate,
        cpdClaimProductSuiteModel: obj[0].cpdClaimProductSuiteModel,
        cpdDocumentDetails: obj[0].cpdDocumentDetails,
        comment: null,
      },
    ]);

    showEditFormModal(obj);
  };

  // const handlePoints = useCallback(() => {
  //   cpdClaims.forEach(claim => {
  //     if (claim.claimType === 'Professional Experience') {
  //       setExpreriencePoints(prevPoints => prevPoints + claim.points);
  //     }
  //   });
  // }, [totalCpdPoints]);

  useEffect(() => {
    setExperiencePoints(0);
    cpdClaims.forEach(claim => {
      if (claim.claimType === 'Professional Experience') {
        setExperiencePoints(prevPoints => prevPoints + claim.points);
      }
    });
  }, [totalCpdPoints]);

  return (
    <div className={classNames}>
      <div className={styles.date}>
        {totalCpdPoints > 20
          ? '20/20'
          : totalCpdPoints.toString().padStart(2, '0') + '/20'}
        {/* {totalCpdPoints.toString().padStart(2, '0')}/20 */}
      </div>

      <div className={styles.status}>
        <span></span>
        <span></span>
      </div>

      <div className={styles.years}>
        <h3>Year {year}</h3>

        <p>
          {getDateFormattedForCPD(startDate)} -{' '}
          {getDateFormattedForCPD(endDate)}
        </p>

        {cpdClaims.length > 0 &&
          cpdClaims?.map((item: any, index) => {
            return (
              <div
                key={`points-summary-${index}`}
                className={styles.pointsSummary}
              >
                <span>{getDateFormattedForCPD(item.completionDate)}:</span>
                <span
                  onClick={disableCPDEdit} // Delete this after PeopleCert+ Transition
                  // onClick={handleCPDEdit}
                  className={styles.pointsDescription}
                  data-id={item.id}
                  data-item={item}
                >
                  {item.claimType}
                </span>
                <span className={styles.lastItem}>
                  +{item.points.toString().padStart(2, '0')}p
                </span>
              </div>
            );
          })}

        {/* TODO - show if current year */}
        <div className={styles.pointsRemainingText}>
          {isCurrentCPDYear && experiencePoints < 5
            ? `* Mandatory Professional Experience points remaining :  ${
                5 - experiencePoints
              }`
            : null}
        </div>
        {isCurrentCPDYear && lastYearPoints >= 20 && (
          <>
            <Button.Generic
              className={styles.peopleCertButton}
              colour={ButtonColour.BRAND_BERRY}
              style={ButtonStyle.PRIMARY}
              label="Add CPD"
              disabled={true} // Delete this after PeopleCert+ Transition
              small
              onClick={showFormModal}
            ></Button.Generic>
          </>
        )}
      </div>
    </div>
  );
};

export default CPDTrackRecord;
