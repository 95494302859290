import { useLoginToken } from 'modules/hooks';
import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { SESSION_IDLE_MINUTES } from 'modules/utils/constants';
import React, { useCallback, useRef } from 'react';
import IdleTimer, { useIdleTimer } from 'react-idle-timer';
import Countdown from 'react-countdown';

export const AutoLogoutTimer = () => {
  const { logOutAndRedirection } = useLoginToken();
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);

  const handleOnComplete = () => {
    !!modal.current && modalHide(modal.current);
    modal.current = null;
    logOutAndRedirection();
  };

  const handleCountdown = () => {
    return (
      <div>
        <span>You will be logged out in </span>
        <b>
          <Countdown
            date={Date.now() + 2 * 60 * 1000}
            onComplete={handleOnComplete}
            zeroPadTime={2}
            renderer={props => (
              <span>
                {props.minutes} : {props.seconds}
              </span>
            )}
          ></Countdown>
        </b>
        <span>. Do you want to remain logged in?</span>
      </div>
    );
  };

  const handleSessionExpiryModel = useCallback(() => {
    if (!modal.current) {
      modal.current = modalShow({
        onCancel: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
          logOutAndRedirection();
        },
        onConfirm: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
          reset();
        },
        title: 'Are you still there?',
        text: handleCountdown(),
        type: ModalType.CONFIRM,
        cancelLabel: 'No',
        confirmLabel: 'Yes',
      });
    }
  }, [modal, modalHide, modalShow]);

  const handleOnIdle = (event: any) => {
    handleSessionExpiryModel();
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 28,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <IdleTimer
      timeout={1000 * 60 * SESSION_IDLE_MINUTES}
      onIdle={handleOnIdle}
      debounce={500}
    />
  );
};
