//colors
export const BORDER_COLOR = '#e4e4e4';
export const MY_AXELOS_WHITE = '#ffffff';
export const BRAND_BLACK_GRAPE = '#282d3d';
export const COLOR_ITIL = '#8f63e5';
export const COLOR_PROPATH = '#2ebfa5';
export const COLOR_TRANSFORMATION = '#f25388';
export const COLOR_MATURITY = '#2274a5';
export const COLOR_RESILIA = '#AE1D57';
export const COLOR_LIGHT_BLUE = '#edfcfc';

//viewport sizes
export const MOBILE = '767px';
export const MOBILE_SML = '540px';

//font-sizes
export const BIG_FONT_SIZE_DESKTOP = '20px';
export const BIG_FONT_SIZE_MOBILE = '17px';

export const RESOURCE_HUB_ELEMENTS_TO_SHOW = 10;

export const RESOURCE_HUB_URL = '/resource-hub/';
export const EVENTS_URL = '/events/';
export const BADGES_URL = '/badges/';

export const ALL_FILTERS = 'All';
export const ALL_FILTERS_ELEMENT = {
  title: ALL_FILTERS,
  uid: '',
  slug: '',
  colour: '',
};

//INTRO_CONTENT_WIDGETS ENUMERATOR
export const TEXT = 0;
export const VIDEO_WITH_CAPTION = 1;

export const INTRO_CONTENT_WIDGETS_DICTIONARY = {
  TEXT,
  VIDEO_WITH_CAPTION,
};

//BODY_CONTENT_WIDGETS ENUMERATOR
export const CONTENTS_HEADING = 0;
export const TEXT_WITH_SUBHEADING = 1;
export const IMAGE_WITH_CAPTION = 2;
export const VIDEO_WITH_CAPTION_BODY = 3;
export const TABLE_SECTION = 4;
export const DOCUMENT_DOWNLOAD = 5;
export const SMALL_DEGREES_BANNER = 6;
export const MEDIUM_DEGREES_BANNER = 7;
export const LARGE_DEGREES_BANNER = 8;
export const TESTIMONIALS_SECTION = 9;
export const FULLWIDTH_IMAGE_AND_TEXT = 10;
export const FAQ_ACCORDION = 11;
export const RELATED_CONTENT = 12;
export const TWO_COLUMN_IMAGE = 13;
export const KEY_MESSAGE = 14;

export const BODY_CONTENT_WIDGETS_DICTIONARY = {
  CONTENTS_HEADING,
  TEXT_WITH_SUBHEADING,
  IMAGE_WITH_CAPTION,
  VIDEO_WITH_CAPTION_BODY,
  TABLE_SECTION,
  DOCUMENT_DOWNLOAD,
  SMALL_DEGREES_BANNER,
  MEDIUM_DEGREES_BANNER,
  LARGE_DEGREES_BANNER,
  TESTIMONIALS_SECTION,
  FULLWIDTH_IMAGE_AND_TEXT,
  FAQ_ACCORDION,
  RELATED_CONTENT,
  TWO_COLUMN_IMAGE,
  KEY_MESSAGE,
};

//best-practices colours
export const ITIL = 'ITIL';
export const ProPath = 'ProPath';
export const Transformation = 'Transformation';
export const Maturity = 'Maturity';
export const RESILIA = 'RESILIA';
export const BEST_PRACTICES_COLORS = {
  ITIL,
  ProPath,
  Transformation,
  Maturity,
  RESILIA,
};

export const TOKEN_PARAM_NAME = 'ClientID';
export const LOG_OUT_TRIGGERED = 'logOutTriggered';
// export const TOKEN_PARAM_NAME = 'token';
export const USER_ROLE_COOKIE_NAME = 'user_role';
export const CLIENTID_COOKIE_NAME = 'clientid';
export const PEOPLECERT_SPLIT_TEST_COOKIE_NAME = 'ab_peoplecert';
export const COOOKIE_BANNER_COOKIE_NAME = 'CookieControl';

//audio and video content types
export const PODCAST_CONTENT_TYPE = 'podcast';
export const WEBINAR_CONTENT_TYPE = 'webinar';
export const TUTORIAL_CONTENT_TYPE = 'tutorial';

export enum DashboardContentTypes {
  FeaturedResources = 1,
  Tutorials = 2,
  Events = 3,
}

//USER ROLES
export const USER_ROLE_INDIVIDUAL =
  process.env.REACT_APP_USER_ROLE_INDIVIDUAL || 'Candidate';
export const USER_ROLE_CONSULTANT =
  process.env.REACT_APP_USER_ROLE_CONSULTANT || 'AXELOS Consultant';
export const USER_ROLE_EMPLOYEE =
  process.env.REACT_APP_USER_ROLE_EMPLOYEE || 'Role_Employee';

export const mainUserRoles = [
  USER_ROLE_INDIVIDUAL,
  USER_ROLE_CONSULTANT,
  USER_ROLE_EMPLOYEE,
];
export const ROLE_LABEL_INDIVIDUAL =
  process.env.REACT_APP_ROLE_LABEL_INDIVIDUAL || 'Individual';
export const ROLE_LABEL_CONSULTANT =
  process.env.REACT_APP_ROLE_LABEL_CONSULTANT || 'Consultant';

export const ROLE_LABEL_EMPLOYEE =
  process.env.REACT_APP_ROLE_LABEL_EMOLOYEE || 'Employee';

export const ACCOUNT_LABEL_INDIVIDUAL =
  process.env.REACT_APP_ACCOUNT_LABEL_INDIVIDUAL || 'Individual';
export const ACCOUNT_LABEL_CONSULTANT =
  process.env.REACT_APP_ACCOUNT_LABEL_CONSULTANT || 'Consultant';

export const ROLE_SELECT_SCREEN_CONTENT_ID =
  process.env.REACT_APP_ROLE_SELECT_SCREEN_CONTENT_ID || 'role_select_screen';

export const SIGN_UP_URL = process.env.REACT_APP_SIGN_UP_URL || '/signup';

export const BOOKMARKS_URL =
  process.env.REACT_APP_BOOKMARKS_URL || '/bookmarks';

export const PAGE_NO_PERMISSION_URL =
  process.env.REACT_APP_PAGE_NO_PERMISSION_URL || '/content-not-allowed';

export const CURATE_PAGE_URL =
  process.env.REACT_APP_CURATE_PAGE_URL || '/curate/';

export const CONGRATULATIONS_PAGE_URL =
  process.env.REACT_APP_CONGRATULATIONS_PAGE_URL || '/congratulations';

export const VERIFY_PAGE_URL =
  process.env.REACT_APP_VERIFY_PAGE_URL || '/verify';

export const POLICIES_PAGE_URL = '/policies/';

export const LEGAL_PAGE_URL = `${POLICIES_PAGE_URL}legal`;
export const PRIVACY_PAGE_URL = `${POLICIES_PAGE_URL}privacy-policy`;
export const TC_PAGE_URL = `${POLICIES_PAGE_URL}terms-and-conditions`;
export const TS_PAGE_URL = `${POLICIES_PAGE_URL}terms-of-service`;

export const PRIVACY_CONTENT_TYPE_ID =
  process.env.REACT_APP_PRIVACY_CONTENT_TYPE_ID || 'policies_myaxelos';

export const MM_P3M3_LINK =
  process.env.REACT_APP_MM_P3M3_LINK || 'https://www.axelos.com/my-axelos';

export const MM_ITIL_LINK =
  process.env.REACT_APP_MM_ITIL_LINK || 'https://www.axelos.com/';

export const MM_ITIL_ROLE_NAME =
  process.env.REACT_APP_MM_ITIL_ROLE_NAME || 'Consultant_ITIL_MM';

export const MM_P3M3_ROLE_NAME =
  process.env.REACT_APP_MM_P3M3_ROLE_NAME || 'Consultant_P3M3_MM';

export const PRODUCT_P3M3_CONSULTANT_ROLE_NAME =
  process.env.REACT_APP_PRODUCT_P3M3_CONSULTANT_ROLE_NAME ||
  'Product_P3M3_Consultant';

export const PRODUCT_CONSULTANT_ROLE =
  process.env.REACT_APP_PRODUCT_CONSULTANT_ROLE || 'Product_ITIL_MM_Consultant';

export const REDIRECT_URL_COOKIE =
  process.env.REACT_APP_REDIRECT_URL_COOKIE || 'redirect_URL';

export const ACCOUNT_MANAGEMENT_URL =
  process.env.REACT_APP_ACCOUNT_MANAGEMENT_URL || '/account-management';

// export const LOGIN_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL!;

export const LOGIN_URL = '/sign-in';

export const FORGOT_PASSWORD_URL =
  'https://myaxelostest.b2clogin.com/myaxelostest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_PWDRESET&client_id=41dc63e4-b00b-4e58-b711-112a5391aa9b&nonce=bc16d6&redirect_uri=https://api-myaxe-uat-ukw.azurewebsites.net/api/v1%2Fauth%2Fprocess-token&scope=https%3A%2F%2Fmyaxelostest.onmicrosoft.com%2Fwebapp1%2FGet.Token&response_type=token&prompt=login&response_mode=query';

export const AXELOS_HOMEPAGE_URL =
  process.env.REACT_APP_AXELOS_HOMEPAGE_URL || 'https://www.axelos.com/';

export const AXELOS_CONTACT_URL =
  process.env.REACT_APP_AXELOS_CONTACT_URL ||
  'https://www.axelos.com/contact-axelos';

export const HELP_URL = process.env.REACT_APP_HELP_URL || '/help';

export const SIGN_IN_URL = '/sign-in';

export const RESET_PASSWORD_URL =
  process.env.REACT_APP_HELP_URL || '/reset-password';

export const DEBUG_URL = process.env.REACT_APP_DEBUG_URL || '/debug';

export const CURATED_FOR_YOU =
  process.env.REACT_APP_CURATED_FOR_YOU || 'CURATED FOR YOU';

export const BOOKMARKS_TO_SHOW = parseInt(
  process.env.REACT_APP_BOOKMARKS_TO_SHOW || '15',
);
export const BRAINTREE_URL =
  process.env.REACT_APP_BRAINTREE_URL || '/braintree';

export const SESSION_IDLE_MINUTES = parseInt(
  process.env.REACT_APP_SESSION_IDLE_MINUTES || '28',
);

export const CONGRATULATIONS_DEFAULT_BACKGROUND_IMAGE =
  process.env.REACT_APP_CONGRATULATIONS_DEFAULT_BACKGROUND_IMAGE ||
  'https://eu-images.contentstack.com/v3/assets/blt2d4e014bb50e60be/blt5fc05b6d061dc445/61b241ea6cfd9e67c963a3c4/congratulations-bg.jpg';

export const NO_FILTER_TEXT = 'all';

export const EMPLOYEE_CONTENT_TYPE_TITLE =
  process.env.REACT_APP_EMPLOYEE_CONTENT_TYPE_TITLE || 'ITIL Practices';

export const EMPLOYEE_CONTENT_TYPE_FILTER =
  process.env.REACT_APP_EMPLOYEE_CONTENT_TYPE_FILTER || 'itil/practice';

export const CACHING_ID_ITEM_NAME = 'CachingID';
export const CLIENT_ID_ITEM_NAME = 'ClientID';
export const CLIENT_EMAIL = 'clientEmail';
export const PEOPLECERT_ID = 'peoplecertid';
export const ADBID = 'adb2cid';
export const USER_ID = 'userid';
export const IS_USER_CONSENT_CHECKED = 'isUserConsentChecked';

export const LOCK_FIELDS_SIGNUP_PCERT_USER =
  process.env.REACT_APP_LOCK_FIELDS_SIGNUP_PCERT_USER === 'true';

export const SIGN_UP_REMINDER = parseInt(
  process.env.REACT_APP_SIGN_UP_REMINDER || '5',
);

export const SIGNUP_REMINDER_CLIKED_COOKIE = 'signup_reminder_clicked';
export const SIGNUP_REMINDER_CLIKED_VALUE = 'clicked';
export const PAGES_VIEWED_COOKIE_NAME = 'pages-viewed';

export const USER_IS_MY_AXELOS_ERROR =
  'User Already exist with My Axelos we have recently implemented single sign-on (SSO) for PeopleCert and MyAxelos and therefore, we need you to reset your password using Forgot your password functionality in order to consolidate your account credentials.';
