import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { CountryCodeProps, ProductInfoProps, VoucherCodeProps } from '../types';
import {
  model,
  Model,
  schema,
  SignupWithPaymentPCertSchema,
} from '../QuestionSet/model';
import * as FormControl from '../FormControl';
import * as QuestionSet from '../QuestionSet';
import Fieldset from 'components/Fieldset';
import PurchaseSummary from '../PurchaseSummary';
import QuestionsWithRecaptcha from 'components/QuestionsWithRecaptcha';
import RecaptchaMessage from 'components/RecaptchaMessage';
import VoucherCode from '../VoucherCode';

import styles from './styles.module.scss';

type PersonalInfoQuestionsProps = {
  countryCode: CountryCodeProps;
  defaultValues: Model;
  handleSubmit: (data: any) => Promise<void>;
  isPeopleCertUser: boolean;
  isExistInPeoplecert?: boolean;
  isSubmitting: boolean;
  productInfo: ProductInfoProps;
  voucherCode: VoucherCodeProps;
};

function PersonalInfoQuestions({
  countryCode,
  defaultValues,
  handleSubmit,
  isPeopleCertUser,
  isSubmitting,
  isExistInPeoplecert,
  productInfo,
  voucherCode,
}: PersonalInfoQuestionsProps) {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });

  const updateCountry = useCallback(
    values => {
      countryCode.setValue(values.countryCode);
    },
    [countryCode],
  );

  return (
    <QuestionsWithRecaptcha
      className={styles.signUpForm}
      defaultValues={defaultValues}
      id="SignUp_PersonalInfo"
      onSubmit={handleSubmit}
      schema={isExistInPeoplecert ? SignupWithPaymentPCertSchema : schema[0]}
    >
      {({ control, isFormSubmitting, values }) => {
        updateCountry(values); //TODO possible problem?
        return (
          <React.Fragment>
            <Fieldset title="Personal info" disabled={isExistInPeoplecert}>
              <QuestionSet.PersonalInfo
                control={control}
                isPeopleCertUser={isPeopleCertUser}
                isExistInPeoplecert={isExistInPeoplecert}
                isSubmitting={isFormSubmitting || isSubmitting}
                model={model}
              />
            </Fieldset>

            <RecaptchaMessage className={styles.recaptchaMessage} />

            {isMobileBreakpoint && (
              <PurchaseSummary showBackLink={true} {...productInfo}>
                <VoucherCode {...voucherCode} isSubmitting={isSubmitting} />
              </PurchaseSummary>
            )}

            <FormControl.PersonalInfo
              isSubmitting={isFormSubmitting || isSubmitting}
            />
          </React.Fragment>
        );
      }}
    </QuestionsWithRecaptcha>
  );
}

export default React.memo(
  PersonalInfoQuestions,
) as typeof PersonalInfoQuestions;
