import { useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { Model } from '../model';
import { ModalType } from 'modules/modals/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { UseFormReset } from 'react-hook-form';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { ExamMessages } from 'pages/badges/types';

export const useSubmitCandidate = (
  updateBadges: (runAll: boolean) => Promise<void>,
  getBadges: (openLink: boolean) => Promise<void>,
) => {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const [candidateSubmitState, setCandidateSubmitState] = useState('');
  const [candidateErrorState, setCandidateErrorState] = useState(false);
  const [candidateId, setCandidateId] = useState('');
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);
  const active = useRef(true);

  const showModal = (title: string, message: string) => {
    if (!errorModal.current) {
      errorModal.current = modalShow({
        onClose: () => {
          !!errorModal.current && modalHide(errorModal.current);
          errorModal.current = null;
        },
        title: title,
        text: message,
        type: ModalType.INFO,
      });
    }
  };

  //TODO done useCallback
  const postCandidateId = async (newCandidateValue: string) => {
    try {
      setCandidateErrorState(false);
      const res = await api(API.POST_CANDIDATE_POST(newCandidateValue));
      if (active.current) {
        setCandidateId(newCandidateValue);
        return res.data;
      }
    } catch (error: any) {
      if (active.current) {
        switch (error.response.data.type) {
          case 'CandidateIdNotFoundException':
            setCandidateErrorState(true);
            break;
          case 'CandidateIdAlreadyExistsException':
            showModal(
              'Error',
              'Oops, it looks like this candidate number has already been added. Please try again or visit our <a href="/help">Help page</a>',
            );
            break;
          case 'CandidateIdClaimCheckNoExamException':
            setCandidateId(newCandidateValue);
            setCandidateSubmitState('invalidNoExams');
            showModal(
              'Error',
              `Oops, it looks like there aren't any valid exams associated with this candidate number. Please try again or visit our <a href="/help">Help page</a>`,
            );
            break;
          case 'CandidateIdClaimCheckValidationException':
            showModal(
              'Error',
              "We are sorry but we couldn't add that candidate number to your account. Please check that the name on your account matches the name on your exam or contact Axelos for help",
            );
            break;
          default:
            showModal(
              'Error',
              "We are sorry but we couldn't add that candidate number to your account. Please check that the name on your account matches the name on your exam or contact Axelos for help",
            );
        }
      }
    }
  };

  //TODO done useCallback
  const handleSubmit = async (data: Model, reset: UseFormReset<Model>) => {
    if (!isApiSubmitting) {
      try {
        setIsApiSubmitting(true);
        const candidateId = await postCandidateId(data.candidateId);
        if (active.current) {
          reset({ candidateId: '' });
          if (candidateId?.PeoplecertCandidateNumber.length > 0) {
            showModal(
              'Badge Information',
              'Unfortunately you cannot add multiple PeopleCert candidate numbers to MyAxelos.  If you have multiple PeopleCert candidate numbers, please contact customer services quoting error code <1> so that we can consolidate your accounts.',
            );
          }

          if (candidateId?.ExamExpiredMessage?.length > 1)
            showModal(
              'Badge Information',
              'We have logged your candidate number, but it looks like some of your certifications have inactive. You cannot claim digital badges for these inactive certifications, but we are retrieving your digital badges for the rest of your certifications.',
            );
          else if (
            candidateId?.ExamExpiredMessage?.length === 1 &&
            candidateId?.ExamExpiredMessage[0] === ExamMessages.RETRIEVE_BADGES
          )
            showModal(
              'We are retrieving your badges!',
              'You will be able to claim them shortly. Please allow a few minutes for badges to show after you have claimed them.',
            );
          else if (
            candidateId?.ExamExpiredMessage?.length === 1 &&
            candidateId?.ExamExpiredMessage[0] ===
              ExamMessages.ALL_CERTIFICATES_EXPIRED
          )
            showModal(
              'We are retrieving your badges!',
              'We have logged your candidate number, but it looks like your certifications have inactive. You cannot claim digital badges for these inactive certifications.',
            );

          TriggerGTMEvent({
            action: EventsActions.CLICK_CTA_ON_DASHBOARD,
            category: EventsCategories.CANDIDATE_NUMBERS,
            label: '',
          });
          setCandidateSubmitState('candidateIdSuccess');
          await getBadges(false);
          updateBadges(true);
        }
      } catch (error) {
        active.current && logger.debug('Post Candidate Number Error: ', error);
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleSubmit,
    isApiSubmitting,
    candidateSubmitState,
    candidateId,
    candidateErrorState,
  };
};
