import { useHistory } from 'react-router-dom';

import { CONGRATULATIONS_PAGE_URL } from 'modules/utils/constants';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { useCallback } from 'react';
import { isSubscriptionUpgrade } from 'pages/account-management/components/Subscriptions/Subscription/useSubscriptions';

export const useSuccessRedirect = () => {
  const history = useHistory();
  const successRedirect = useCallback(
    (
      subscriptionPlanName: string,
      subscriptionState: SubscriptionStateEnum,
    ) => {
      if (!isSubscriptionUpgrade) {
        history.push({
          pathname: CONGRATULATIONS_PAGE_URL,
          state: {
            plan: subscriptionPlanName,
            subscriptionState: subscriptionState,
          },
        });
      } else history.push('/');
    },
    [history],
  );

  return { successRedirect } as const;
};
