import { Redirect, Route, Switch } from 'react-router-dom';

import {
  BOOKMARKS_URL,
  CONGRATULATIONS_PAGE_URL,
  CURATE_PAGE_URL,
  DEBUG_URL,
  HELP_URL,
  PAGE_NO_PERMISSION_URL,
  POLICIES_PAGE_URL,
  RESOURCE_HUB_URL,
  SIGN_UP_URL,
  SIGN_IN_URL,
  VERIFY_PAGE_URL,
  RESET_PASSWORD_URL,
  EVENTS_URL,
} from 'modules/utils/constants';
import {
  ProtectedRouteAccountManagement,
  ProtectedRouteBadges,
  ProtectedRouteBookmarks,
  ProtectedRouteChangeEmailVerify,
  ProtectedRouteCongrats,
  ProtectedRouteContentNotAllowed,
  ProtectedRouteCpd,
  ProtectedRouteCuratedPage,
  ProtectedRouteDebug,
  ProtectedRouteFaq,
  ProtectedRouteHome,
  ProtectedRoutePage404,
  ProtectedRoutePrivacyPolicy,
  ProtectedRouteResetPassword,
  ProtectedRouteResourceHub,
  ProtectedRouteSignup,
  ProtectedRouteSignin,
  ProtectedRouteEvents,
} from './protectedRoutes';

export const Router = () => {
  return (
    <Switch>
      <ProtectedRouteHome exact path={['/', '/auth', '/auth-reset']} />
      <ProtectedRouteResourceHub path={`${RESOURCE_HUB_URL}:slug?`} />
      <ProtectedRouteCpd path="/cpd" />
      <ProtectedRouteEvents path={`${EVENTS_URL}:slug?`} />
      <ProtectedRouteBadges path="/badges" />
      <ProtectedRouteFaq path={HELP_URL} />
      <ProtectedRouteResetPassword path={RESET_PASSWORD_URL} />
      <ProtectedRouteSignup path={SIGN_UP_URL} />
      <ProtectedRouteSignin exact path={SIGN_IN_URL} />
      <ProtectedRoutePrivacyPolicy path={`${POLICIES_PAGE_URL}:slug`} />
      <ProtectedRouteCuratedPage path={`${CURATE_PAGE_URL}:slug`} />
      <ProtectedRouteAccountManagement path={'/account-management'} />
      <ProtectedRouteBookmarks path={BOOKMARKS_URL} />
      <ProtectedRouteDebug path={DEBUG_URL} />
      <ProtectedRouteCongrats path={CONGRATULATIONS_PAGE_URL} />
      <ProtectedRouteChangeEmailVerify path={VERIFY_PAGE_URL} />
      <ProtectedRouteContentNotAllowed path={PAGE_NO_PERMISSION_URL} />
      <ProtectedRoutePage404 />
    </Switch>
  );
};
