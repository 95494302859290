import { useCallback, useRef } from 'react';

import useModalAction from 'modules/modals/hooks/useModalAction';
import { API, api } from 'modules/api';
import { ModalType } from 'modules/modals/constants';
import { getDateWithOrdinal } from 'modules/utils/utils';

export const useNotifyOfFreeMonth = () => {
  const successModal = useRef<string | null>(null);
  const errorModal = useRef<string | null>(null);
  const { modalShow, modalHide } = useModalAction();

  const notifyOfFreeMonth = useCallback(
    async (userId?: number, userEmail?: string) => {
      try {
        if (userId === null || userEmail === '') {
          throw new Error();
        }

        api(
          API.GET_NOTIFY_FOR_FREE_MONTH_MESSAGE(
            `userId=${userId}`,
            `userEmail=${userEmail}`,
          ),
        ).then(res => {
          if (res.status === 200 && res.data !== null) {
            const { message, date } = res.data;

            successModal.current = modalShow({
              onClose: () => {
                !!successModal.current && modalHide(successModal.current);
                successModal.current = null;
              },
              title: 'Subscription Notification',
              text: `${message} ${getDateWithOrdinal(date)}.`,
              type: ModalType.INFO,
            });
          }
        });
      } catch (error: any) {
        if (!errorModal.current) {
          errorModal.current = modalShow({
            onClose: () => {
              !!errorModal.current && modalHide(errorModal.current);
              errorModal.current = null;
            },
            title: `Error: ${error.response?.statusText}`,
            text: 'There was an error while trying to get subscription notification.',
            type: ModalType.INFO,
          });
        }
      }
    },
    [modalHide, modalShow],
  );
  return { notifyOfFreeMonth } as const;
};
