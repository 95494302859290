import React from 'react';
import clsx from 'clsx';

import { ALL_FILTERS } from 'modules/utils/constants';
import { filterObjectType } from 'pages/resource-hub/types';
import { getColorCode } from 'modules/utils/utils';
import { ReactComponent as CloseIcon } from 'modules/theme/icons/general/cross.svg';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type FiltersSelectedProps = {
  addDeleteItems: (items: filterObjectType) => void;
  itemsSelected: filterObjectType[];
};

const FiltersSelected: React.FC<FiltersSelectedProps> = ({
  addDeleteItems,
  itemsSelected,
}) => {
  const isResourceHub = window.location.href.includes('/resource-hub')
    ? true
    : false;

  return itemsSelected.length > 1 || itemsSelected[0].title !== ALL_FILTERS ? (
    <React.Fragment>
      {itemsSelected.map((contentTypeSelected: any, index: number) => {
        const classNames = clsx(
          styles.filtersSelected,
          contentTypeSelected.colour &&
            getColorCode(contentTypeSelected.colour),
        );
        return (
          <Button.Generic
            key={`filter-selected-${index}`}
            className={classNames}
            disabled={
              contentTypeSelected.title === 'Events' && !isResourceHub
                ? true
                : false
            }
            onClick={() => addDeleteItems(contentTypeSelected)}
          >
            <div>{contentTypeSelected.title}</div>
            {(contentTypeSelected.title !== 'Events' || isResourceHub) && (
              <CloseIcon />
            )}
          </Button.Generic>
        );
      })}
    </React.Fragment>
  ) : null;
};

export default FiltersSelected;
